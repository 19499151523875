import React, { useEffect, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  Home as HomeIcon,
  ExpandMore as ExpandMoreIcon,
  Videocam as VideocamIcon,
  VpnKey as VpnKeyIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  CheckBox as CheckBoxIcon,
} from '@material-ui/icons';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../../rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';
import routes from '../../../routes';
import CourseModuleItem from '../Components/CourseModuleItem';
import { Colors } from '../../../assets/theme/Colors';

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: palette.background.default,
    flexDirection: 'column',
    padding: spacing(1),
  },
  accordion: {
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  summary: {
    position: 'relative',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    '&.Mui-expanded': {
      minHeight: '50px',
    },
  },
  summaryTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  summaryTitle: {
    flexGrow: 1,
  },
  checkbox: {
    color: Colors.green.light,
  },
}));

const LearningCenterMobileBreadcrumbs = () => (
  <Box boxShadow={0}>
    <Breadcrumbs aria-label="breadcrumb">
      <StyledRescuerBreadcrumb
        component={Link}
        to={generatePath(routes.mobileDashboard)}
        label="Home"
        icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
      />
      <StyledRescuerTextBreadcrumb component="a" href="#" label="Learn" />
    </Breadcrumbs>
  </Box>
);

const LearningCenterMobileView = ({
  videoTab,
  needToKnowTab,
  quizTab,
  courses,
  onModuleClick,
  coursesState,
  userId,
}) => {
  const classes = useStyles();
  const [isExpaned, setIsExpanded] = useState();
  const currentUserCoursesState = coursesState[userId];

  useEffect(() => {
    if (currentUserCoursesState) {
      setIsExpanded(currentUserCoursesState?.activeCourse);
    }
  }, [currentUserCoursesState]);

  return (
    <>
      <LearningCenterMobileBreadcrumbs />

      <Box className={classes.container}>
        <Typography variant="h6">Learn About Food Rescue</Typography>

        <Box my={2}>
          {courses.map((course) => {
            const courseState = currentUserCoursesState?.[course?.id];
            const modulesCount = (course?.modules || []).length;
            const allModulesFinished =
              modulesCount > 0 && modulesCount === Object.values(courseState?.modules || []).length;
            return (
              <Accordion
                elevation={25}
                className={classes.accordion}
                key={course.id}
                expanded={isExpaned === course.id}
                onClick={() => (isExpaned !== course.id ? setIsExpanded(course.id) : setIsExpanded(null))}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.summary}>
                  <div className={classes.summaryTitleContainer}>
                    <span className={classes.summaryTitle}>{course.title}</span>
                    {allModulesFinished && <CheckBoxIcon className={classes.checkbox} />}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={12}>
                      <CourseModuleItem
                        onClick={() => onModuleClick(videoTab, course)}
                        icon={<VideocamIcon />}
                        title="Video"
                        duration={course.videoDuration}
                        done={currentUserCoursesState && currentUserCoursesState[course.id]?.modules?.videoModule}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CourseModuleItem
                        onClick={() => onModuleClick(needToKnowTab, course)}
                        icon={<VpnKeyIcon />}
                        title="Need To Know"
                        duration={course.needToKnowDuration}
                        done={currentUserCoursesState && currentUserCoursesState[course.id]?.modules?.needToKnowModule}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CourseModuleItem
                        onClick={() => onModuleClick(quizTab, course)}
                        icon={<CheckCircleOutlineIcon />}
                        title="Quiz"
                        duration={course.quizDuration}
                        done={currentUserCoursesState && currentUserCoursesState[course.id]?.modules?.quizModule}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default LearningCenterMobileView;
