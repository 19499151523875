import React, { useState } from 'react';
import classNames from 'classnames';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { Done as DoneIcon, SkipNext as SkipNextIcon, Cancel as CancelIcon } from '@material-ui/icons';
import { Colors } from '../../../assets/theme/Colors';
import ToDoAlertMap from './ToDoAlertMap';
import { getRescueDropoffAddress, getRescuePickupAddress } from '../../../helpers/RescuesHelper';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
    borderRadius: 16,
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    transition: 'transform 0.2s ease-in',
    zIndex: 10,
  },
  contentContainer: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  content: {
    minHeight: (props) => (props.unclaimed ? '112px' : ''),
  },
  title: {
    textAlign: 'center',
  },
  buttonContainer: {
    textTransform: 'none',
    padding: 2,
    paddingTop: 5,
    fontSize: '16px',
    width: '100%',
    borderRadius: 0,
  },
  removeButton: {
    borderBottomLeftRadius: 16,
  },
  reviewButton: {
    borderBottomRightRadius: 16,
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  doneIcon: {
    color: Colors.green.light,
    fontSize: '32px',
  },
  skipButton: {
    color: '#ec9a29',
    fontSize: '32px',
  },
  cancelIcon: {
    color: Colors.red,
    fontSize: '32px',
  },
  cardOut: {
    transform: 'translateX(-120%) rotate(-5deg) translateY(20px)',
    transition: 'all 0.4s ease-in',
  },
  nextCard: {
    position: 'absolute',
    top: 0,
    zIndex: 5,
    transform: 'translateX(10px) translateY(10px)',
    maxHeight: 'fit-content',
  },
}));

const ToDoAlertCard = ({
  rescue,
  type,
  onRemoveClick,
  onSkipClick,
  onReviewClick,
  current = false,
  next = false,
  content = '',
  unclaimed = false,
}) => {
  const classes = useStyles({ unclaimed });
  const [cardOut, setCardOut] = useState();
  const animationTime = 400;

  return (
    <Box
      boxShadow={3}
      className={classNames(classes.card, next ? classes.nextCard : '', cardOut ? classes.cardOut : '')}
      style={{ display: current || next ? 'block' : 'none' }}
    >
      <Box className={classes.contentContainer}>
        <Typography className={classes.title} variant="h6">
          {type}
        </Typography>
        <Box my={1} className={classes.content}>
          <Typography variant="subtitle1">{content}</Typography>
        </Box>
        <Box mb={2}>
          <ToDoAlertMap
            pickupAddress={rescue ? getRescuePickupAddress(rescue) : null}
            dropoffAddress={rescue ? getRescueDropoffAddress(rescue) : null}
          />
        </Box>
      </Box>
      <Grid container>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            className={classNames(classes.buttonContainer, classes.removeButton)}
            onClick={() => {
              if (current) {
                setCardOut(true);
                setTimeout(() => {
                  onRemoveClick();
                }, animationTime);
              }
            }}
          >
            <span className={classes.button}>
              <CancelIcon className={classes.cancelIcon} />
              Remove
            </span>
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            className={classes.buttonContainer}
            onClick={() => {
              if (current) {
                setCardOut(true);
                setTimeout(() => {
                  onSkipClick();
                }, animationTime);
              }
            }}
          >
            <span className={classes.button}>
              <SkipNextIcon className={classes.skipButton} />
              Skip
            </span>
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            className={classNames(classes.buttonContainer, classes.reviewButton)}
            onClick={() => {
              if (current) {
                onReviewClick(rescue.id);
              }
            }}
          >
            <span className={classes.button}>
              <DoneIcon className={classes.doneIcon} />
              Review
            </span>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ToDoAlertCard;
