import React, { useContext, useEffect, useState } from 'react';
import { Link, generatePath, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Box, Breadcrumbs, makeStyles, Typography } from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../../rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';
import routes from '../../../routes';
import useActiveSite from '../../../hooks/useActiveSite';
import ToDoAlertCard from './ToDoAlertCard';
import FABContext from '../../../context/FABContext/FABContext';
import { sortByDateAndTime } from '../../../helpers/sorters';

export const NO_REVEIVER_ASSIGNED = 'No Receiver Assigned';

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    width: '100%',
    height: 'fit-content',
    backgroundColor: palette.background.default,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
  },
  alertsContainer: {
    position: 'relative',
    height: '100%',
  },
  noData: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    '-webkit-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
}));

const SDToDoNoReceiverAssignedBreadcrumbs = () => (
  <Box boxShadow={0}>
    <Breadcrumbs aria-label="breadcrumb">
      <StyledRescuerBreadcrumb
        component={Link}
        to={generatePath(routes.mobileDashboard)}
        label="Home"
        icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
      />
      <StyledRescuerTextBreadcrumb component={Link} to={routes.mobileToDo} label="To-do" />
      <StyledRescuerTextBreadcrumb component="a" href="#" label="No Receiver Assigned" />
    </Breadcrumbs>
  </Box>
);

const ToDoNoReceiverAssigned = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const activeSite = useActiveSite();
  const fabContext = useContext(FABContext);
  const [currentCardIndex, setCurrentCardIndex] = useState(location.state?.toDoCardIndex || 0);
  const pickupSpecsEntities = useSelector((state) => state.entities.sites.pickupSpecs);
  const donationsPickupsWithNoReceiver = [
    ...(pickupSpecsEntities.bySiteId[activeSite.id] || [])
      .reduce(
        (acc, ps) =>
          acc.set(ps.donation_id, {
            ...ps,
            id: ps.donation_id,
          }),
        new Map()
      )
      .values(),
  ];

  const donationsByDate = donationsPickupsWithNoReceiver.sort((a, b) =>
    sortByDateAndTime('desc', a?.start_date, b?.start_date)
  );
  const handleReviewClick = (donationId) => {
    history.push(generatePath(routes.donation_edit, { donationId }), { toDoCardIndex: currentCardIndex });
  };

  const handleSkipClick = () => {
    setCurrentCardIndex(currentCardIndex + 1);
  };

  const handleRemoveClick = () => {
    // TODO: Wait for API. Use skip logic for now
    handleSkipClick();
  };

  useEffect(() => {
    // hide FAB
    fabContext.updateFABStyles({
      speedDial: {
        display: 'none',
      },
    });

    // reset FAB styles on component unmount
    return () => fabContext.updateFABStyles({});
  }, []);

  // Show if there is no more pickups to review or when the last card has been skipped
  if (!donationsPickupsWithNoReceiver.length || currentCardIndex + 1 > donationsPickupsWithNoReceiver.length) {
    return (
      <>
        <SDToDoNoReceiverAssignedBreadcrumbs />
        <Box className={classes.container}>
          <Box className={classes.noData}>
            <Typography color="textSecondary" variant="h5">
              You’re all caught up, great work!
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <SDToDoNoReceiverAssignedBreadcrumbs />

      <Box className={classes.container}>
        <Box className={classes.alertsContainer}>
          {donationsByDate.map((donation, index) => (
            <ToDoAlertCard
              key={donation.id}
              rescue={donation}
              type={NO_REVEIVER_ASSIGNED}
              current={index === currentCardIndex}
              next={donationsByDate.length <= index ? null : index === currentCardIndex + 1}
              onRemoveClick={handleRemoveClick}
              onSkipClick={handleSkipClick}
              onReviewClick={handleReviewClick}
              content={
                <span>
                  Pickup from{' '}
                  <b>
                    {donation?.location?.length <= 20 ? donation?.location : `${donation?.location?.substr(0, 20)}...`}
                  </b>{' '}
                  on <b>{moment(donation?.start_date).format('ddd MMM Do')}</b> from{' '}
                  <b>{moment(donation?.pickup_begin, 'HH:mm:ss').format('hh:mma')}</b> to{' '}
                  <b>{moment(donation?.pickup_end, 'HH:mm:ss').format('hh:mma')}</b>, has no receiver assigned. Please
                  assign now!
                </span>
              }
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ToDoNoReceiverAssigned;
