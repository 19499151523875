import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import Bluebird from 'bluebird';
import moment from 'moment';
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Link,
  Tooltip,
  IconButton,
  Box,
  Button,
  TableFooter,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  ListItemText,
  MenuItem,
  Checkbox,
} from '@material-ui/core';
import { Check, Close, Cancel, FileCopy as FileCopyIcon, BorderColor as BorderColorIcon } from '@material-ui/icons';
import { omit } from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import { confirmAlert } from 'react-confirm-alert';
import ClearChip from './ClearChip';
import snackbarHelper from '../helpers/snackbarHelper';
import * as errorsHelper from '../helpers/errors';
import routes from '../routes';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import TableActionButton from './TableActionButton';
import TableActionsButtonsWrapper from './TableActionsButtonsWrapper';
import {
  donorLinkFilters,
  getRescueAdopter,
  getRescueEstimatedLbs,
  getRescuePickupLocationFullName,
  getRescueRescuer,
  isRescueClaimed,
  renderPickupLocationNameOrAdress,
} from '../helpers/RescuesHelper';
import { formatTime, formatCsvFileName, formatDate } from '../helpers/formatters';
import * as authService from '../services/auth';
import * as sitesActions from '../actions/sites';
import { getMuiTableDataIndex, getReceiversWithSiteOptions } from '../helpers/getters';
import { sortAlphabetically, sortByPickupReady, sortByTime } from '../helpers/sorters';
import RescuesTableStatusBox, { useRescuesTableRowColorStyles } from './Common/RescuesTableStatusBox';
import { RescueFilterTypes } from '../containers/FutureRescuesListView';
import { Roles } from '../models/roles';
import ConfirmationDialog from './ConfirmationDialog';
import UndoButton from './UndoButton';
import { fetchReceiversIfNeeded } from '../actions/receivers';
import { Colors } from '../assets/theme/Colors';
import useSystemSettings from '../hooks/useSystemSettings';
import { LBS_PER_MEAL } from '../models/systemSettings';
import DonorLinkPickupIndicator from './DonorLinkPickupIndicator';
import useHasActiveFeatureFlag from '../hooks/useFeatureFlags';
import { FF_DONOR_LINK } from './FeatureFlagWrapper';

const useStyles = makeStyles(() => ({
  toolPalette: {
    height: 'auto',
    padding: '2px 2px 2px 2px',
  },
  toolPaletteCell: {
    padding: '2px 2px 2px 2px',
    border: 'none',
    display: 'flex',
  },
  editedRow: {
    backgroundColor: Colors.bulkEdit.light,
    '&.MuiTableRow-root.MuiTableRow-hover:hover': {
      backgroundColor: Colors.bulkEdit.highlight,
    },
  },
  editableField: {
    backgroundColor: 'white',
  },
  editedField: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `3px solid ${Colors.bulkEdit.main}`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${Colors.bulkEdit.main}`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `3px solid ${Colors.bulkEdit.main}`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.bulkEdit.main,
    },
    marginRight: '1rem',
    backgroundColor: 'white',
  },
}));

const customTableToolbar = ({
  handleEditClick,
  editMode,
  showEditRescueButton = true,
  hasActiveBulkEditFeatureFlag = true,
  handleReviewClick,
  reviewButtonEnabled,
}) => (
  <>
    {!editMode && showEditRescueButton && hasActiveBulkEditFeatureFlag && (
      <Tooltip title="Edit Mode">
        <IconButton onClick={handleEditClick}>
          <BorderColorIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    )}
    {editMode && (
      <Box display="flex" justifyContent="flex-end" flexWrap="no-wrap">
        <Button onClick={handleEditClick}>Exit Edit Mode</Button>
        <Button
          disabled={!reviewButtonEnabled}
          onClick={handleReviewClick}
          style={{ marginLeft: '1rem' }}
          variant="contained"
          color="primary"
        >
          Review and Save
        </Button>
      </Box>
    )}
  </>
);

const customEditFooter = ({ handleEditClick, handleReviewClick, reviewButtonEnabled }) => (
  <TableFooter>
    <TableRow>
      <TableCell
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexWrap: 'nowrap',
          marginRight: '24px',
          minHeight: '64px',
        }}
      >
        <Typography variant="body2" style={{ marginRight: '1rem' }}>
          Exit Edit Mode to select a different page
        </Typography>
        <Button onClick={handleEditClick}>Exit Edit Mode</Button>
        <Button
          disabled={!reviewButtonEnabled}
          onClick={handleReviewClick}
          style={{ marginLeft: '1rem' }}
          variant="contained"
          color="primary"
        >
          Review and Save
        </Button>
      </TableCell>
    </TableRow>
  </TableFooter>
);

const RescuesTable = ({
  tableId,
  sites = [],
  rescues,
  isLoading,
  renderNamesAsLinks,
  showSiteColumn,
  handleReviewClick,
  editedRescues,
  setEditedRescues,
  editMode,
  setEditMode,
  showActionsColumn = true,
  showCopyRescueButton = true,
  showEditRescueButton = true,
  removedFilters = [],
}) => {
  const history = useHistory();
  const classes = useStyles();
  const tableStyles = useRescuesTableRowColorStyles();
  const dispatch = useDispatch();
  const modifyRescue = (id, attrs) => dispatch(sitesActions.modifySiteRescue(id, attrs));
  const saveRescue = id => dispatch(sitesActions.saveSiteRescue(id));
  const resetRescue = id => dispatch(sitesActions.resetSiteRescue(id));
  const uniqueRescuersList = [...new Set(rescues.map(rescue => rescue.rescuer))].filter(rescuer => rescuer !== null);
  const user = authService.getCurrentlyLoggedInOrImpersonatingUser();
  const receiversEntities = useSelector(state => state.entities.receivers);
  const receiversList = getReceiversWithSiteOptions(Object.values(receiversEntities.byId).sort((a, b) =>
    sortAlphabetically('asc', b.name, a.name)
  ));
  const rescueSizes = useSelector(state => state.entities.rescueSizes.byId);
  const lbsPerMeal = useSystemSettings(LBS_PER_MEAL);
  const hasActiveDonorLinkFF = useHasActiveFeatureFlag(FF_DONOR_LINK);

  useEffect(() => {
    dispatch(fetchReceiversIfNeeded());
  }, []);

  const handleEditClick = () => {
    if (!showEditRescueButton) {
      setEditMode(false);
      return setEditedRescues([]);
    }

    if (editedRescues?.length > 0) {
      return confirmAlert({
        message: 'Are you sure you want to exit without saving?',
        buttons: [
          {
            label: 'No',
            color: 'primary',
          },
          {
            label: 'Yes',
            color: 'primary',
            onClick: () => {
              setEditMode(!editMode);
              setEditedRescues([]);
            },
          },
        ],
        customUI: ({ message, onClose, buttons }) => (
          <ConfirmationDialog buttons={buttons} closeDialog={onClose} message={message} />
        ),
      });
    }

    setEditMode(!editMode);
    return setEditedRescues([]);
  };

  const onEditableFieldChange = (rescueEdited, rescue, data) => {
    if (!rescueEdited) {
      return setEditedRescues([...editedRescues, { rescue_id: rescue.id, ...data }]);
    }

    return setEditedRescues(editedRescues.map((r) => (r.rescue_id !== rescue.id ? r : { ...rescueEdited, ...data })));
  };

  const handleUndoClick = (rescue, field, previousWeight = 0) => {
    const editedRescuesReduced = editedRescues.reduce((acc, curr) => {
      if (curr.rescue_id === rescue.rescue_id) {
        let reducedRescue = omit(curr, [field]);

        if (field === 'receiving_agency_id') {
          reducedRescue = omit(reducedRescue, ['receiver']);
        }

        if (Object.keys(reducedRescue).length === 1) {
          return acc;
        }

        acc.push(reducedRescue);

        return acc;
      }
      acc.push(curr);
      return acc;
    }, []);

    setEditedRescues(editedRescuesReduced);
  };

  const onRescueCancel = rescue => modifyRescue(rescue.id, { cancelled_by_id: user.id, canceller: user.firstname });

  const onRescueUnCancel = rescue => modifyRescue(rescue.id, { cancelled_by_id: null, canceller: null });

  const onReleaserClear = rescue => modifyRescue(rescue.id, { released_by_id: null, releaser: null });

  const onEdit = (rescue) => history.push(generatePath(routes.rescue_edit, { rescueId: rescue.id }), { prevScrollPosition: window.scrollY });

  const onCopy = async rescue => {
    // If user has admin or SD roles go to donation create
    if (
      authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
        Roles.Admin,
        Roles.NationalSiteDirector,
        Roles.SiteDirector,
        Roles.SiteCoordinator,
      ])
    ) {
      return history.push(generatePath(routes.donation_add_new), { copy: true, rescue: rescue });
    }

    // If user has Food Donor roles go to create donation request
    if (
      authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
        Roles.DonorLocationAdmin,
        Roles.DonorLocationStaff,
      ])
    ) {
      return history.push(generatePath(routes.donation_create_request), { copy: true, rescue: rescue });
    }

    return null;
  };

  const onReset = rescue => resetRescue(rescue.id);

  const onSave = rescue =>
    Bluebird.try(() => saveRescue(rescue.id))
      .then(() => snackbarHelper.success('Rescue updated.'))
      .catch(err => errorsHelper.handleError(err));

  const renderUserProfileLink = user => {
    if (!user) {
      return '-';
    }

    return (
      <Link
        onClick={() => history.push(generatePath(routes.userEditNew, { id: user.id }))}
        style={{ cursor: 'pointer' }}
      >
        {user.name}
      </Link>
    );
  };

  const dateCustomBodyRender = (value, tableMeta, csv = false) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];
    const formattedDate = moment(value, 'YYYYMMDD').format('MM/DD/YYYY');

    if (csv) {
      return formattedDate;
    }

    return (
      <>
        <span>{formattedDate}</span>

        <RescuesTableStatusBox rescue={rescue} />
      </>
    );
  };

  const dayCustomBodyRender = (value, tableMeta) =>
    moment(rescues[getMuiTableDataIndex(tableMeta)].date, 'YYYYMMDD').format('dddd');

  const pickupBeginCustomBodyRender = value => formatTime(value);

  const pickupEndCustomBodyRender = value => formatTime(value);

  const locationCustomBodyRender = (value, tableMeta, csv = false) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];

    if (!rescue) {
      return false;
    }

    const rescuePickupLocationFullName = getRescuePickupLocationFullName(rescue) || '';

    if (csv || !renderNamesAsLinks) {
      return rescuePickupLocationFullName;
    }

    return (
      <Link
        onClick={() =>
          history.push(
            generatePath(routes.foodDonor, {
              foodDonorId: rescue.location_id,
            })
          )
        }
        style={{ cursor: 'pointer' }}
      >
        {rescuePickupLocationFullName}
      </Link>
    );
  };

  const receiverCustomBodyRender = (value, tableMeta) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];
    if (!rescue) {
      return false;
    }

    const siteReceivers = receiversList.filter((receiver) => receiver.site_id === rescue.site_id);

    if (editMode) {
      const rescueEdited = editedRescues.find((r) => r.rescue_id === rescue.id);
      return (
        <Box display="flex">
          <Autocomplete
            className={rescueEdited?.receiving_agency_id ? classes.editedField : classes.editableField}
            style={{ width: 250 }}
            value={{
              id: rescueEdited?.receiving_agency_id || rescue.receiver_id,
              name: rescueEdited?.receiving_agency_id ? rescueEdited?.receiver : rescue.receiver,
              site_id: rescue.site_id,
            }}
            fullWidth
            size="small"
            loading={receiversEntities.inflight}
            name="receiver_id"
            placeholder="Select receiver"
            options={siteReceivers}
            disableClearable
            onChange={(event, value) => {
              onEditableFieldChange(rescueEdited, rescue, { receiving_agency_id: value.id, receiver: value.name });
            }}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            getOptionDisabled={(option) => !option.id}
            renderOption={(option) => (
              <Box display="flex" flexDirection="column">
                <Typography variant="body1">{option.name}</Typography>
              </Box>
            )}
            renderInput={(params) => <TextField {...params} placeholder="Select receiver" variant="outlined" />}
          />
          {rescueEdited?.receiving_agency_id && (
            <UndoButton handleClick={() => handleUndoClick(rescueEdited, 'receiving_agency_id')} />
          )}
        </Box>
      );
    }

    if (!renderNamesAsLinks) {
      return value || '';
    }

    return (
      <Link
        onClick={() => history.push(generatePath(routes.receiver, { id: rescue.receiver_id }))}
        style={{ cursor: 'pointer' }}
      >
        {value || ''}
      </Link>
    );
  };

  const pickupLocationCustomBodyRender = (value, tableMeta) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];

    if (!rescue) {
      return false;
    }

    const rescuePickupLocationFullName = renderPickupLocationNameOrAdress(rescue) || '';

    return rescuePickupLocationFullName;
  };

  const adopterCustomBodyRender = (value, tableMeta, csv = false) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];

    if (!rescue) {
      return false;
    }

    const rescueAdopter = getRescueAdopter(rescue);

    if (csv || !renderNamesAsLinks) {
      return rescueAdopter ? rescueAdopter.name : null;
    }

    return renderUserProfileLink(rescueAdopter);
  };

  const rescuerCustomBodyRender = (value, tableMeta, csv = false) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];

    if (!rescue) {
      return false;
    }

    const rescueRescuer = getRescueRescuer(rescue);

    if (csv || !renderNamesAsLinks) {
      return rescueRescuer ? rescueRescuer.name : null;
    }

    return renderUserProfileLink(rescueRescuer);
  };

  const claimerCustomBodyRender = (value) => value ? 'Claimed' : 'Unclaimed';

  const weightCustomBodyRender = (value, tableMeta) => {
    if (value) {
      return value;
    }

    const rescue = rescues[getMuiTableDataIndex(tableMeta)];
    const rescueSize = Object.values(rescueSizes).find((size) => size.id === rescue.rescue_size_id);
    const rescueSizeLbs = rescueSize?.meals_per_rescue ? rescueSize.meals_per_rescue * lbsPerMeal : null;

    return rescueSizeLbs || '-';
  };

  const cancellerCustomBodyRender = (value, tableMeta, csv = false) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];

    if (!rescue || !rescue.canceller) {
      return !csv ? '-' : null;
    }

    if (csv || !renderNamesAsLinks) {
      return rescue.canceller;
    }

    return <ClearChip value={rescue.canceller} onClick={() => onRescueUnCancel(rescue)} />;
  };

  const releaserCustomBodyRender = (value, tableMeta, csv = false) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];

    if (!rescue || !rescue.releaser) {
      return !csv ? '-' : null;
    }

    if (csv || !renderNamesAsLinks) {
      return rescue.releaser;
    }

    return <ClearChip value={rescue.releaser} onClick={() => onReleaserClear(rescue)} />;
  };

  const vehicleCustomBodyRenderer = (value) => {
    const rescueSize = Object.values(rescueSizes).find((size) => size.id === value);

    return rescueSize?.name || '-';
  };

  const pickupReadyCustomBodyRender = (value, tableMeta, csv = false) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];

    if (!rescue) {
      return null;
    }

    if (!rescue?.location_donor_link_active) {
      return '-';
    }

    if (csv) {
      return value ? 'Pickup Ready' : 'No food yet';
    }

    return <DonorLinkPickupIndicator pickupReady={!!value} />;
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];

  if (showSiteColumn) {
    columns.push({
      name: 'site_id',
      label: 'Site',
      options: {
        customBodyRender: value => {
          if (!sites[value]) {
            return 'n/a';
          }

          return sites[value].name;
        },
        filter: false,
      },
    });
  }

  columns.push(
    {
      name: 'date',
      label: 'Date',
      options: {
        sort: true,
        sortCompare: order => ({ rowData: row1 }, { rowData: row2 }) => {
          const orderMultiplier = order === 'asc' ? 1 : -1;
          const date1 = moment(row1[1], 'YYYYMMDD').format('YYYY-MM-DD');
          const date2 = moment(row2[1], 'YYYYMMDD').format('YYYY-MM-DD');

          const pickupDate1 = new Date(`${date1} 00:00`);
          const pickupBegin1 = new Date(`${date1} ${row1[3]}`);
          const pickupEnd1 = new Date(`${date1} ${row1[4]}`);

          const pickupDate2 = new Date(`${date2} 00:00`);
          const pickupBegin2 = new Date(`${date2} ${row2[3]}`);
          const pickupEnd2 = new Date(`${date2} ${row2[3]}`);

          return (
            (pickupDate1 - pickupDate2) * orderMultiplier ||
            pickupBegin1 - pickupBegin2 ||
            pickupEnd1 - pickupEnd2
          );
        },
        customBodyRender: (value, tableMeta) => dateCustomBodyRender(value, tableMeta),
        customBodyRenderCSV: (value, tableMeta) => dateCustomBodyRender(value, tableMeta, true),
        customFilterListOptions: {
          render: value => `Date: ${formatDate(value)}`,
        },
        filterOptions: {
          renderValue: formatDate,
        },
      },
    },
    {
      name: 'day',
      label: 'Day',
      options: {
        filter: true,
        filterType: 'custom',
        filterOptions: {
          logic: (day, filters, row) => {
            if (filters.length) return !filters.includes(day);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            const optionValues = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
            return (
              <FormControl>
                <InputLabel htmlFor='select-multiple-chip'>
                  Day
                </InputLabel>
                <Select
                  multiple
                  value={filterList[index]}
                  renderValue={selected => selected.join(', ')}
                  onChange={event => {
                    filterList[index] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                >
                  {optionValues.map(item => (
                    <MenuItem key={item} value={item}>
                      <Checkbox
                        color='primary'
                        checked={filterList[index].indexOf(item) > -1}
                      />
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }
        },
        customBodyRender: (value, tableMeta) => dayCustomBodyRender(value, tableMeta),
        customBodyRenderCSV: (value, tableMeta) => dayCustomBodyRender(value, tableMeta),
        customFilterListOptions: {
          render: value => `Day: ${value}`,
        },
      },
    },
    {
      name: 'pickup_begin',
      label: 'Pickup Begin',
      options: {
        filter: false,
        customBodyRender: value => pickupBeginCustomBodyRender(value),
        customBodyRenderCSV: value => pickupBeginCustomBodyRender(value),
        sortCompare: order => (row1, row2) => sortByTime(order, row1.data, row2.data),
      },
    },
    {
      name: 'pickup_end',
      label: 'Pickup End',
      options: {
        filter: false,
        customBodyRender: value => pickupEndCustomBodyRender(value),
        customBodyRenderCSV: value => pickupEndCustomBodyRender(value),
        sortCompare: order => (row1, row2) => sortByTime(order, row1.data, row2.data),
      },
    },
    {
      name: 'location',
      label: 'Food Donor',
      options: {
        customBodyRender: (value, tableMeta) => locationCustomBodyRender(value, tableMeta),
        customBodyRenderCSV: (value, tableMeta) => locationCustomBodyRender(value, tableMeta, true),
        customFilterListOptions: {
          render: value => `Food Donor: ${value}`,
        },
        filter: !removedFilters.includes('location'),
      },
    },
    {
      name: 'pickup_location_name',
      label: 'Pickup Location',
      options: {
        customBodyRender: (value, tableMeta) => pickupLocationCustomBodyRender(value, tableMeta),
        customBodyRenderCSV: (value, tableMeta) => pickupLocationCustomBodyRender(value, tableMeta),
        customFilterListOptions: {
          render: value => `Pickup Location ${value}`,
        },
        filter: false,
        display: false,
      },
    },
    {
      name: 'receiver',
      label: 'Receiving Agency',
      options: {
        forceDisplay: !!editMode,
        customBodyRender: (value, tableMeta) => receiverCustomBodyRender(value, tableMeta),
        customFilterListOptions: {
          render: value => `Receiving Agency: ${value}`,
        },
      },
    },
    {
      name: 'pickup_city',
      label: 'Pickup City',
      options: {
        forceDisplay: !!editMode,
        customBodyRender: (value) => value || '',
        customFilterListOptions: {
          render: value => `Pickup City: ${value}`,
        },
      },
    },
    {
      name: 'dropoff_city',
      label: 'Dropoff City',
      options: {
        forceDisplay: !!editMode,
        customBodyRender: (value) => value || '',
        customFilterListOptions: {
          render: value => `Dropoff City: ${value}`,
        },
      },
    },
    {
      name: 'rescue_size_id',
      label: 'Vehicle',
      options: {
        display: false,
        sort: true,
        sortCompare: order => (row1, row2) => {
          const orderMultiplier = order === 'asc' ? 1 : -1;

          const row1RescueSize = Object.values(rescueSizes).find((size) => size.id === row1.data);
          const row2RescueSize = Object.values(rescueSizes).find((size) => size.id === row2.data);
          if (row1RescueSize && row2RescueSize) {
            return (row1RescueSize.meals_per_rescue - row2RescueSize.meals_per_rescue) * orderMultiplier;
          }

          return row1.data - row2.data;
        },
        customBodyRender: value => vehicleCustomBodyRenderer(value),
        customBodyRenderCSV: value => vehicleCustomBodyRenderer(value),
      },
    },
    {
      name: 'slug',
      label: 'Description',
      options: {
        filter: false,
        customBodyRender: value => value || '-',
      },
    },
    {
      name: 'notes',
      label: 'Pickup Notes',
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: value => value || '-',
      },
    },
    {
      name: 'adopter',
      label: 'Adopter',
      options: {
        customFilterListOptions: {
          render: value => `Adopted by: ${value === '-' ? 'unadopted' : value}`,
        },
        customBodyRender: (value, tableMeta) => adopterCustomBodyRender(value, tableMeta),
        customBodyRenderCSV: (value, tableMeta) => adopterCustomBodyRender(value, tableMeta, true),
      },
    },
    {
      name: 'rescuer',
      label: 'Rescuer',
      options: {
        filter: true,
        customBodyRender: (value, tableMeta) => rescuerCustomBodyRender(value, tableMeta),
        customBodyRenderCSV: (value, tableMeta) => rescuerCustomBodyRender(value, tableMeta, true),
        customFilterListOptions: {
          render: value => `Rescuer: ${value}`,
        },
      },
    },
    ...(hasActiveDonorLinkFF
      ? [
          {
            name: 'rescue_confirmed',
            label: 'Donor Link',
            options: {
              display: true,
              filter: true,
              customBodyRender: (value, tableMeta) => pickupReadyCustomBodyRender(value, tableMeta),
              customBodyRenderCSV: (value, tableMeta) => pickupReadyCustomBodyRender(value, tableMeta, true),
              filterOptions: {
                names: [
                  donorLinkFilters.dataReceived,
                  donorLinkFilters.dataNotReceived,
                  donorLinkFilters.donorLinkInactive,
                  donorLinkFilters.donorLinkActive,
                ],
                logic: (option, filters, row) => {
                  if (!filters || filters.length === 0) {
                    return false;
                  }

                  const rescueId = row[0];
                  const rescue = rescues.find((r) => r.id === rescueId);

                  switch (filters[0]) {
                    case donorLinkFilters.dataReceived:
                      return !rescue.rescue_confirmed;
                    case donorLinkFilters.dataNotReceived:
                      return !!option;
                    case donorLinkFilters.donorLinkInactive:
                      return rescue.location_donor_link_active;
                    case donorLinkFilters.donorLinkActive:
                      return !rescue.location_donor_link_active;
                    default:
                      return true;
                  }
                },
              },
              customFilterListOptions: {
                render: (value) => `Donor Link: ${value}`,
              },
              sortCompare: (order) => (row1, row2) => {
                const rescue1 = rescues.find((fd) => fd.id === row1.rowData[0]);
                const rescue2 = rescues.find((fd) => fd.id === row2.rowData[0]);

                return sortByPickupReady(order, rescue1, rescue2);
              },
            },
          },
        ]
      : []),
    {
      name: 'claimer',
      label: 'Rescue Status',
      options: {
        filter: true,
        customFilterListOptions: {
          render: value => `Rescue Status: ${value}`,
        },
        customBodyRender: value => claimerCustomBodyRender(value),
        customBodyRenderCSV: value => claimerCustomBodyRender(value),
        filterOptions: {
          names: [RescueFilterTypes.claimed, RescueFilterTypes.unclaimed],
          logic(value, filterVal) {
            if (filterVal.includes(RescueFilterTypes.claimed)) {
              return value !== 'Claimed';
            }

            if (filterVal.includes(RescueFilterTypes.unclaimed)) {
              return value !== 'Unclaimed';
            }

            return false;
          },
        },
      },
    },
    {
      name: 'lbs',
      label: 'Rescue Weight',
      options: {
        filter: true,
        customFilterListOptions: {
          render: value => `Weight: ${value}`,
        },
        customBodyRender: (value, tableMeta) => weightCustomBodyRender(value, tableMeta),
        customBodyRenderCSV: (value, tableMeta) => weightCustomBodyRender(value, tableMeta),
      },
    },
    {
      name: 'canceller',
      label: 'Cancelled By',
      options: {
        empty: true,
        filter: false,
        searchable: false,
        customBodyRender: (value, tableMeta) => cancellerCustomBodyRender(value, tableMeta),
        customBodyRenderCSV: (value, tableMeta) => cancellerCustomBodyRender(value, tableMeta, true),
      },
    },
    {
      name: 'releaser',
      label: 'Released By',
      options: {
        empty: true,
        filter: false,
        searchable: false,
        customBodyRender: (value, tableMeta) => releaserCustomBodyRender(value, tableMeta),
        customBodyRenderCSV: (value, tableMeta) => releaserCustomBodyRender(value, tableMeta, true),
      },
    }
  );

  if (showActionsColumn) {
    columns.push({
      name: 'actions',
      label: 'Actions',
      options: {
        download: false,
        Empty: true,
        filter: false,
        searchable: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta) => {
          const rescue = rescues[getMuiTableDataIndex(tableMeta)];

          if (!rescue) {
            return false;
          }

          if (rescue.modified) {
            return (
              <Table className={classes.toolPalette}>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.toolPaletteCell}>
                      <TableActionsButtonsWrapper>
                        <TableActionButton title="Reset" icon={Close} onClick={() => onReset(rescue)} />

                        <TableActionButton title="Save" icon={Check} onClick={() => onSave(rescue)} />
                      </TableActionsButtonsWrapper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            );
          }

          return (
            <Table className={classes.toolPalette}>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.toolPaletteCell}>
                    <TableActionsButtonsWrapper>
                      {!rescue.cancelled_by_id && (
                        <TableActionButton
                          title="Cancel Rescue"
                          icon={Cancel}
                          onClick={() => {
                            if (rescue.rescue_confirmed) {
                              return confirmAlert({
                                message: `The donor has already logged items for  donation, are you sure you want to  cancel? If so, make sure you contact  the donor and let them know you won't be picking up!`,
                                buttons: [
                                  {
                                    label: 'Back',
                                    color: 'secondary',
                                  },
                                  {
                                    label: 'Cancel Rescue',
                                    color: 'primary',
                                    onClick: () => onRescueCancel(rescue),
                                  },
                                ],
                                customUI: ({ message, onClose, buttons }) => (
                                  <ConfirmationDialog buttons={buttons} closeDialog={onClose} message={message} />
                                ),
                              });
                            }
                            
                            return onRescueCancel(rescue)}
                          }
                        />
                      )}
                      {showEditRescueButton && (
                        <TableActionButton title="Edit Rescue" onClick={() => onEdit(rescue)} />
                      )}
                      {showCopyRescueButton && (
                        <TableActionButton
                          icon={FileCopyIcon}
                          title="Copy Rescue"
                          onClick={() => onCopy(rescue)}
                        />
                      )}
                    </TableActionsButtonsWrapper>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          );
        },
      },
    });
  }

  return (
    <MuiThemeProvider
      theme={theme =>
        createMuiTheme({
          ...theme,
          overrides: {
            ...theme.overrides,
            MUIDataTableHeadCell: {
              root: {
                ...theme.overrides.MUIDataTableHeadCell.root,
                '&:last-child': {
                  width: 130,
                },
              },
            },
          },
        })
      }
    >
      <BaseMUIDataTable
        isLoading={isLoading}
        tableId={tableId}
        data={rescues}
        columns={columns}
        options={{
          customToolbar: () =>
            customTableToolbar({
              handleEditClick,
              editMode,
              showEditRescueButton,
              handleReviewClick,
              reviewButtonEnabled: editedRescues?.length,
            }),
          customFooter: editMode
            ? () => customEditFooter({ handleEditClick, handleReviewClick, reviewButtonEnabled: editedRescues.length })
            : null,
          sortOrder: {
            name: 'date',
            direction: 'asc',
          },
          setRowProps: (row, dataIndex) => {
            const rescue = rescues[dataIndex];

            let colorClass = classes.unclaimedRescueRow;
            const rescueEdited = editedRescues.find((r) => r.rescue_id === rescue.id);

            if (rescue.cancelled_by_id) {
              colorClass = tableStyles.cancelledRescueRow;
            } else if (rescue.closed_by_id) {
              colorClass = tableStyles.closedRescueRow;
            } else if (isRescueClaimed(rescue)) {
              colorClass = tableStyles.claimedRescueRow;
            } else if (!isRescueClaimed(rescue)) {
              colorClass = tableStyles.unclaimedRescueRow;
            }

            return {
              className: rescueEdited ? `${classes.editedRow} ${colorClass}` : colorClass,
            };
          },
          rowsPerPage: 15,
          selectableRows: 'none',
          responsive: 'simple',
          download: !editMode,
          filter: !editMode,
          search: !editMode,
          viewColumns: !editMode,
          downloadOptions: {
            filename: formatCsvFileName('Rescues'),
          },
        }}
      />
    </MuiThemeProvider>
  );
};

export default RescuesTable;
