import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Box, Button, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Close as CloseIcon, ImportExport as ImportExportIcon } from '@material-ui/icons';
import useActiveSite from '../../../hooks/useActiveSite';
import routes from '../../../routes';
import { currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite } from '../../../services/auth';
import { Roles } from '../../../models/roles';

const MapDrivingDirections = ({ CloseDrivingDirections, routeValue, reverseDirections }) => {
  const activeSite = useActiveSite();
  const history = useHistory();
  const canCreateDonation = currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
    Roles.Admin,
    Roles.NationalSiteDirector,
    Roles.SiteDirector,
    Roles.SiteCoordinator,
  ]);

  const onCreateDonationClick = () => {
    if (routeValue.origin._markerType === 'donor' && routeValue.destination._markerType === 'receiver') {
      history.push(
        generatePath(routes.donation_add_from_map, {
          location_id: routeValue.origin.donor_id,
          pickup_location_id: routeValue.origin.id,
          receiver_id: routeValue.destination.receiver_id,
        })
      );
    }
  };

  const fromToSelected = Object.keys(routeValue.origin).length > 0 && Object.keys(routeValue.destination).length > 0;
  const isFromTheActiveSite = routeValue.destination.site_id === activeSite.id && routeValue.origin.site_id === activeSite.id;
  const tooltipText = () => {
    if (routeValue.destination._markerType !== 'receiver' || routeValue.origin._markerType !== 'donor') {
      return 'To create a donation, make sure your route begins at a food donor location and ends at a receiving agency.';
    }

    if (!isFromTheActiveSite) {
      return 'You are able to create donations only for Donors and Receivers from the current site.';
    }

    return '';
  };

  return (
    <Box
      style={{ zIndex: 5, position: 'absolute', top: 70, right: 370, maxWidth: 450 }}
      boxShadow={25}
      p={2}
      bgcolor="background.paper"
    >
      <Typography gutterBottom variant="subtitle2" color="textSecondary">
        Driving Directions
      </Typography>
      <IconButton
        style={{
          position: 'absolute',
          top: 8,
          right: 8,
        }}
        size="small"
        onClick={CloseDrivingDirections}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      <Grid container>
        {!Object.keys(routeValue.origin).length && 'Please click on the marker to select a starting point.'}
        {Object.keys(routeValue.origin).length > 0 && (
          <>
            <Grid container justify="flex-end">
              <Grid container item xs={11}>
                <Grid item xs={2}>
                  <Typography variant="subtitle2" style={{ marginRight: 5 }}>
                    From:
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography style={{ marginLeft: 5 }} variant="body2" color="textSecondary">
                    {routeValue.origin.title || routeValue.origin.name || ''} {routeValue.origin.address},{' '}
                    {routeValue.origin.city}
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Typography variant="subtitle2">To:</Typography>
                </Grid>
                <Grid item xs={10}>
                  {Object.keys(routeValue.destination).length > 0 ? (
                    <Typography style={{ marginLeft: 5 }} variant="body2" color="textSecondary">
                      {routeValue.destination.title || routeValue.destination.name || ''}{' '}
                      {routeValue.destination.address || ''}, {routeValue.destination.city || ''}
                    </Typography>
                  ) : (
                    <Typography style={{ marginLeft: 5 }} variant="body2" color="textSecondary">
                      Please click on the second marker to select destination point.
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={1}>
                {fromToSelected && (
                  <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="end">
                    <Tooltip title="Reverse directions">
                      <IconButton style={{ position: 'relative', right: -8 }} size="small" onClick={reverseDirections}>
                        <ImportExportIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              </Grid>
            </Grid>

            <Grid item xs={2}>
              <Typography variant="subtitle2">Distance:</Typography>
            </Grid>
            <Grid item xs={10}>
              {Object.keys(routeValue.directions).length > 0 ? (
                <Typography
                  style={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                    marginLeft: 5,
                  }}
                  variant="body2"
                  color="textSecondary"
                >
                  {routeValue.directions.distance.text}
                </Typography>
              ) : (
                ''
              )}
            </Grid>

            <Grid item xs={2}>
              <Typography variant="subtitle2">Duration:</Typography>
            </Grid>
            <Grid item xs={10}>
              {Object.keys(routeValue.directions).length > 0 ? (
                <Typography style={{ marginLeft: 5 }} variant="body2" color="textSecondary">
                  {routeValue.directions.duration.text}
                </Typography>
              ) : (
                ''
              )}
            </Grid>
          </>
        )}
      </Grid>
      {fromToSelected && (
        <Box display="flex" justifyContent="end">
          <Tooltip title={tooltipText()}>
            <span>
              <Button
                variant="contained"
                color="secondary"
                onClick={onCreateDonationClick}
                disabled={
                  !isFromTheActiveSite ||
                  !canCreateDonation ||
                  routeValue.destination._markerType !== 'receiver' ||
                  routeValue.origin._markerType !== 'donor'
                }
              >
                Create Rescue
              </Button>
            </span>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default MapDrivingDirections;
