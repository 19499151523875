import React, { useContext, useEffect, useState } from 'react';
import { Link, generatePath, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Box, Breadcrumbs, makeStyles, Typography } from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../../rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';
import routes from '../../../routes';
import useActiveSite from '../../../hooks/useActiveSite';
import ToDoAlertCard from './ToDoAlertCard';
import FABContext from '../../../context/FABContext/FABContext';

export const UNCLAIMED_RESCUE = 'Unclaimed Rescue';

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    width: '100%',
    height: 'fit-content',
    backgroundColor: palette.background.default,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
  },
  alertsContainer: {
    position: 'relative',
    height: '100%',
  },
  noData: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    '-webkit-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
}));

const SDToDoUnclaimedRescuesBreadcrumbs = () => (
  <Box boxShadow={0}>
    <Breadcrumbs aria-label="breadcrumb">
      <StyledRescuerBreadcrumb
        component={Link}
        to={generatePath(routes.mobileDashboard)}
        label="Home"
        icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
      />
      <StyledRescuerTextBreadcrumb component={Link} to={routes.mobileToDo} label="To-do" />
      <StyledRescuerTextBreadcrumb component="a" href="#" label="Unclaimed Rescues" />
    </Breadcrumbs>
  </Box>
);

const ToDoUnclaimedRescues = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const activeSite = useActiveSite();
  const fabContext = useContext(FABContext);
  const unclaimedRescues = useSelector((state) => state.entities.sites.unclaimedRescues.bySiteId[activeSite.id]) || [];
  const [currentCardIndex, setCurrentCardIndex] = useState(location.state?.toDoCardIndex || 0);

  const handleReviewClick = (rescueId) => {
    history.push(generatePath(routes.rescue_edit, { rescueId }), { toDoCardIndex: currentCardIndex });
  };

  const handleSkipClick = () => {
    setCurrentCardIndex(currentCardIndex + 1);
  };

  const handleRemoveClick = () => {
    // TODO: Wait for API. Use skip logic for now
    handleSkipClick();
  };

  useEffect(() => {
    // hide FAB
    fabContext.updateFABStyles({
      speedDial: {
        display: 'none',
      },
    });

    // reset FAB styles on component unmount
    return () => fabContext.updateFABStyles({});
  }, []);

  // Show if there is no more unclaimed rescues to review or when the last card has been skipped
  if (!unclaimedRescues.length || currentCardIndex + 1 > unclaimedRescues.length) {
    return (
      <>
        <SDToDoUnclaimedRescuesBreadcrumbs />
        <Box className={classes.container}>
          <Box className={classes.noData}>
            <Typography color="textSecondary" variant="h5">
              You’re all caught up, great work!
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <SDToDoUnclaimedRescuesBreadcrumbs />

      <Box className={classes.container}>
        <Box className={classes.alertsContainer}>
          {unclaimedRescues.map((rescue, index) => (
            <ToDoAlertCard
              key={rescue.id}
              rescue={rescue}
              type={UNCLAIMED_RESCUE}
              current={index === currentCardIndex}
              next={unclaimedRescues.length <= index ? null : index === currentCardIndex + 1}
              unclaimed
              onRemoveClick={handleRemoveClick}
              onSkipClick={handleSkipClick}
              onReviewClick={handleReviewClick}
              content={
                <span>
                  Pickup from {}
                  <b>
                    {rescue?.location?.length <= 20 ? rescue?.location : `${rescue?.location?.substr(0, 20)}...`}
                  </b>{' '}
                  to {}
                  <b>
                    {rescue?.receiver?.length <= 20 ? rescue?.receiver : `${rescue?.receiver?.substr(0, 20)}...`}
                  </b>{' '}
                  on <b>{moment(rescue?.date).format('ddd MMM Do')}</b> from{' '}
                  <b>{moment(rescue?.pickup_begin, 'HH:mm:ss').format('hh:mma')}</b> to{' '}
                  <b>{moment(rescue?.pickup_end, 'HH:mm:ss').format('hh:mma')}</b>, has no rescuer assigned. Please{' '}
                  assign now!
                </span>
              }
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default ToDoUnclaimedRescues;
