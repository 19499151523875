import React from 'react';
import { Virtuoso } from 'react-virtuoso';
import { Box, Breadcrumbs, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Home as HomeIcon } from '@material-ui/icons';
import DonationRequestCard from './DonationRequestCard';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../../../rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import routes from '../../../../routes';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: palette.background.default,
  },
  noData: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    '-webkit-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
}));

const PendingRequestBreadcrumbs = () => (
  <Box boxShadow={0}>
    <Breadcrumbs aria-label="breadcrumb">
      <StyledRescuerBreadcrumb
        component={RouterLink}
        to={generatePath(routes.mobileDashboard)}
        label="Home"
        icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
      />
      <StyledRescuerTextBreadcrumb component={RouterLink} to={routes.mobileToDo} label="To-do" />
      <StyledRescuerTextBreadcrumb component="a" href="#" label="Pending Donations" />
    </Breadcrumbs>
  </Box>
);

const DonationPendingRequestsCards = ({ isLoading, donations, pickupLocationsEntities, onEditClick }) => {
  const classes = useStyles();

  if (!isLoading && donations.length === 0) {
    return (
      <>
        <PendingRequestBreadcrumbs />
        <Box className={classes.container}>
          <Box className={classes.noData}>
            <Typography color="textSecondary" variant="h5">
              You have no pending requests to donate.
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <PendingRequestBreadcrumbs />
      <Virtuoso
        totalCount={donations.length}
        style={{ height: 'calc(100vh - 70px)' }}
        itemContent={index => {
          const donationRequest = {
            ...donations[index],
            pickupLocation: pickupLocationsEntities[donations[index].pickup_location_id],
          };
          const top = index === 0 ? 0 : 1;
          const bottom = index === donations ? 0 : 1;

          return (
            <Box pt={top} pb={bottom} key={donationRequest.id}>
              <DonationRequestCard donationRequest={donationRequest} isLoading={isLoading}>
                <Button
                  fullWidth
                  size="large"
                  color="primary"
                  variant="contained"
                  disabled={donationRequest.status !== 'pending'}
                  onClick={() => onEditClick(donationRequest.id)}
                >
                  Verify
                </Button>
              </DonationRequestCard>
            </Box>
          );
        }}
      />
    </>
  );
};

export default DonationPendingRequestsCards;
