import Bluebird from 'bluebird';
import * as rescuersApi from '../api/rescuers';
import * as enviroApi from '../api/environmentImpact';

export const REQUEST_ENVIRONMENT_IMPACT = 'REQUEST_ENVIRONMENT_IMPACT';
export const RECEIVE_RESCUER_ENVIRONMENT_IMPACT = 'RECEIVE_RESCUER_ENVIRONMENT_IMPACT';
export const RECEIVE_ENVIRONMENT_IMPACT = 'RECEIVE_ENVIRONMENT_IMPACT';

export const requestEnvironmentImpact = () => ({
  type: REQUEST_ENVIRONMENT_IMPACT,
  receivedAt: Date.now(),
});

export const receiveRescuerEnvironmentImpact = (environmentImpact) => ({
  type: RECEIVE_RESCUER_ENVIRONMENT_IMPACT,
  environmentImpact,
});

export const receiveEnvironmentImpact = (environmentImpact) => ({
  type: RECEIVE_ENVIRONMENT_IMPACT,
  environmentImpact,
});

export const fetchRescuerEnvironmentImpact =
  (rescuerId, quietMode = false, from, to) =>
  (dispatch) => {
    if (!quietMode) {
      dispatch(requestEnvironmentImpact());
    }

    return Bluebird.try(() => rescuersApi.getRescuerEnvironmentImpact(rescuerId, from, to))
      .then((res) => res.json())
      .then((response) => dispatch(receiveRescuerEnvironmentImpact(response.data)));
  };

export const fetchEnvironmentImpact =
  (sites, from, to, quietMode = false) =>
  (dispatch) => {
    if (!quietMode) {
      dispatch(requestEnvironmentImpact());
    }

    return Bluebird.try(() => enviroApi.getEnvironmentImpact({ ...sites, from, to }))
      .then((res) => res.json())
      .then((response) => dispatch(receiveEnvironmentImpact(response.data)));
  };
