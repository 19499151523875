import React, { useContext, useEffect, useState } from 'react';
import { generatePath, Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LearningCenterMobileView from './LearningCenterMobileView';
import routes from '../../../routes';
import VideoModule from '../Components/VideoModule';
import NeedToKnowModule from '../Components/NeedToKnowModule';
import FABContext from '../../../context/FABContext/FABContext';
import QuizModule from '../Components/QuizModule';
import useActiveUser from '../../../hooks/useActiveUser';
import { setCoursesState } from '../../../actions/ui';
import courses from '../../../helpers/courses';

const LearningCenterMobileContainer = () => {
  const history = useHistory();
  const fabContext = useContext(FABContext);
  const dispatch = useDispatch();
  const [currentCourse, setCurrentCourse] = useState({});
  const coursesState = useSelector((state) => state.ui.courses);
  const activeUser = useActiveUser();
  const videoTab = 'video';
  const needToKnowTab = 'need-to-know';
  const quizTab = 'quiz';

  useEffect(() => {
    fabContext.updateFABStyles({
      speedDial: {
        display: 'none',
      },
    });

    return () => fabContext.updateFABStyles({});
  }, []);

  const handleModuleClick = (module, course) => {
    setCurrentCourse(course);
    history.push(generatePath(routes.mobileDashboardLearn, { tab: module, id: course.id }));
  };

  const paths = [
    generatePath(routes.mobileDashboardLearn),
    generatePath(routes.mobileDashboardLearn, { tab: videoTab }),
    generatePath(routes.mobileDashboardLearn, { tab: needToKnowTab }),
    generatePath(routes.mobileDashboardLearn, { tab: quizTab }),
  ];

  const setCurrentCoursesState = (data) =>
    dispatch(
      setCoursesState({
        userId: activeUser.id,
        courseId: data.courseId,
        data,
      })
    );

  return (
    <Switch>
      <Route exact path={paths[0]}>
        <LearningCenterMobileView
          videoTab={videoTab}
          needToKnowTab={needToKnowTab}
          quizTab={quizTab}
          courses={courses}
          onModuleClick={handleModuleClick}
          coursesState={coursesState}
          userId={activeUser?.id}
        />
      </Route>
      <Route path={paths[1]}>
        <VideoModule
          isMobile
          course={currentCourse}
          nextTab={needToKnowTab}
          setCurrentCoursesState={setCurrentCoursesState}
        />
      </Route>
      <Route path={paths[2]}>
        <NeedToKnowModule
          isMobile
          course={currentCourse}
          nextTab={quizTab}
          setCurrentCoursesState={setCurrentCoursesState}
        />
      </Route>
      <Route path={paths[3]}>
        <QuizModule isMobile course={currentCourse} user={activeUser} setCurrentCoursesState={setCurrentCoursesState} />
      </Route>
    </Switch>
  );
};

export default LearningCenterMobileContainer;
