import React, { useState } from 'react';
import MinMaxTimePicker from '../../../../../../components/MinMaxTimePicker';
import ReceiverSelector from '../../../../../../components/ReceiverSelector';
import ReceiverChip from '../../../../../../components/ReceiverChip';
import moment from 'moment';
import { TextAreaStyles } from '../../../../../../assets/theme/TextAreaStyles';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, TextField, Card, CardContent, Typography, FormHelperText } from '@material-ui/core';
import AdopterSelector from '../../../../../../components/AdopterSelector';
import { SelectorMobileDialog } from '../../../../../../components/Common/SelectorMobileDialog';
import { DONATION_TYPE_REQUEST_APPROVAL, DONATION_TYPE_SD } from '../../../../../../actions/donationNew';
import { FREQUENCY_ONCE } from '../../../../../../models/donationsNew';

const useCardStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const textAreaStyles = makeStyles(() => ({
  ...TextAreaStyles(),
}));

const PickupSpecCardMobile = ({
  pickup,
  receiversList,
  rescuersList,
  onPickupBeginUpdate,
  onPickupEndUpdate,
  onReceiverChange,
  onAdopterChange,
  onRescuerChange,
  onPickupNotesChange,
  onPickupDelete,
  isLoading,
  donationType,
  isNotSCOrGreaterRoleUser,
  errors = {},
  frequency,
  rescue,
}) => {
  const classes = textAreaStyles();
  const [isReceiverSelectorOpen, setReceiverSelectorOpen] = useState(false);
  const [isAdopterSelectorOpen, setAdopterSelectorOpen] = useState(false);
  const [rescuerNotesValue, setRescuerNotesValue] = useState(pickup.rescuer_notes || '');
  const isOneTimeOnly = frequency === FREQUENCY_ONCE;

  const pickupBeginIsBeforeEnd = moment(pickup.pickup_begin.value, 'HH:mm:ss').isAfter(
    moment(pickup.pickup_end.value, 'HH:mm:ss')
  );

  const getUniqueKey = keyName => `${keyName}-${pickup.day}_${pickup.position}_${pickup.ordinal || 0}`;
  return (
    <>
      {[DONATION_TYPE_SD, DONATION_TYPE_REQUEST_APPROVAL].includes(donationType) && (
        <>
          <SelectorMobileDialog
            dialogTitle="Receiving Agency:"
            getOptionId={adopter => adopter.value.receiver_id}
            getOptionLabel={adopter => adopter.label}
            options={receiversList}
            disabled={isLoading || isNotSCOrGreaterRoleUser}
            open={isReceiverSelectorOpen}
            onClose={value => {
              if (value) {
                onReceiverChange(pickup, value);
              }
              setReceiverSelectorOpen(false);
            }}
          />

          <SelectorMobileDialog
            dialogTitle={isOneTimeOnly ? 'Rescuer:' : 'Adopter:'}
            getOptionId={adopter => adopter.value.id}
            getOptionLabel={adopter => adopter.label}
            options={rescuersList}
            disabled={isLoading || isNotSCOrGreaterRoleUser}
            open={isAdopterSelectorOpen}
            onClose={value => {
              if (value) {
                if (isOneTimeOnly) {
                  onRescuerChange(pickup, value);
                } else {
                  onAdopterChange(pickup, value);
                }
              }
              setAdopterSelectorOpen(false);
            }}
          />
        </>
      )}

      <Card variant="outlined" classes={useCardStyles()}>
        <CardContent>
          <Box mb={1}>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item xs={5}>
                <MinMaxTimePicker
                  label="Pickup start"
                  inputVariant="outlined"
                  size="medium"
                  fullWidth
                  value={moment(pickup.pickup_begin.value, pickup.pickup_begin.format)}
                  maxTime={moment(pickup.pickup_end.value, pickup.pickup_end.format)}
                  onTimeChange={({ event }) => onPickupBeginUpdate(pickup, event.format('HH:mm:00'))}
                  showWarning={errors.pickups && pickupBeginIsBeforeEnd}
                />
              </Grid>
              <Grid item xs={5}>
                <MinMaxTimePicker
                  showFieldErrors
                  label="Pickup end"
                  inputVariant="outlined"
                  fullWidth
                  value={moment(pickup.pickup_end.value, pickup.pickup_end.format)}
                  minTime={moment(pickup.pickup_begin.value, pickup.pickup_begin.format)}
                  onTimeChange={({ event, isValid }) => {
                    if (isValid) {
                      return onPickupEndUpdate(pickup, event.format('HH:mm:00'));
                    }
                  }}
                  showWarning={errors.pickups && pickupBeginIsBeforeEnd}
                />
              </Grid>
            </Grid>
            {errors.pickups && pickupBeginIsBeforeEnd && (
              <Box height={20}>
                <Box style={{ position: 'absolute' }}>
                  <FormHelperText data-testid="pickup_end-error" id="pickup_end" error>
                    {errors.pickups}
                  </FormHelperText>
                </Box>
              </Box>
            )}
          </Box>

          {[DONATION_TYPE_REQUEST_APPROVAL].includes(donationType) && (
            <Box my={2}>
              <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12}>
                  {pickup.receiver === null ? (
                    <>
                      <ReceiverSelector
                        name={getUniqueKey('receiver')}
                        isDisabled={isLoading || isNotSCOrGreaterRoleUser}
                        inputId={`receiver-1`}
                        instanceId={getUniqueKey('receiver')}
                        receivers={receiversList}
                        onMenuOpen={() => setReceiverSelectorOpen(true)}
                        error={!!errors.receiver}
                      />
                      {errors.receiver && (
                        <FormHelperText data-testid="receiver_selector-error" id="receiver_selector-error" error>
                          {errors.receiver}
                        </FormHelperText>
                      )}
                    </>
                  ) : (
                    <Box display="flex" flexDirection="column">
                      <Typography>Receiving Agency:</Typography>
                      <ReceiverChip
                        isDisabled={isNotSCOrGreaterRoleUser}
                        isMobile
                        value={pickup.receiver.name}
                        onClick={() => onReceiverChange(pickup, null)}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}

          {[DONATION_TYPE_SD].includes(donationType) && (
            <>
              <Box my={2}>
                <Grid container direction="row" justify="center" alignItems="center">
                  <Grid item xs={12}>
                    {pickup.receiver === null ? (
                      <>
                        <ReceiverSelector
                          name={getUniqueKey('receiver')}
                          isDisabled={isLoading || isNotSCOrGreaterRoleUser}
                          inputId={`receiver-1`}
                          instanceId={getUniqueKey('receiver')}
                          receivers={receiversList}
                          onMenuOpen={() => setReceiverSelectorOpen(true)}
                          error={!!errors.receiver}
                        />
                        {errors.receiver && (
                          <FormHelperText data-testid="receiver_selector-error" id="receiver_selector-error" error>
                            {errors.receiver}
                          </FormHelperText>
                        )}
                      </>
                    ) : (
                      <Box display="flex" flexDirection="column">
                        <Typography>Receiving Agency:</Typography>
                        <ReceiverChip
                          isDisabled={isNotSCOrGreaterRoleUser}
                          isMobile
                          value={pickup.receiver.name}
                          onClick={() => onReceiverChange(pickup, null)}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Box my={2}>
                <Grid container direction="row" justify="center" alignItems="center">
                  {isOneTimeOnly ? (
                    <Grid item xs={12}>
                      {!rescue?.rescuer ? (
                        <AdopterSelector
                          placeholder="Rescuer"
                          noOptionsText="There are no active rescuers in the Site"
                          isDisabled={isLoading || isNotSCOrGreaterRoleUser}
                          rescuersList={rescuersList}
                          onMenuOpen={() => setAdopterSelectorOpen(true)}
                        />
                      ) : (
                        <Box display="flex" flexDirection="column">
                          <Typography>Rescuer:</Typography>
                          <ReceiverChip
                            isMobile
                            isDisabled={isNotSCOrGreaterRoleUser}
                            value={rescue.rescuer.name}
                            onClick={() => onRescuerChange(pickup, null)}
                          />
                        </Box>
                      )}
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      {pickup.adopter === null ? (
                        <AdopterSelector
                          isDisabled={isLoading || isNotSCOrGreaterRoleUser}
                          rescuersList={rescuersList}
                          onMenuOpen={() => setAdopterSelectorOpen(true)}
                        />
                      ) : (
                        <Box display="flex" flexDirection="column">
                          <Typography>Adopter:</Typography>
                          <ReceiverChip
                            isMobile
                            isDisabled={isNotSCOrGreaterRoleUser}
                            value={pickup.adopter.name}
                            onClick={() => onAdopterChange(pickup, null)}
                          />
                        </Box>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Box>
            </>
          )}

          <Box my={2}>
            <Grid container direction="row" justify="center" alignItems="center">
              <Grid item xs={12}>
                <TextField
                  classes={classes}
                  rows={2}
                  fullWidth
                  multiline
                  rowsMax={5}
                  type="textarea"
                  variant="outlined"
                  label="Rescuer Notes"
                  value={rescuerNotesValue}
                  onChange={event => setRescuerNotesValue(event.target.value)}
                  onBlur={event => onPickupNotesChange(pickup, event.target.value)}
                />
              </Grid>
            </Grid>
          </Box>

          {onPickupDelete && (
            <Box mt={2}>
              <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12}>
                  <Button fullWidth variant="contained" color="secondary" onClick={() => onPickupDelete(pickup)}>
                    Delete Pickup
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default PickupSpecCardMobile;
