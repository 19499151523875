import React from 'react';
import { useParams, matchPath } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';
import get from 'lodash/get';
import routes from '../../../routes';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../../rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';

const RenderDashboardBreadcrumb = ({ rescueId }) => (
  <Breadcrumbs aria-label="Breadcrumbs">
    <Link color="inherit" to={routes.index}>
      Dashboard
    </Link>

    <Typography color="textPrimary">{rescueId}</Typography>
  </Breadcrumbs>
);

const RescueEditBreadcrumb = (isMobile = false) => {
  const { rescueId } = useParams();
  const location = useLocation();

  if (!get(location, 'state.prevPath')) {
    return <RenderDashboardBreadcrumb rescueId={rescueId} />;
  }

  const {
    state: { prevPath },
  } = location;

  let origin = matchPath(prevPath, {
    path: routes.rescues,
    exact: true,
  })
    ? 'Upcoming Rescues'
    : undefined;

  origin = matchPath(prevPath, {
    path: routes.rescuesSchedule,
    exact: true,
  })
    ? 'Schedule'
    : origin;

  origin = matchPath(prevPath, {
    path: routes.mobileDashboardSchedule,
    exact: true,
  })
    ? 'Schedule'
    : origin;

  origin = matchPath(prevPath, {
    path: routes.mobileToDoUnclaimedRescues,
    exact: true,
  })
    ? 'Unclaimed Rescues'
    : origin;

  origin =
    matchPath(prevPath, {
      path: routes.dashboardRescuer,
      exact: true,
    }) ||
    matchPath(prevPath, {
      path: routes.dashboardSC,
      exact: true,
    })
      ? 'Dashboard'
      : origin;

  if (!origin) {
    return <RenderDashboardBreadcrumb rescueId={rescueId} />;
  }

  if (isMobile) {
    return (
      <Breadcrumbs aria-label="Breadcrumbs">
        <StyledRescuerBreadcrumb
          component={Link}
          to={routes.mobileDashboard}
          label="Home"
          icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
        />
        {origin === 'Unclaimed Rescues' && (
          <StyledRescuerTextBreadcrumb component={Link} to={routes.mobileToDo} label="To-do" />
        )}
        <StyledRescuerTextBreadcrumb
          component={Link}
          to={{ pathname: prevPath, state: location.state }}
          label={origin}
        />
        <StyledRescuerTextBreadcrumb component="a" label="Rescue Details" />
      </Breadcrumbs>
    );
  }

  return (
    <Breadcrumbs aria-label="Breadcrumbs">
      <Link color="inherit" to={{ pathname: prevPath, state: location.state }}>
        {origin}
      </Link>

      <Typography color="textPrimary">Rescue Details</Typography>
    </Breadcrumbs>
  );
};

export default RescueEditBreadcrumb;
