import React, { useEffect } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { Box, Breadcrumbs, makeStyles, Paper, Typography } from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../../rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';
import routes from '../../../routes';
import ModuleButtons from './ModuleButtons';

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: palette.background.default,
    flexDirection: 'column',
    padding: spacing(1),
  },
  paper: {
    padding: spacing(1),
    paddingBottom: spacing(2),
    marginTop: spacing(1),
  },
  transcript: {
    border: '1px solid black',
    padding: spacing(1),
    marginTop: spacing(1),
    overflow: 'scroll',
    maxHeight: '300px',
  },
  video: {
    maxWidth: '800px',
  },
}));

const LearningCenterMobileVideoBreadcrumbs = ({ label }) => (
  <Box boxShadow={0}>
    <Breadcrumbs aria-label="breadcrumb">
      <StyledRescuerBreadcrumb
        component={Link}
        to={generatePath(routes.mobileDashboard)}
        label="Home"
        icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
      />
      <StyledRescuerTextBreadcrumb component={Link} to={generatePath(routes.mobileDashboardLearn)} label="Learn" />
      <StyledRescuerTextBreadcrumb component="a" href="#" label={label} />
    </Breadcrumbs>
  </Box>
);

const VideoModule = ({ course, nextTab, setCurrentCoursesState, isMobile = false }) => {
  const classes = useStyles();
  const history = useHistory();
  const { id, title, videoDuration: duration, transcript, video } = course;

  if (!id) {
    if (isMobile) {
      return history.push(generatePath(routes.mobileDashboardLearn));
    }
    return history.push(generatePath(routes.learn));
  }

  useEffect(() => {
    setCurrentCoursesState({
      courseId: id,
      modules: { videoModule: true },
    });
  }, [id]);

  return (
    <>
      {isMobile && <LearningCenterMobileVideoBreadcrumbs label={`Learn About ${title}`} />}

      <Box className={classes.container}>
        <Typography variant="h6">
          Learn About {title} - {duration} min
        </Typography>

        <Paper className={classes.paper}>
          <Box py={1}>
            <video src={video} width="100%" height="100%" controls className={classes.video}>
              <track kind="captions" src="" />
              Your browser does not support the video.
            </video>
          </Box>

          <Typography variant="body1">Transcript</Typography>

          <Box className={classes.transcript}>
            <Typography variant="body2">{transcript}</Typography>
          </Box>
        </Paper>

        <ModuleButtons nextTab={nextTab} courseId={id} isMobile={isMobile} />
      </Box>
    </>
  );
};

export default VideoModule;
