import React from 'react';
import { Box, Chip, makeStyles, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  stackContainer: {
    position: 'relative',
  },
  card: {
    position: 'relative',
    backgroundColor: '#fff',
    width: '250px',
    height: '230px',
    borderRadius: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
    transition: 'all 0.2s ease-in-out',
    '&:active': {
      transform: 'rotate(3deg)',
      transformOrigin: 'center',
    },
    '&:before': {
      position: 'absolute',
      zIndex: 0,
      top: 0,
      left: 0,
      width: '100%',
      height: '10%',
      content: '""',
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      backgroundColor: (props) => props?.color || '',
    },
  },
  currentCard: {
    zIndex: 10,
    transform: 'translateX(-10px) translateY(-10px)',
  },
  middleCard: {
    position: 'absolute',
    top: 0,
    zIndex: 5,
    transform: 'translateX(-5px) translateY(-5px)',
  },
  nextCard: {
    zIndex: 1,
    top: 0,
    position: 'absolute',
  },
  cardText: {
    textAlign: 'center',
    fontWeight: 500,
  },
}));

const Counter = withStyles({
  root: {
    backgroundColor: '#f7b924',
    fontWeight: 700,
    position: 'absolute',
    left: -7,
    top: -7,
  },
})(({ classes, label }) => <Chip size="small" classes={classes} label={label} />);

const ToDoStack = ({ onClick, badgeCounter = 0, color = '', name = '', isLoading = false }) => {
  const classes = useStyles({ badgeCounter, color });

  return (
    <Box className={classes.stackContainer} onClick={onClick}>
      {isLoading && (
        <>
          <Box boxShadow={3} className={classNames(classes.card, classes.currentCard)}>
            <Skeleton width={220} />
          </Box>
          <Box boxShadow={3} className={classNames(classes.card, classes.middleCard)}>
            <Skeleton width={220} />
          </Box>
          <Box boxShadow={3} className={classNames(classes.card, classes.nextCard)}>
            <Skeleton width={220} />
          </Box>
        </>
      )}

      {!isLoading && (
        <>
          <Box boxShadow={3} className={classNames(classes.card, badgeCounter > 1 && classes.currentCard)}>
            <Typography variant="subtitle1" className={classes.cardText}>
              {name}
            </Typography>
            <Counter label={badgeCounter} />
          </Box>
          {badgeCounter > 1 && (
            <Box
              boxShadow={3}
              className={classNames(classes.card, badgeCounter > 2 ? classes.middleCard : classes.nextCard)}
            />
          )}
          {badgeCounter > 2 && <Box boxShadow={3} className={classNames(classes.card, classes.nextCard)} />}
        </>
      )}
    </Box>
  );
};

export default ToDoStack;
