import React, { Component } from 'react';
import { generatePath, Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { Button, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Add as AddIcon,
  AddCircle as AddBoldIcon,
  DateRange as DateRangeIcon,
  Kitchen as KitchenIcon,
  Storefront as StoreFrontIcon,
  LocalGroceryStore as LocalGroceryStoreIcon,
  StoreMallDirectory as StoreMallDirectoryIcon,
  DirectionsCar as DirectionsCarIcon,
  Settings as SettingsIcon,
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  TrendingUp as TrendingUpIcon,
  List as ListIcon,
  AccountCircle as AccountCircleIcon,
  ChevronLeft as ChevronLeftIcon,
  SupervisedUserCircle as UsersIcon,
  LocationCity as LocationCityIcon,
  PersonAdd as PersonAddIcon,
  Map as MapIcon,
  PhotoLibrary as PhotoLibraryIcon,
  EmojiPeople as EmojiPeopleIcon,
  Email as EmailIcon,
  BeachAccess as BeachAccessIcon,
  ThumbUp as ThumbUpIcon,
  School as SchoolIcon,
} from '@material-ui/icons';
import { Roles } from '../models/roles';
import * as authService from '../services/auth';
import routes from '../routes';
import { UserSlashIcon, FoodDonorSlashIcon } from '../assets/SvgIcons';
import IconWithText from './IconWithText';
import errorMessages from '../assets/errorMessages';

const ListItemLink = ({ icon, primary, to, isDrawerOpen, dataTestId, onLinkClick }) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isActive = pathname.includes(to);

  if (isDrawerOpen) {
    return (
      <li>
        <ListItem button component={Link} to={to} onClick={onLinkClick} data-testid={dataTestId}>
          <ListItemIcon style={isActive ? { color: theme.palette.primary.main} : {}}>{icon}</ListItemIcon>
          <ListItemText primary={primary} />
        </ListItem>
      </li>
    );
  }

  return (
    <li>
      <Tooltip title={primary} placement="right" arrow>
        <ListItem button component={Link} to={to} onClick={onLinkClick} data-testid={dataTestId}>
          <ListItemIcon style={isActive ? { color: theme.palette.primary.main} : {}}>{icon}</ListItemIcon>
        </ListItem>
      </Tooltip>
    </li>
  );
};

class MainDrawer extends Component {
  renderDrawerContent = () => {
    const {
      classes,
      closeDrawer,
      user,
      isDrawerOpen,
      isMobileView,
      featureFlagsList,
      disableRequestToDonate,
    } = this.props;

    const currentlyLoggedInUser = authService.getCurrentlyLoggedInUser();
    let showSitesInMenu = false;

    if (user.role_assignments !== undefined) {
      showSitesInMenu = authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
        Roles.Admin,
        Roles.NationalSiteDirector,
        Roles.SiteDirector,
      ])
        ? true
        : authService.getCurrentlyLoggedInOrImpersonatingUserAvailableSitesIds().length > 1;
    }

    const handleListItemClick = () => {
      if (isMobileView) {
        closeDrawer();
      }
    };

    return (
      <>
        <div className={classes.toolbarIcon}>
          <IconButton onClick={closeDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <List className={classes.menuItems}>
          {authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
            Roles.Admin,
            Roles.NationalSiteDirector,
            Roles.SiteDirector,
            Roles.SiteCoordinator,
          ]) && (
            <>
              <ListItemLink
                onLinkClick={handleListItemClick}
                isDrawerOpen={isDrawerOpen}
                to={generatePath(routes.rescuesList)}
                primary="Rescues"
                dataTestId="donations"
                icon={<LocalGroceryStoreIcon data-introid="drawer-donations" />}
              />
              <ListItemLink
                onLinkClick={handleListItemClick}
                isDrawerOpen={isDrawerOpen}
                to={generatePath(routes.allUsers)}
                primary="Users"
                icon={<UsersIcon data-introid="drawer-users" />}
                dataTestId="users-menu"
              />
              <ListItemLink
                onLinkClick={handleListItemClick}
                isDrawerOpen={isDrawerOpen}
                to={routes.map}
                primary="Map"
                icon={<MapIcon data-introid="map" />}
                dataTestId="map"
              />

              <ListItemLink
                onLinkClick={handleListItemClick}
                isDrawerOpen={isDrawerOpen}
                to={routes.rescuesGallery}
                primary="Rescue Photo Gallery"
                icon={<PhotoLibraryIcon data-introid="rescue-gallery" />}
                dataTestId="rescue-photo-gallery"
              />
            </>
          )}

          {authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
            Roles.Admin,
            Roles.NationalSiteDirector,
            Roles.SiteDirector,
            Roles.SiteCoordinator,
            Roles.DonorAdmin,
            Roles.DonorStaff,
            // Remove feature flag when ready
            get(featureFlagsList, 'receivingAgencyReport', false) && Roles.ReceiverLocationAdmin,
          ]) && (
            <ListItemLink
              onLinkClick={handleListItemClick}
              isDrawerOpen={isDrawerOpen}
              to={generatePath(routes.reports)}
              primary="Reports"
              icon={<IconWithText text="REP" icon={<TrendingUpIcon data-introid="drawer-reports" />} />}
            />
          )}

          {showSitesInMenu && (
            <ListItemLink
              onLinkClick={handleListItemClick}
              isDrawerOpen={isDrawerOpen}
              to={routes.sites}
              primary="Sites"
              icon={<StoreMallDirectoryIcon data-introid="drawer-sites-menu" />}
              dataTestId="sites-menu"
            />
          )}

          {authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
            Roles.Admin,
            Roles.NationalSiteDirector,
            Roles.SiteDirector,
            Roles.SiteCoordinator,
          ]) && (
            <>
              {get(featureFlagsList, 'marketingHubFF', false) && (
                <ListItemLink
                  onLinkClick={handleListItemClick}
                  isDrawerOpen={isDrawerOpen}
                  to={generatePath(routes.marketingHub)}
                  primary="Marketing Hub"
                  icon={<ThumbUpIcon data-introid="drawer-marketing-hub" />}
                />
              )}

              <ListItemLink
                onLinkClick={handleListItemClick}
                isDrawerOpen={isDrawerOpen}
                to={routes.emails}
                primary="Sent Emails"
                icon={<EmailIcon data-introid="drawer-sent-emails" />}
              />

              <ListItemLink
                onLinkClick={handleListItemClick}
                isDrawerOpen={isDrawerOpen}
                to={routes.sms}
                primary="Sent SMS"
                icon={<EmailIcon data-introid="drawer-sent-sms" />}
              />

              {get(featureFlagsList, 'events', false) && (
                <ListItemLink
                  onLinkClick={handleListItemClick}
                  isDrawerOpen={isDrawerOpen}
                  to={routes.eventsList}
                  primary="Events"
                  icon={<EmojiPeopleIcon data-introid="drawer-events" />}
                />
              )}
            </>
          )}
        </List>

        <Divider />

        <List>
          {authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([Roles.Rescuer]) && (
            <ListItemLink
              onLinkClick={handleListItemClick}
              isDrawerOpen={isDrawerOpen}
              to={generatePath(routes.dashboardRescuer)}
              primary="Dashboard Rescuer"
              dataTestId="dashboard-rescuer"
              icon={<DashboardIcon />}
            />
          )}

          {authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
            Roles.SiteCoordinator,
            Roles.SiteDirector,
          ]) && (
            <ListItemLink
              onLinkClick={handleListItemClick}
              isDrawerOpen={isDrawerOpen}
              to={generatePath(routes.dashboardSC)}
              primary="Dashboard SD/SC"
              icon={<DashboardIcon />}
            />
          )}

          {authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
            Roles.DonorLocationAdmin,
            Roles.DonorLocationStaff,
          ]) && (
            <>
              <ListItemLink
                onLinkClick={handleListItemClick}
                isDrawerOpen={isDrawerOpen}
                to={generatePath(routes.dashboardFoodDonor)}
                primary="Food Donor Dashboard"
                dataTestId="drawer-food-donor-dashboard"
                icon={<DashboardIcon />}
              />

              <ListItemLink
                onLinkClick={handleListItemClick}
                isDrawerOpen={isDrawerOpen}
                to={generatePath(routes.donation_requests)}
                primary="Donation Requests"
                icon={<LocalGroceryStoreIcon data-introid="drawer-donations" />}
                dataTestId="donation-requests"
              />

              <li>
                <Tooltip
                  title={(() => {
                    if (disableRequestToDonate) {
                      return errorMessages.FOOD_DONOR_ACCOUNT_NOT_VERIFIED.message;
                    }
                    return isDrawerOpen ? '' : 'Request to Donate';
                  })()}
                  placement="right"
                >
                  <span>
                    <ListItem
                      disabled={disableRequestToDonate}
                      button
                      component={Link}
                      to={routes.donation_create_request}
                      data-testid="request-to-donate"
                    >
                      {isDrawerOpen ? (
                        <Button
                          startIcon={<AddIcon />}
                          type="button"
                          variant="contained"
                          color="primary"
                          size="small"
                        >
                          Request to Donate
                        </Button>
                      ) : (
                        <ListItemIcon>{<AddBoldIcon color="secondary" />}</ListItemIcon>
                      )}
                    </ListItem>
                  </span>
                </Tooltip>
              </li>
            </>
          )}

          {authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
            Roles.ReceiverAdmin,
            Roles.ReceiverStaff,
            Roles.ReceiverLocationAdmin,
            Roles.ReceiverLocationStaff,
          ]) && (
            <ListItemLink
              onLinkClick={handleListItemClick}
              isDrawerOpen={isDrawerOpen}
              to={generatePath(routes.dashboardReceiver)}
              primary="Dashboard Receiving Agency"
              icon={<DashboardIcon />}
            />
          )}

          {authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([
            Roles.NationalSiteDirector,
          ]) && (
            <ListItemLink
              onLinkClick={handleListItemClick}
              isDrawerOpen={isDrawerOpen}
              to={generatePath(routes.dashboardNSD)}
              primary="Dashboard NSD"
              icon={<DashboardIcon />}
            />
          )}

          {get(featureFlagsList, 'learningCenterFF', false) && (
            <ListItemLink
              onLinkClick={handleListItemClick}
              isDrawerOpen={isDrawerOpen}
              to={generatePath(routes.learn)}
              primary="Learn"
              icon={<SchoolIcon />}
            />
          )}

          {authService.hasAnyRoleInCurrentlySelectedSite(currentlyLoggedInUser, [
            Roles.Admin,
            Roles.NationalSiteDirector,
          ]) && (
            <ListItemLink
              onLinkClick={handleListItemClick}
              isDrawerOpen={isDrawerOpen}
              to={routes.switchUser}
              primary="Switch User"
              icon={<AccountCircleIcon />}
              dataTestId="switch-user-menu"
            />
          )}

          {authService.currentlyLoggedInOrImpersonatingUserHasRoleInCurrentlySelectedSite(Roles.Admin) && (
            <ListItemLink
              onLinkClick={handleListItemClick}
              isDrawerOpen={isDrawerOpen}
              to={generatePath(routes.settings)}
              primary="Manage Settings"
              icon={<SettingsIcon />}
            />
          )}
        </List>
      </>
    );
  };

  render() {
    const { classes, isDrawerOpen, isMobileView } = this.props;

    return (
      <div data-introid="main-drawer">
        {isMobileView ? (
          <Drawer
            variant="temporary"
            classes={{ paper: classNames(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose) }}
            open={isDrawerOpen}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {this.renderDrawerContent()}
          </Drawer>
        ) : (
          <Drawer
            classes={{ paper: classNames(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose) }}
            variant="permanent"
            open={isDrawerOpen}
          >
            {this.renderDrawerContent()}
          </Drawer>
        )}
      </div>
    );
  }
}

MainDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const drawerWidth = 220;
const styles = theme => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    paddingTop: 4,
    paddingBottom: 3,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  menuItems: {
    '&:empty': {
      padding: 0,
    },
  },
});

export default withStyles(styles)(MainDrawer);
