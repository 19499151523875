import React, { useEffect } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Breadcrumbs, makeStyles, Typography } from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';
import moment from 'moment';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../../rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';
import routes from '../../../routes';
import useActiveSite from '../../../hooks/useActiveSite';
import { hasAnyRoleInSite } from '../../../services/auth';
import useActiveUser from '../../../hooks/useActiveUser';
import { Roles } from '../../../models/roles';
import { fetchRequestDonationList } from '../../../actions/requestDonations';
import { fetchFoodDonorsWithPendingVerifications } from '../../../actions/foodDonors';
import { fetchSitePickupSpecs, fetchSiteUnclaimedRescues } from '../../../actions/sites';
import ToDoStack from './ToDoStack';
import { Colors } from '../../../assets/theme/Colors';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: palette.background.default,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noData: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    '-webkit-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
}));

const stackColors = {
  unclaimedRescues: Colors.rescues.unclaimed.color,
  donationsPickupsWithNoReceiver: Colors.schedule.color,
  pendingDonations: Colors.requestDonationAdd.color,
  pendingFoodDonors: Colors.pendingFoodDonor.color,
};

const SDToDoMobileBreadcrumbs = () => (
  <Box boxShadow={0}>
    <Breadcrumbs aria-label="breadcrumb">
      <StyledRescuerBreadcrumb
        component={Link}
        to={generatePath(routes.mobileDashboard)}
        label="Home"
        icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
      />
      <StyledRescuerTextBreadcrumb component="a" href="#" label="To-do" />
    </Breadcrumbs>
  </Box>
);

const SDToDoMobile = () => {
  const classes = useStyles();
  const activeSite = useActiveSite();
  const activeUser = useActiveUser();
  const history = useHistory();
  const dispatch = useDispatch();
  const pendingDonationsEntities = useSelector((state) => state.entities.requestDonations);
  const pendingDonationsCount = pendingDonationsEntities.allIds.length;
  const pendingFoodDonorsEntities = useSelector((state) => state.entities.foodDonors);
  const pendingFoodDonorsCount = pendingFoodDonorsEntities.allIds.length;
  const unclaimedRescuesEntities = useSelector((state) => state.entities.sites.unclaimedRescues);
  const unclaimedRescuesCount = (unclaimedRescuesEntities.bySiteId[activeSite.id] || []).length;
  const pickupSpecsEntities = useSelector((state) => state.entities.sites.pickupSpecs);
  const donationsPickupsWithNoReceiver = [
    ...(pickupSpecsEntities.bySiteId[activeSite.id] || []).reduce(
      (acc, ps) =>
        acc.set(ps.donation_id, {
          ...ps,
          id: ps.donation_id,
        }),
      new Map()
    ),
  ];

  const isLoading =
    pendingDonationsEntities.inflight ||
    pendingFoodDonorsEntities.inflight ||
    unclaimedRescuesEntities.inflight ||
    pickupSpecsEntities.inflight;

  const count =
    pendingFoodDonorsCount + pendingDonationsCount + unclaimedRescuesCount + donationsPickupsWithNoReceiver.length;

  const isSiteDirector = hasAnyRoleInSite(activeUser, activeSite.id, [
    Roles.SiteDirector,
    Roles.SiteCoordinator,
    Roles.NationalSiteDirector,
    Roles.Admin,
  ]);

  useEffect(() => {
    dispatch(
      fetchRequestDonationList({
        siteIds: [activeSite.id],
        verificationStatus: 'pending',
      })
    );
    dispatch(fetchFoodDonorsWithPendingVerifications([activeSite.id]));
    dispatch(
      fetchSitePickupSpecs(activeSite.id, false, {
        unassigned: true,
      })
    );
    dispatch(
      fetchSiteUnclaimedRescues(moment().format('YYYYMMDD'), moment().add(10, 'day').format('YYYYMMDD'), activeSite.id)
    );
  }, [dispatch, isSiteDirector]);

  if (!isSiteDirector) {
    return history.push(routes.index);
  }

  if (count <= 0) {
    return (
      <>
        <SDToDoMobileBreadcrumbs />
        <Box className={classes.container}>
          <Box className={classes.noData}>
            <Typography color="textSecondary" variant="h5">
              You’re all caught up, great work!
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <SDToDoMobileBreadcrumbs />

      <Box className={classes.container}>
        {isLoading && (
          <>
            <ToDoStack isLoading={isLoading} />
            <ToDoStack isLoading={isLoading} />
          </>
        )}

        {!isLoading && unclaimedRescuesCount > 0 && (
          <ToDoStack
            name="Unclaimed Rescues"
            badgeCounter={unclaimedRescuesCount}
            color={stackColors.unclaimedRescues}
            onClick={() => history.push(routes.mobileToDoUnclaimedRescues)}
          />
        )}

        {!isLoading && donationsPickupsWithNoReceiver.length > 0 && (
          <ToDoStack
            name="No Receiver Assigned"
            badgeCounter={donationsPickupsWithNoReceiver.length}
            color={stackColors.donationsPickupsWithNoReceiver}
            onClick={() => history.push(routes.mobileToDoNoReceiverAssigned)}
          />
        )}

        {!isLoading && pendingDonationsCount > 0 && (
          <ToDoStack
            name="Pending Donations"
            badgeCounter={pendingDonationsCount}
            color={stackColors.pendingDonations}
            onClick={() => history.push(routes.pendingDonations)}
          />
        )}

        {!isLoading && pendingFoodDonorsCount > 0 && (
          <ToDoStack
            name="Pending Food Donors"
            badgeCounter={pendingFoodDonorsCount}
            color={stackColors.pendingFoodDonors}
            onClick={() => history.push(routes.pendingFoodDonorsRegistrations)}
          />
        )}
      </Box>
    </>
  );
};

export default SDToDoMobile;
