import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Button, CardContent, CardHeader, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import moment from 'moment';
import { DashboardTableCard } from '../../../components/Dashboard/DashboardTableCard';
import SCDashboardSiteGoalsProgress from './SCDashboardSiteGoalsProgress';
import routes from '../../../routes';
import useHasActiveFeatureFlag from '../../../hooks/useFeatureFlags';
import { FF_SITE_GOALS } from '../../../components/FeatureFlagWrapper';
import { fetchSiteGoals } from '../../../actions/siteGoals';
import { sortByDateAndTime } from '../../../helpers/sorters';

const SCDashboardSiteGoals = () => {
  const siteId = useSelector((state) => state.app.site.id);
  const siteGoalsEntities = useSelector((state) => state.entities.siteGoals);
  const history = useHistory();
  const dispatch = useDispatch();
  const hasActiveSiteGoalsFeatureFlag = useHasActiveFeatureFlag(FF_SITE_GOALS);
  const siteGoalsLoading = siteGoalsEntities.inflight;
  const goalsList = Object.values(siteGoalsEntities.byId).sort((goal1, goal2) =>
    sortByDateAndTime(
      'desc',
      `${moment(goal1.date, 'YYYY-MM-DD').format('YYYY-MM-DD')} 00:00:00`,
      `${moment(goal2.date, 'goal2.date').format('YYYY-MM-DD')} 00:00:00`
    )
  );

  useEffect(() => {
    if (hasActiveSiteGoalsFeatureFlag) {
      dispatch(fetchSiteGoals({ sites_ids: siteId }));
    }
  }, []);

  return (
    <>
      <Box mb={2}>
        <Typography variant="h6" color="textSecondary">
          Site Goals
        </Typography>
      </Box>
      <DashboardTableCard isLoading={siteGoalsLoading}>
        <CardHeader
          action={
            <Button
              onClick={() => history.push(routes.siteGoalAdd)}
              startIcon={<Add />}
              variant="contained"
              color="primary"
              size="small"
            >
              Add Site Goal
            </Button>
          }
        />
        <CardContent>
          <SCDashboardSiteGoalsProgress siteGoalsList={goalsList} />
        </CardContent>
      </DashboardTableCard>
    </>
  );
};

export default SCDashboardSiteGoals;
