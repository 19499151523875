import React from 'react';
import RescueEditBreadcrumb from './RescueEditBreadcrumb';
import { FormPaperContainer, GridRow, RowContent, RowTitle } from './FormGrid';
import moment from 'moment';
import { Box, Grid, IconButton, Tooltip, useMediaQuery } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Linkify from 'react-linkify';
import {
  formatPhoneNumber,
  formatRescueDistance,
  formatRescueDuration
} from '../../../helpers/formatters';
import {
  getRescueDropoffAddress,
  getRescueFoodTypes,
  getRescuePickupAddress,
  getRescuePickupLocationFullName,
} from '../../../helpers/RescuesHelper';
import DonorLinkIcon from '../../../components/DonorLinkIcon';
import useHasActiveFeatureFlag from '../../../hooks/useFeatureFlags';
import { FF_DONOR_LINK } from '../../../components/FeatureFlagWrapper';

const handleAddressClick = address => window.open(`https://maps.google.com/?q=${address}`, '_blank');

const useStyles = makeStyles(() => ({
  addressRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  timePicker: {
    '& input': {
      textAlign: 'center',
    },
  },
  breakWhiteSpaces: {
    whiteSpace: 'pre-wrap',
  },
  donorLinkIcon: {
    top: 8,
    right: 8,
    position: 'absolute',
  },
}));

const RescueDetailsView = ({ rescue, testId, children }) => {
  const classes = useStyles();
  const foodTypes = getRescueFoodTypes(rescue);
  const pickupAddress = getRescuePickupAddress(rescue);
  const dropoffAddress = getRescueDropoffAddress(rescue);
  const hasDonorLinkFF = useHasActiveFeatureFlag(FF_DONOR_LINK);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <>
      <RescueEditBreadcrumb isMobile={isMobileView} />
      <FormPaperContainer testId={testId}>
        <GridRow>
          <RowTitle>Date:</RowTitle>
          <RowContent>{moment(rescue.date, 'YYYY-MM-DD').format('dddd, MMMM Do')}</RowContent>
        </GridRow>

        <GridRow>
          <RowTitle>From:</RowTitle>
          <RowContent>
            {getRescuePickupLocationFullName(rescue)}
          </RowContent>
        </GridRow>

        <GridRow>
          <RowTitle>To:</RowTitle>
          <RowContent>{rescue.receiver}</RowContent>
        </GridRow>

        <GridRow>
          <RowTitle>
            Distance / Duration:
          </RowTitle>

          <RowContent>
            {`${formatRescueDistance(rescue.distance, ' miles')} / ${formatRescueDuration(rescue.duration, false)}`}
          </RowContent>
        </GridRow>

        <GridRow hidden={!rescue.food_types}>
          <RowTitle>
            Food Type(s):
          </RowTitle>

          <RowContent>
            {foodTypes}
          </RowContent>
        </GridRow>

        <GridRow hidden={!rescue.slug}>
          <RowTitle>
            Description:
          </RowTitle>

          <RowContent>
            {rescue.slug}
          </RowContent>
        </GridRow>

        <GridRow hidden={!rescue.rescue_size}>
          <RowTitle>Rescue Vehicle Size:</RowTitle>
          <RowContent>{rescue.rescue_size}</RowContent>
        </GridRow>

        <GridRow>{children}</GridRow>

        <GridRow>
          <RowTitle>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Grid item>Pickup Address:</Grid>
              <Grid item>
                <Box ml={4}>
                  <IconButton size="small" aria-label="delete" onClick={() => handleAddressClick(pickupAddress)}>
                    <RoomIcon htmlColor="#000" fontSize="small" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </RowTitle>
          <RowContent>
            <span className={classes.addressRow} onClick={() => handleAddressClick(pickupAddress)}>
              {pickupAddress}
            </span>
          </RowContent>
        </GridRow>

        <GridRow>
          <RowTitle>Pickup Contact:</RowTitle>
          {rescue.pickup_contact && <RowContent>Name: {rescue.pickup_contact}</RowContent>}
          {rescue.pickup_contact_phone && (
            <RowContent>
              <span>Phone: </span>
              <a href={`tel:${rescue.pickup_contact_phone}`}>
                {formatPhoneNumber(rescue.pickup_contact_phone)}
              </a>
              {rescue.pickup_contact_phone_ext && <span>{` (ext: ${rescue.pickup_contact_phone_ext})`}</span>}
            </RowContent>
          )}
          {rescue.pickup_contact_email && (
            <RowContent>
              Email: <a href={`mailto:${rescue.pickup_contact_email}`}>{rescue.pickup_contact_email}</a>
            </RowContent>
          )}
        </GridRow>

        <GridRow hidden={!rescue.pickup_notes}>
          <RowTitle>Pickup Notes:</RowTitle>
          <RowContent>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
             )}
            >
              <span className={classes.breakWhiteSpaces}>{rescue.pickup_notes}</span>
            </Linkify>
          </RowContent>
        </GridRow>

        <GridRow>
          <RowTitle>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <Grid item>Dropoff Address:</Grid>
              <Grid item>
                <Box ml={4}>
                  <IconButton size="small" aria-label="delete" onClick={() => handleAddressClick(dropoffAddress)}>
                    <RoomIcon htmlColor="#000" fontSize="small" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </RowTitle>
          <RowContent>
            <span className={classes.addressRow} onClick={() => handleAddressClick(dropoffAddress)}>
              {dropoffAddress}
            </span>
          </RowContent>
        </GridRow>

        <GridRow>
          <RowTitle>Dropoff Contact:</RowTitle>
          {rescue.dropoff_contact && <RowContent>Name: {rescue.dropoff_contact}</RowContent>}
          {rescue.dropoff_contact_phone && (
            <RowContent>
              <span>Phone: </span>
              <a href={`tel:${rescue.dropoff_contact_phone}`}>
                {formatPhoneNumber(rescue.dropoff_contact_phone)}
              </a>
              {rescue.dropoff_contact_phone_ext && <span>{` (ext: ${rescue.dropoff_contact_phone_ext})`}</span>}
            </RowContent>
          )}
          {rescue.dropoff_contact_email && (
            <RowContent>
              Email: <a href={`mailto:${rescue.dropoff_contact_email}`}>{rescue.dropoff_contact_email}</a>
            </RowContent>
          )}
        </GridRow>

        <GridRow hidden={!rescue.dropoff_notes}>
          <RowTitle>Dropoff Notes:</RowTitle>
          <RowContent>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              <span className={classes.breakWhiteSpaces}>{rescue.dropoff_notes}</span>
            </Linkify>
          </RowContent>
        </GridRow>

        {(hasDonorLinkFF && !!rescue.location_donor_link_active) && (
          <Tooltip arrow placement="top" title="This is a Donor Link rescue">
            <span className={classes.donorLinkIcon}>
              <DonorLinkIcon size={24} isActive={true} />
            </span>
          </Tooltip>
        )}
      </FormPaperContainer>
    </>
  );
};

export default RescueDetailsView;
