import omit from 'lodash/omit';
import { objectFromArray, objectFromArrayWithDup } from '../helpers';
import * as sitesActions from '../actions/sites';
import * as usersActions from '../actions/users';
import * as usersModel from '../models/users';
import * as rescuesModel from '../models/rescues';
import { apiToStore } from '../models/rescues';
import { getRescueClaimer } from '../helpers/RescuesHelper';
import { sortArrayOfObjects } from '../helpers/sorters';
import { getUserFullName } from '../helpers/user';

export const initialSitesState = {
  byId: {},
  allIds: [],
  lastUpdated: null,
  donations: {
    byId: {},
    allIds: [],
    bySiteId: [],
    lastUpdated: null,
  },
  donors: {
    byId: {},
    allIds: [],
    bySiteId: [],
    lastUpdated: null,
  },
  donorsLocations: {
    byId: {},
    allIds: [],
    bySiteId: {},
    lastUpdated: null,
  },
  events: {
    byId: {},
    allIds: [],
    bySiteId: {},
    inflight: false,
    lastUpdated: null,
  },
  eventsList: {
    byId: {},
    allIds: [],
    bySiteId: {},
    inflight: false,
    lastUpdated: null,
  },
  insights: {
    bySiteId: {},
    inflight: false,
    lastUpdated: null,
  },
  leaderboards: {
    foodDonors: [],
    receivingAgencies: [],
    rescuers: [],
    inflight: false,
    lastUpdated: null,
  },
  metrics: {
    bySiteId: {},
    inflight: false,
    lastUpdated: null,
  },
  pastRescues: {
    byId: {},
    allIds: [],
    inflight: false,
    lastUpdated: null,
  },
  photos: {
    byId: {},
    allIds: [],
    inflight: false,
    lastUpdated: null,
  },
  pickupLocations: {
    byId: {},
    byFoodDonorId: {},
    allIds: [],
    inflight: false,
    lastUpdated: null,
  },
  pickupSpecs: {
    byId: {},
    bySiteId: {},
    allIds: [],
    inflight: false,
    lastUpdated: null,
  },
  receivers: {
    byId: {},
    allIds: [],
    bySiteId: [],
    lastUpdated: null,
    inflight: false,
  },
  rescues: {
    byId: {},
    allIds: [],
    modifiedIds: [],
    modifiedById: {},
    bySiteId: {},
    inflight: false,
    lastUpdated: null,
  },
  rescuers: {
    byId: {},
    allIds: [],
    bySiteId: [],
    inflight: false,
    lastUpdated: null,
    photos: {
      bySiteId: {},
      inflight: false,
      lastUpdated: null,
    }
  },
  unclaimedRescues: {
    byId: {},
    bySiteId: {},
    failed: false,
    inflight: false,
    lastUpdated: null,
  },
  users: {
    byId: {},
    allIds: [],
    bySiteId: [],
    lastUpdated: null,
  },
  emails: {
    byId: {},
    allIds: [],
    bySiteId: [],
    lastUpdated: null,
  },
  sms: {
    byId: {},
    allIds: [],
    bySiteId: [],
    lastUpdated: null,
  },
};

const sitesReducer = (state = initialSitesState, action) => {
  const receiveSites = data => ({
    inflight: false,
    byId: objectFromArray(data.sites, r => r.id),
    allIds: data.sites.map(r => r.id),
    lastUpdated: data.receivedAt,
  });

  const updateUserData = (appState, userData) => {
    const usersListById = appState.users.byId;
    usersListById[userData.id] = {
      ...usersListById[userData.id],
      ...userData,
    };
    const user = usersListById[userData.id];
    const userListBySiteId = appState.users.bySiteId[user.site_id];
    if (userListBySiteId) {
      const userIndexInSite = userListBySiteId.findIndex(userList => userList.id === user.id);
      userListBySiteId[userIndexInSite] = user;
      return {
        ...appState.users,
        byId: usersListById,
        bySiteId: {
          ...appState.users.bySiteId,
          [user.site_id]: userListBySiteId,
        },
      };
    }

    return {
      ...appState.users,
      byId: usersListById,
    };
  };

  switch (action.type) {
    case usersActions.DELETE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          ..._deleteUser(action.user, state.users.byId, state.users.allIds, state.users.bySiteId),
          lastUpdated: action.receivedAt,
        },
      };
    case usersActions.UPDATE_USER:
      return {
        ...state,
        users: updateUserData(state, action.user),
      };
    case sitesActions.REQUEST_SITES:
      return {
        ...state,
        inflight: true,
      };
    case sitesActions.RECEIVE_SITES:
      return {
        ...state,
        ...receiveSites(action),
      };
    case sitesActions.RECEIVE_ONE_SITE:
      return {
        ...state,
        ...receiveSites({
          sites: [...Object.values(state.byId), action.site],
          receivedAt: action.receivedAt,
        }),
      };
    case sitesActions.REQUEST_SITE_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          ...{
            inflight: true,
          },
        },
      };
    case sitesActions.RECEIVE_SITE_USERS:
      const siteUsers = action.siteUsers.map(siteUser => usersModel.apiToStore(siteUser));

      return {
        ...state,
        users: {
          inflight: false,
          byId: objectFromArray(siteUsers, siteUser => siteUser.id),
          allIds: siteUsers.map(siteUser => siteUser.id),
          bySiteId: objectFromArrayWithDup(siteUsers, siteUser => siteUser.site_id),
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.REQUEST_SITE_METRICS:
      return {
        ...state,
        metrics: {
          bySiteId: {
            ...state.metrics.bySiteId,
            inflight: true,
            [action.siteId]: {
              ...state.metrics.bySiteId[action.siteId],
              inflight: true,
            },
          },
        },
      };
    case sitesActions.INVALIDATE_SITE_METRICS:
      return {
        ...state,
        metrics: initialSitesState.metrics,
      };
    case sitesActions.RECEIVE_SITE_METRICS:
      return {
        ...state,
        metrics: {
          bySiteId: {
            ...state.metrics.bySiteId,
            inflight: false,
            lastUpdated: action.receivedAt,
            [action.siteId]: {
              ...action.metrics,
              inflight: false,
              lastUpdated: action.receivedAt,
            },
          },
        },
      };
    case sitesActions.REQUEST_SITE_INSIGHTS:
      return {
        ...state,
        insights: {
          bySiteId: {
            ...state.insights.bySiteId,
            inflight: true,
            [action.siteId]: {
              ...state.insights.bySiteId[action.siteId],
              inflight: true,
            },
          },
        },
      };
    case sitesActions.INVALIDATE_SITE_INSIGHTS:
      return {
        ...state,
        insights: initialSitesState.insights,
      };
    case sitesActions.RECEIVE_SITE_INSIGHTS:
      return {
        ...state,
        insights: {
          bySiteId: {
            ...state.insights.bySiteId,
            inflight: false,
            lastUpdated: action.receivedAt,
            [action.siteId]: {
              ...action.insights,
              inflight: false,
              lastUpdated: action.receivedAt,
            },
          },
        },
      };
    case sitesActions.REQUEST_SITE_DONORS_LOCATIONS:
      return {
        ...state,
        donorsLocations: {
          ...state.donorsLocations,
          inflight: true,
        },
      };
    case sitesActions.RECEIVE_SITE_DONORS_LOCATIONS:
      const sortedSiteDonorsLocations = sortArrayOfObjects(action.siteDonorsLocations, row => row.name)
      return {
        ...state,
        donorsLocations: {
          inflight: false,
          byId: objectFromArray(action.siteDonorsLocations, siteDonorLocation => siteDonorLocation.id),
          allIds: action.siteDonorsLocations.map(siteDonorLocation => siteDonorLocation.id),
          bySiteId: objectFromArrayWithDup(sortedSiteDonorsLocations, siteDonorLocation => siteDonorLocation.site_id),
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.REQUEST_SITE_PAST_RESCUES:
      return {
        ...state,
        pastRescues: {
          ...state.pastRescues,
          inflight: true,
        },
      };
    case sitesActions.RECEIVE_SITE_PAST_RESCUES:
      const pastRescues = action.sitePastRescues.map(pr => apiToStore(pr));
      return {
        ...state,
        pastRescues: {
          inflight: false,
          byId: objectFromArray(pastRescues, r => r.id),
          allIds: pastRescues.map(r => r.id),
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.REQUEST_SITE_UNCLAIMED_RESCUES:
      return {
        ...state,
        unclaimedRescues: {
          ...state.unclaimedRescues,
          inflight: true,
        },
      };
    case sitesActions.RECEIVE_SITE_UNCLAIMED_RESCUES:
      return {
        ...state,
        unclaimedRescues: {
          inflight: false,
          byId: {
            ...state.unclaimedRescues.byId,
            ...objectFromArray(action.siteUnclaimedRescues, r => r.id),
          },
          bySiteId: {
            ...state.unclaimedRescues.bySiteId,
            ...objectFromArrayWithDup(action.siteUnclaimedRescues, uRescue => uRescue.site_id),
          },
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.REQUEST_SITE_UNCLAIMED_RESCUES_FAILED:
      return {
        ...state,
        unclaimedRescues: {
          ...state.unclaimedRescues,
          failed: true,
          inflight: false,
          lastUpdated: Date.now(),
        },
      };
    case sitesActions.REQUEST_SITE_RESCUES:
      return {
        ...state,
        rescues: {
          ...state.rescues,
          inflight: true,
        },
      };
    case sitesActions.RECEIVE_SITE_RESCUE:
      const siteRescues = state.rescues.byId;
      siteRescues[action.rescue.id] = action.rescue;
      return {
        ...state,
        rescues: {
          ...state.rescues,
          inflight: false,
          byId: siteRescues,
          allIds: [...new Set([...state.rescues.allIds, action.rescue.id])],
          bySiteId: objectFromArrayWithDup(Object.values(siteRescues), rescue => rescue.site_id),
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.RECEIVE_SITE_RESCUES:
      const rescues = action.rescues.map(r => rescuesModel.apiToStore(r));

      return {
        ...state,
        rescues: {
          ...state.rescues,
          inflight: false,
          byId: objectFromArray(rescues, rescue => rescue.id),
          allIds: rescues.map(rescue => rescue.id),
          bySiteId: objectFromArrayWithDup(rescues, rescue => rescue.site_id),
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.MODIFY_SITE_RESCUE:
      const modifiedRescue = {
        ...state.rescues.byId[action.id],
        ...action.attrs,
      };
      const rescueWithClaimer = {
        ...modifiedRescue,
        claimer: getRescueClaimer(modifiedRescue),
      };

      return {
        ...state,
        rescues: {
          ...state.rescues,
          modifiedIds: state.rescues.modifiedIds.indexOf(action.id) > 0
              ? state.rescues.modifiedIds
              : state.rescues.modifiedIds.concat(action.id),
          modifiedById: Object.assign({}, state.rescues.modifiedById, { [action.id]: rescueWithClaimer }),
        },
      };
    case sitesActions.RESET_SITE_RESCUE:
      const { [action.id]: dontcare, ...newById } = state.rescues.modifiedById;

      return {
        ...state,
        rescues: {
          ...state.rescues,
          modifiedIds: state.rescues.modifiedIds.filter(id => id !== action.id),
          modifiedById: newById,
        },
      };
    case sitesActions.RECEIVE_SITE_RESCUE_POST: {
      const { [action.rescue.id]: dontcare, ...newById } = state.rescues.modifiedById;

      return {
        ...state,
        rescues: {
          ...state.rescues,
          byId: {
            ...state.rescues.byId,
            [action.rescue.id]: rescuesModel.apiToStore(action.rescue),
          },
          bySiteId: objectFromArrayWithDup(
            state.rescues.bySiteId[action.rescue.site_id].map(rescue => {
              if (rescue.id !== action.rescue.id) {
                return rescue;
              }

              return rescuesModel.apiToStore(action.rescue);
            }),
            rescue => rescue.site_id
          ),
          modifiedIds: state.rescues.modifiedIds.filter(_id => _id !== action.rescue.id),
          modifiedById: newById,
        },
      };
    }
    case sitesActions.REQUEST_SITE_RECEIVERS:
      return {
        ...state,
        receivers: {
          ...state.receivers,
          ...{
            inflight: true,
          },
        },
      };
    case sitesActions.RECEIVE_SITE_RECEIVERS:
      const sortedSiteReceivers = sortArrayOfObjects(action.siteReceivers, row => row.name);
      return {
        ...state,
        receivers: {
          inflight: false,
          byId: objectFromArray(action.siteReceivers, siteReceiver => siteReceiver.id),
          allIds: action.siteReceivers.map(siteReceiver => siteReceiver.id),
          bySiteId: {
            ...state.receivers.bySiteId,
            ...objectFromArrayWithDup(sortedSiteReceivers, siteReceiver => siteReceiver.site_id),
          },
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.REQUEST_SITE_RESCUERS:
      return {
        ...state,
        rescuers: {
          ...state.rescuers,
          inflight: true,
        },
      };
    case sitesActions.DELETE_SITE_RESCUER:
      return {
        ...state,
        rescuers: {
          ..._deleteUser(
            usersModel.apiToStore(action.siteRescuer),
            state.rescuers.byId,
            state.rescuers.allIds,
            state.rescuers.bySiteId
          ),
          inflight: false,
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.RECEIVE_SITE_RESCUER_DELETE:
      return {
        ...state,
        rescuers: {
          ..._deleteUser(
            {
              id: action.rescuerId,
              site_id: action.siteId,
            },
            state.rescuers.byId,
            state.rescuers.allIds,
            state.rescuers.bySiteId
          ),
          inflight: false,
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.RECEIVE_SITE_FOOD_DONOR_DELETE:
      return {
        ...state,
        donorsLocations: {
          ..._deleteFoodDonor(
            {
              id: action.foodDonorId,
              site_id: action.siteId,
            },
            state.donorsLocations.byId,
            state.donorsLocations.allIds,
            state.donorsLocations.bySiteId
          ),
          inflight: false,
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.RECEIVE_SITE_RECEIVER_DELETE:
      return {
        ...state,
        receivers: {
          ..._deleteReceiver(
            {
              id: action.receiverId,
              site_id: action.siteId,
            },
            state.receivers.byId,
            state.receivers.allIds,
            state.receivers.bySiteId
          ),
          inflight: false,
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.RECEIVE_SITE_RESCUER:
      return {
        ...state,
        rescuers: {
          ..._addOrUpdateUser(
            usersModel.apiToStore(action.siteRescuer),
            state.rescuers.byId,
            state.rescuers.allIds,
            state.rescuers.bySiteId
          ),
          inflight: false,
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.RECEIVE_SITE_RESCUERS:
      const sortedSiteRescuers = sortArrayOfObjects(action.siteRescuers, getUserFullName);
      return {
        ...state,
        rescuers: {
          inflight: false,
          byId: objectFromArray(action.siteRescuers, siteRescuer => siteRescuer.id),
          allIds: action.siteRescuers.map(siteRescuer => siteRescuer.id),
          bySiteId: objectFromArrayWithDup(sortedSiteRescuers, siteRescuer => siteRescuer.site_id),
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.UPDATE_SITE_RESCUER_NOTES:
      return {
        ...state,
        rescuers: {
          byId: {
            ...state.rescuers.byId,
            [action.rescuerId]: {
              ...state.rescuers.byId[action.rescuerId],
              rescuer_notes: action.notes,
            },
          },
          bySiteId: {
            ...state.rescuers.bySiteId,
            [action.siteId]: updateSiteRescuerNotes(state.rescuers.bySiteId[action.siteId], {
              rescuerId: action.rescuerId,
              notes: action.notes,
            }),
          },
        },
      };
    case sitesActions.REQUEST_SITE_DONATIONS:
      return {
        ...state,
        donations: {
          ...state.donations,
          inflight: true,
        },
      };
    case sitesActions.RECEIVE_SITE_DONATIONS:
      return {
        ...state,
        donations: {
          inflight: false,
          byId: objectFromArray(action.siteDonations, siteDonation => siteDonation.id),
          allIds: action.siteDonations.map(siteDonation => siteDonation.id),
          bySiteId: objectFromArrayWithDup(action.siteDonations, siteDonation => siteDonation.site_id),
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.REQUEST_SITE_PICKUP_SPECS:
      return {
        ...state,
        pickupSpecs: {
          ...state.pickupSpecs,
          inflight: true,
        }
      }
    case sitesActions.RECEIVE_SITE_PICKUP_SPECS:
      return {
        ...state,
        pickupSpecs: {
          ...state.pickupSpecs,
          lastUpdated: action.receivedAt,
          inflight: false,
          bySiteId: {
            ...state.pickupSpecs.bySiteId,
            [action.siteId]: action.sitePickupSpecs,
          }
        }
      }
    case sitesActions.REQUEST_SITE_PICKUP_LOCATIONS:
      return {
        ...state,
        pickupLocations: {
          ...state.pickupLocations,
          inflight: true,
        },
      };
    case sitesActions.RECEIVE_SITE_PICKUP_LOCATIONS:
      const sortedSitePickupLocations = sortArrayOfObjects(action.sitePickupLocations, row => row.name || row.address)
      return {
        ...state,
        pickupLocations: {
          byId: objectFromArray(action.sitePickupLocations, spl => spl.id),
          allIds: action.sitePickupLocations.map(spl => spl.id),
          byFoodDonorId: objectFromArrayWithDup(sortedSitePickupLocations, spl => spl.donor_location_id),
          inflight: false,
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.REQUEST_SITE_EVENTS:
      return {
        ...state,
        events: {
          ...state.events,
          inflight: true,
        },
      };
    case sitesActions.REQUEST_SITE_EVENTS_LIST:
      return {
        ...state,
        eventsList: {
          ...state.eventsList,
          inflight: true,
        },
      };
    case sitesActions.REQUEST_SITE_EVENT:
      const requestSiteEvents = state.events.byId;
      delete requestSiteEvents[action.eventId]
      return {
        ...state,
        events: {
          ...state.events,
          byId: requestSiteEvents,
          allIds: Object.values(requestSiteEvents).map(e => e.id),
          bySiteId: objectFromArrayWithDup(Object.values(requestSiteEvents), e => e.site_id),
        },
      };
    case sitesActions.RECEIVE_SITE_EVENT:
      const rEvents = {
        ...state.events.byId,
        [action.event.id]: action.event,
      };
      return {
        ...state,
        events: {
          ...state.events,
          byId: rEvents,
          allIds: [...new Set([...state.events.allIds, action.event.id])],
          bySiteId: objectFromArrayWithDup(Object.values(rEvents), e => e.site_id),
          inflight: false,
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.RECEIVE_SITE_EVENTS:
      return {
        ...state,
        events: {
          byId: objectFromArray(action.events, spl => spl.id),
          allIds: action.events.map(spl => spl.id),
          bySiteId: objectFromArrayWithDup(action.events, e => e.site_id),
          inflight: false,
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.RECEIVE_SITE_EVENTS_LIST:
      return {
        ...state,
        eventsList: {
          byId: objectFromArray(action.events, spl => spl.id),
          allIds: action.events.map(spl => spl.id),
          bySiteId: objectFromArrayWithDup(action.events, e => e.site_id),
          inflight: false,
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.CANCEL_SITE_EVENT:
      return {
        ...state,
        events: {
          ...cancelSiteEvent(action.event, state.events.byId),
          allIds: state.events.allIds,
          inflight: false,
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.SET_SITE_EVENT_REGISTRATION:
      return {
        ...state,
        eventsList: {
          ...state.eventsList,
          bySiteId: {
            ...state.eventsList.bySiteId,
            [action.siteId]: addSiteEventRegistration(state.eventsList.bySiteId[action.siteId], {
              eventId: action.eventId,
              eventShiftId: action.eventShiftId,
              eventShift: action.eventShift,
            }),
          },
        },
      };
    case sitesActions.SET_SITE_EVENT_ADOPTION:
      return {
        ...state,
        events: {
          ...state.events,
          bySiteId: {
            ...state.events.bySiteId,
            [action.siteId]: addSiteEventRegistration(state.events.bySiteId[action.siteId], {
              eventId: action.eventId,
              eventShiftId: action.eventShiftId,
              eventShift: action.eventShift,
            }),
          },
        },
      };
    case sitesActions.DELETE_SITE_EVENT_REGISTRATION:
      return {
        ...state,
        eventsList: {
          ...state.eventsList,
          bySiteId: {
            ...state.eventsList.bySiteId,
            [action.siteId]: deleteSiteEventRegistration(state.eventsList.bySiteId[action.siteId], {
              eventId: action.eventId,
              eventShiftId: action.eventShiftId,
              eventShiftRegistrationId: action.eventShiftRegistrationId,
            }),
          },
        },
      };
    case sitesActions.DELETE_SITE_EVENT_ADOPTION:
      return {
        ...state,
        events: {
          ...state.events,
          bySiteId: {
            ...state.events.bySiteId,
            [action.siteId]: deleteSiteEventRegistration(state.events.bySiteId[action.siteId], {
              eventId: action.eventId,
              eventShiftId: action.eventShiftId,
              eventShiftRegistrationId: action.eventShiftRegistrationId,
            }),
          },
        },
      };
    case sitesActions.REQUEST_SITE_EMAILS:
      return {
        ...state,
        emails: {
          ...state.emails,
          inflight: true,
        },
      };
    case sitesActions.RECEIVE_SITE_EMAILS:
      return {
        ...state,
        emails: {
          inflight: false,
          byId: objectFromArray(action.emails, emails => emails.id),
          allIds: action.emails.map(emails => emails.id),
          bySiteId: objectFromArrayWithDup(action.emails, emails => emails.site_id),
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.REQUEST_SITE_EMAIL:
      return {
        ...state,
        emails: {
          ...state.emails,
          inflight: true,
        },
      };
    case sitesActions.RECEIVE_SITE_EMAIL:
      let emails = state.emails.allIds.map(emailId => {
        if (emailId === action.email.id) {
          return action.email;
        }

        return state.emails.byId[emailId];
      });

      if (!emails.find(email => email.id === action.email.id)) {
        emails.push(action.email);
      }

      return {
        ...state,
        emails: {
          inflight: false,
          byId: objectFromArray(emails, e => e.id),
          allIds: emails.map(e => e.id),
          bySiteId: objectFromArrayWithDup(emails, e => e.site_id),
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.REQUEST_SITE_SMS:
      return {
        ...state,
        sms: {
          ...state.sms,
          inflight: true,
        },
      };
    case sitesActions.RECEIVE_SITE_SMS:
      return {
        ...state,
        sms: {
          inflight: false,
          byId: objectFromArray(action.sms, sms => sms.id),
          allIds: action.sms.map(sms => sms.id),
          bySiteId: objectFromArrayWithDup(action.sms, sms => sms.site_id),
          lastUpdated: action.receivedAt,
        },
      };
    case sitesActions.REQUEST_SITES_PHOTOS:
      return {
        ...state,
        photos: {
          ...state.photos,
          inflight: true,
        }
      }
    case sitesActions.RECEIVE_SITES_PHOTOS:
      return {
        ...state,
        photos: {
          byId: objectFromArray(action.sitePhotos, photo => photo.id),
          allIds: action.sitePhotos.map(photo => photo.id),
          inflight: false,
          lastUpdated: action.receivedAt,
        }
      }
    case sitesActions.REQUEST_SITE_LEADERBOARDS:
      return {
        ...state,
        leaderboards: {
          ...state.leaderboards,
          inflight: true,
        }
      }
    case sitesActions.RECEIVE_SITE_LEADERBOARDS:
      return {
        ...state,
        leaderboards: {
          foodDonors: action.data.foodDonors,
          receivingAgencies: action.data.receivingAgencies,
          rescuers: action.data.rescuers,
          inflight: false,
          lastUpdated: action.receivedAt,
        }
      }
    default:
      return state;
  }
};

const updateSiteRescuerNotes = (siteRescuers, { rescuerId, notes }) => {
  const rescuerIndex = siteRescuers.findIndex((rescuer) => rescuer.id === rescuerId);
  siteRescuers[rescuerIndex].rescuer_notes = notes;

  return siteRescuers;
}

const addSiteEventRegistration = (siteEvents, { eventId, eventShiftId, eventShift }) => {
  const eventIndex = siteEvents.findIndex(event => event.id === Number(eventId));
  const shiftEvents = [...siteEvents[eventIndex].shifts];
  const shiftIndex = shiftEvents.findIndex(shift => shift.id === eventShiftId);
  shiftEvents[shiftIndex] = eventShift;
  siteEvents[eventIndex].shifts = shiftEvents;

  return siteEvents;
};

const deleteSiteEventRegistration = (siteEvents, { eventId, eventShiftId, eventShiftRegistrationId }) => {
  const siteEventIndex = siteEvents.findIndex(event => event.id === Number(eventId));
  const event = siteEvents[siteEventIndex];
  const shiftEventDeleteIndex = event.shifts.findIndex(shift => shift.id === eventShiftId);
  event.shifts[shiftEventDeleteIndex].registrations = event.shifts[shiftEventDeleteIndex].registrations.filter(
    r => r.id !== eventShiftRegistrationId
  );
  siteEvents[siteEventIndex] = event;

  return siteEvents;
};

const cancelSiteEvent = (event, byId) => {
  const events = Object.values(byId).map((e) => {
    if (e.id === event.id) return event;
    return e;
  });
  return {
    byId: { ...byId, [event.id]: event },
    bySiteId: objectFromArrayWithDup(events, (e) => e.site_id),
  };
};

const _addOrUpdateUser = (user, byId, allIds, bySiteId) => {
  const rescuers = (bySiteId[user.site_id] || []).filter(u => u.id !== user.id);
  rescuers.push(user);

  return {
    byId: {
      ...byId,
      [user.id]: user,
    },
    allIds: [...new Set([...allIds, user.id])],
    bySiteId: objectFromArrayWithDup(rescuers, siteRescuer => siteRescuer.site_id),
  };
};

const _deleteUser = (user, byId, allIds, bySiteId) => {
  const rescuers = (bySiteId[user.site_id] || []).filter(u => u.id !== user.id);

  return {
    byId: omit(byId, user.id),
    allIds: allIds.filter(num => num !== user.id),
    bySiteId: objectFromArrayWithDup(rescuers, siteRescuer => siteRescuer.site_id),
  };
};

const _deleteFoodDonor = (foodDonor, byId, allIds, bySiteId) => {
  const foodDonors = (bySiteId[foodDonor.site_id] || []).filter(fd => fd.id !== foodDonor.id);

  return {
    byId: omit(byId, foodDonor.id),
    allIds: allIds.filter(num => num !== foodDonor.id),
    bySiteId: objectFromArrayWithDup(foodDonors, siteFoodDonor => siteFoodDonor.site_id),
  };
};

const _deleteReceiver = (receiver, byId, allIds, bySiteId) => {
  const receivers = (bySiteId[receiver.site_id] || []).filter(r => r.id !== receiver.id);

  return {
    byId: omit(byId, receiver.id),
    allIds: allIds.filter(num => num !== receiver.id),
    bySiteId: objectFromArrayWithDup(receivers, siteReceiver => siteReceiver.site_id),
  };
};

export default sitesReducer;
