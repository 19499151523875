import React, { useEffect, useState } from 'react';
import { generatePath, Link, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, getContrastRatio, makeStyles, Typography } from '@material-ui/core';
import { CheckBox as CheckBoxIcon } from '@material-ui/icons';
import { Colors } from '../../../assets/theme/Colors';
import courses from '../../../helpers/courses';
import routes from '../../../routes';
import VideoModule from '../Components/VideoModule';
import NeedToKnowModule from '../Components/NeedToKnowModule';
import QuizModule from '../Components/QuizModule';
import useActiveUser from '../../../hooks/useActiveUser';
import { setCoursesState } from '../../../actions/ui';

const useStyles = makeStyles((theme) => ({
  navItem: {
    display: 'flex',
    alignItems: 'center',
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
  },
  link: {
    textDecoration: 'none',
    color: Colors.gray,
    paddingLeft: 10,
    marginRight: theme.spacing(1),
    '&.active': {
      color: theme.palette.primary.main,
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        left: -3,
        top: 0,
        height: '100%',
        borderLeft: '4px solid',
        borderColor: theme.palette.primary.main,
      },
    },
  },
  container: {
    width: 'calc(100% - 170px)',
  },
  subLink: {
    textDecoration: 'none',
    color: Colors.gray,
    marginLeft: 10,
    padding: '3px 8px',
    position: 'relative',
    '&.active': {
      color: theme.palette.primary.dark,
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main,
        filter:
          getContrastRatio(theme.palette.primary.main, '#fff') > 4.5
            ? 'brightness(4) saturate(0.1)'
            : 'brightness(1.5) saturate(0.7)',
        zIndex: -1,
      },
    },
  },
  submenu: {
    overflow: 'hidden',
    maxHeight: 0,
    opacity: 0,
    transition: 'all 0.5s ease-in-out',
    '&.show': {
      maxHeight: '200px',
      opacity: 1,
      transition: 'max-height 1s ease-in-out, opacity 0.7s ease-in-out',
    },
  },
  checkbox: {
    fontSize: '18px',
    color: Colors.green.light,
  },
}));

const LearningCenterDesktopContainer = () => {
  const classes = useStyles();
  const activeUser = useActiveUser();
  const dispatch = useDispatch();
  const [currentCourse, setCurrentCourse] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [activeSubTab, setActiveSubTab] = useState(0);
  const coursesState = useSelector((state) => state.ui.courses);
  const currentUserCoursesState = coursesState[activeUser.id];
  const videoTab = 'video';
  const needToKnowTab = 'need-to-know';
  const quizTab = 'quiz';

  useEffect(() => {
    if (currentUserCoursesState) {
      return setActiveTab(currentUserCoursesState?.activeCourse);
    }

    return courses.length > 0 ? setActiveTab(courses[0]?.id) : null;
  }, [currentUserCoursesState]);

  const setCurrentCoursesState = (data) =>
    dispatch(
      setCoursesState({
        userId: activeUser.id,
        courseId: data.courseId,
        data,
      })
    );

  const paths = [
    generatePath(routes.learn),
    generatePath(routes.learn, { tab: videoTab }),
    generatePath(routes.learn, { tab: needToKnowTab }),
    generatePath(routes.learn, { tab: quizTab }),
  ];

  return (
    <>
      <Typography variant="h5">Learn About Food Rescue</Typography>
      <Box mt={5} display="flex" gridGap="16px">
        <Box minWidth="max-content">
          {courses.map((course) => {
            const courseState = currentUserCoursesState?.[course?.id];
            const modulesCount = (course?.modules || []).length;
            const allModulesFinished =
              modulesCount > 0 && modulesCount === Object.values(courseState?.modules || []).length;
            return (
              <Box key={course.id}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  className={classes.navItem}
                  onClick={() => {
                    setActiveSubTab('');
                    setActiveTab(course.id);
                  }}
                >
                  <Link
                    className={course.id !== activeTab ? classes.link : `${classes.link} active`}
                    to={generatePath(routes.learn)}
                  >
                    {course.title}
                  </Link>

                  {allModulesFinished && <CheckBoxIcon className={classes.checkbox} />}
                </Typography>

                <Box className={activeTab === course.id ? `${classes.submenu} show` : classes.submenu}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    className={classes.linkContainer}
                    onClick={() => {
                      setCurrentCourse(course);
                      setActiveSubTab(videoTab);
                    }}
                  >
                    <Link
                      className={activeSubTab !== videoTab ? classes.subLink : `${classes.subLink} active`}
                      to={generatePath(routes.learn, { tab: videoTab, id: course.id })}
                    >
                      Video - {course.videoDuration} min
                    </Link>

                    {currentUserCoursesState && currentUserCoursesState[course.id]?.modules?.videoModule && (
                      <CheckBoxIcon className={classes.checkbox} />
                    )}
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    className={classes.linkContainer}
                    onClick={() => {
                      setCurrentCourse(course);
                      setActiveSubTab(needToKnowTab);
                    }}
                  >
                    <Link
                      className={activeSubTab !== needToKnowTab ? classes.subLink : `${classes.subLink} active`}
                      to={generatePath(routes.learn, { tab: needToKnowTab, id: course.id })}
                    >
                      Need To Know - {course.needToKnowDuration} min
                    </Link>

                    {currentUserCoursesState && currentUserCoursesState[course.id]?.modules?.needToKnowModule && (
                      <CheckBoxIcon className={classes.checkbox} />
                    )}
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    className={classes.linkContainer}
                    onClick={() => {
                      setCurrentCourse(course);
                      setActiveSubTab(quizTab);
                    }}
                  >
                    <Link
                      className={activeSubTab !== quizTab ? classes.subLink : `${classes.subLink} active`}
                      to={generatePath(routes.learn, { tab: quizTab, id: course.id })}
                    >
                      Quiz - {course.quizDuration} min
                    </Link>

                    {currentUserCoursesState && currentUserCoursesState[course.id]?.modules?.quizModule && (
                      <CheckBoxIcon className={classes.checkbox} />
                    )}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box className={classes.container}>
          <Switch>
            <Route path={paths[1]}>
              <VideoModule course={currentCourse} setCurrentCoursesState={setCurrentCoursesState} />
            </Route>
            <Route path={paths[2]}>
              <NeedToKnowModule course={currentCourse} setCurrentCoursesState={setCurrentCoursesState} />
            </Route>
            <Route path={paths[3]}>
              <QuizModule course={currentCourse} user={activeUser} setCurrentCoursesState={setCurrentCoursesState} />
            </Route>
          </Switch>
        </Box>
      </Box>
    </>
  );
};

export default LearningCenterDesktopContainer;
