import React, { useState } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';
import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  FormControlLabel,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../../rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';
import routes from '../../../routes';
import { Colors } from '../../../assets/theme/Colors';

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: palette.background.default,
    padding: spacing(1),
  },
  paper: {
    padding: spacing(2),
    marginTop: spacing(1),
  },
  label: {
    paddingRight: spacing(2),
    marginLeft: 0,
  },
  error: {
    color: Colors.errorText,
  },
}));

const LearningCenterMobilQuizBreadcrumbs = () => (
  <Box boxShadow={0}>
    <Breadcrumbs aria-label="breadcrumb">
      <StyledRescuerBreadcrumb
        component={Link}
        to={generatePath(routes.mobileDashboard)}
        label="Home"
        icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
      />
      <StyledRescuerTextBreadcrumb component={Link} to={generatePath(routes.mobileDashboardLearn)} label="Learn" />
      <StyledRescuerTextBreadcrumb component="a" href="#" label="Quiz" />
    </Breadcrumbs>
  </Box>
);

const QuizModule = ({ course, setCurrentCoursesState, isMobile = false }) => {
  const classes = useStyles();
  const history = useHistory();
  const [selectedValues, setSelectedValues] = useState({});
  const { id, quiz, title } = course;

  const handleChange = (questionId, event) => {
    setSelectedValues((prev) => ({
      ...prev,
      [questionId]: event?.target?.value,
    }));
  };
  const handleDone = () => {
    setCurrentCoursesState({ courseId: id, modules: { quizModule: true } });

    if (isMobile) {
      return history.push(generatePath(routes.mobileDashboardLearn));
    }
    return history.push(generatePath(routes.learn));
  };

  const renderRadioStyles = (isSelected) => {
    if (isSelected) {
      return {
        color: 'white',
      };
    }

    return null; // Style only changes when selected
  };

  const renderLabelStyles = (isSelected, isCorrect) => {
    if (isSelected) {
      if (isCorrect) {
        return {
          backgroundColor: Colors.green.light,
          borderRadius: 5,
          color: 'white',
        };
      }

      return {
        backgroundColor: Colors.red,
        borderRadius: 5,
        color: 'white',
        fontWeight: '700',
      };
    }

    return null;
  };

  // Check if all selected answers are correct
  const allCorrect = (quiz || []).every((q) => {
    const correctAnswer = q.answers.find((answer) => answer.correct);
    // Check if selected answer matches correct one
    return selectedValues[q.id] === correctAnswer.value;
  });

  if (!id) {
    if (isMobile) {
      return history.push(generatePath(routes.mobileDashboardLearn));
    }
    return history.push(generatePath(routes.learn));
  }

  return (
    <>
      {isMobile && <LearningCenterMobilQuizBreadcrumbs />}

      <Box className={classes.container}>
        <Typography variant="h6">{title} Quiz</Typography>

        <Paper className={classes.paper}>
          <Box>
            <Typography variant="body1">
              Select the correct answer from the options provided. This quiz is just a learning aid, you can attempt
              this quiz as many times as you want!
            </Typography>
          </Box>

          <Box my={2}>
            {(quiz || []).map((q) => {
              const selectedAnswerValue = selectedValues[q.id];
              const selectedAnswer = q.answers.find((answer) => answer.value === selectedAnswerValue);

              return (
                <FormControl key={q.id}>
                  <Typography style={{ fontWeight: 500 }} variant="body1">
                    {q.question}
                  </Typography>

                  {selectedAnswer && !selectedAnswer?.correct && (
                    <Typography variant="body2" className={classes.error}>
                      Uh oh! This is incorrect, please try a different answer.
                    </Typography>
                  )}

                  <RadioGroup row value={selectedValues[q.id] || ''} onChange={(event) => handleChange(q.id, event)}>
                    {(q?.answers || []).map((answer) => {
                      const isCorrect = answer.correct;
                      const isSelected = answer.value === selectedAnswerValue;

                      return (
                        <FormControlLabel
                          key={answer.value}
                          value={answer.value}
                          control={<Radio style={renderRadioStyles(isSelected)} />}
                          label={answer.label}
                          className={classes.label}
                          style={renderLabelStyles(isSelected, isCorrect)}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              );
            })}
          </Box>
        </Paper>

        {allCorrect && (
          <Box py={2} display="flex" justifyContent="end">
            <Button variant="contained" color="primary" onClick={handleDone}>
              Done
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default QuizModule;
