import queryString from 'query-string';
import { apiGET, apiPOST } from './api';

export const getRescuers = () => apiGET('/users');

export const createRescuer = body => apiPOST('/rescuers', body);

const getRescuerRescues = (rescuerId, rescueId, params) => {
  const query = queryString.stringify(params);

  return apiGET(`/rescuers/${rescuerId}/rescues${rescueId ? `/${rescueId}` : ''}${query ? `?${query}` : ''}`);
};

const getRescuerRescuesV2 = (rescuerId, rescueId, params) => {
  const query = queryString.stringify(params);

  return apiGET(`/v2/rescuers/${rescuerId}/rescues${rescueId ? `/${rescueId}` : ''}${query ? `?${query}` : ''}`);
};

export const getAvailableRescues = (rescuerId, rescueId, from, to, nextRescuesDates, sortBy) => {
  const params = {
    available: true,
    sort_by: sortBy || [],
  };

  if (from) {
    params.from = from;
  }

  if (to) {
    params.to = to;
  }

  if (nextRescuesDates) {
    params.next_rescues_dates = nextRescuesDates;
  }

  return getRescuerRescues(rescuerId, rescueId, params);
};

export const getClaimedAndAdoptedRescues = (rescuerId, from, to, queryParams) => {
  return getRescuerRescues(rescuerId, null, { from, to, ...queryParams });
};

export const getClaimedAndAdoptedRescuesV2 = (rescuerId, from, to, queryParams) => {
  return getRescuerRescuesV2(rescuerId, null, { from, to, ...queryParams });
};

export const getPastRescues = rescuerId => {
  const query = queryString.stringify({
    sort_by: ['date,desc', 'pickup_begin,asc']
  });

  return apiGET(`/rescuers/${rescuerId}/past_rescues?${query}`);
};

export const getRescuerEvents = rescuerId => apiGET(`/rescuers/${rescuerId}/events`);

export const getRescuerEnvironmentImpact = (rescuerId, from, to) => {
  const query = queryString.stringify(
    {
      from,
      to,
    },
    {
      skipNull: true,
    }
  );

  return apiGET(`/rescuers/${rescuerId}/environment_impact?${query}`);
};
