import React from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { CheckBox as CheckBoxIcon } from '@material-ui/icons';
import { Colors } from '../../../assets/theme/Colors';

const useStyles = makeStyles(() => ({
  checkbox: {
    color: Colors.green.light,
  },
  button: {
    textTransform: 'none',
    justifyContent: 'space-between',
  },
}));

const CourseModuleItem = ({ onClick, icon, title, duration, done = false }) => {
  const classes = useStyles();

  return (
    <Box py={1} onClick={onClick} width="100%" display="flex" alignItems="center" justifyContent="space-between">
      <Button variant="outlined" fullWidth className={classes.button}>
        <Box display="flex" alignItems="center">
          {icon}
          <Typography style={{ marginLeft: '1rem' }} variant="subtitle2">
            {title} - {duration} min
          </Typography>
        </Box>
        {!!done && <CheckBoxIcon className={classes.checkbox} />}
      </Button>
    </Box>
  );
};

export default CourseModuleItem;
