
export const SET_ORIGINAL_RESCUE = 'SET_ORIGINAL_RESCUE';
export const UPDATE_DRAFT_RESCUE = 'UPDATE_DRAFT_RESCUE';
export const UPDATE_TOUR_STEPPER = 'UPDATE_TOUR_STEPPER';
export const DISABLE_TOUR_STEPPER = 'DISABLE_TOUR_STEPPER';
export const SHOW_A2HS = 'SHOW_A2HS';
export const DISMISS_A2HS = 'DISMISS_A2HS';
export const SET_UI_FILTER = 'SET_UI_FILTER';
export const SET_UI_DISABLED = 'SET_UI_DISABLED';
export const SET_RESCUE_CLOSE_STEP = 'SET_RESCUE_CLOSE_STEP';
export const SET_QUICK_RESPONSE = 'SET_QUICK_RESPONSE';
export const SET_CURRENT_TABLES_STATE = 'SET_CURRENT_TABLES_STATE';
export const RESET_CURRENT_TABLES_STATE = 'RESET_CURRENT_TABLES_STATE';
export const SET_COURSES = 'SET_COURSES';

export const setUIFilter = (userId, filterId, filterData) => {
  return {
    type: SET_UI_FILTER,
    payload: {
      userId,
      filterId,
      filterData,
    },
  };
};

export const setUIDisabled = ({ status }) => ({
  type: SET_UI_DISABLED,
  status: status,
});

export const showA2HSTutorial = () => ({
  type: SHOW_A2HS,
});

export const dismissA2HSTutorial = () => ({
  type: DISMISS_A2HS,
});

export const setOriginalRescue = r => (dispatch, getState) => {
  return dispatch({
    type: SET_ORIGINAL_RESCUE,
    rescue: r,
  });
};

export const updateDraftRescue = r => (dispatch, getState) => {
  return dispatch({
    type: UPDATE_DRAFT_RESCUE,
    rescue: r,
  });
};

export const setTourStepper = (enabled, payload, initialStep = 0) => {
  return {
    type: UPDATE_TOUR_STEPPER,
    payload: {
      enabled,
      initialStep,
      steps: payload.steps,
      onExit: payload.onExit,
    },
  };
};

export const setRescueCloseStep = (step = 0) => {
  return {
    type: SET_RESCUE_CLOSE_STEP,
    step,
  }
}

export const disableTourStepper = () => {
  return {
    type: DISABLE_TOUR_STEPPER,
  };
};

export const setQuickResponse = (value) => ({
  type: SET_QUICK_RESPONSE,
  value,
});

export const setCurrentTablesState = (payload) => ({
  type: SET_CURRENT_TABLES_STATE,
  payload,
});

export const resetCurrentTablesState = () => ({
  type: RESET_CURRENT_TABLES_STATE,
});

export const setCoursesState = (payload) => ({
  type: SET_COURSES,
  payload,
});
