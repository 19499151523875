import React, { useEffect, useState } from 'react';
import { Box, FormControl, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import DashboardGrowthCards from '../../../components/Dashboard/DashboardGrowthCards';
import { fetchSiteMetrics } from '../../../actions/sites';
import moment from 'moment';
import { getPreviousDateRangePeriod, isMetricSupported } from '../../../helpers/metrics';
import DashboardEngagementCards from '../../../components/Dashboard/DashboardEngagementCards';
import DateAndGranularityPicker, { dateRangeOptionsList } from '../../../components/DateAndGranularityPicker/DateAndGranularityPicker';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
  },
}));

const SCDashboardAtGlance = ({ siteId }) => {
  const classes = useStyles();
  const metrics = useSelector(state => state.entities.sites.metrics);
  const dispatch = useDispatch();

  const [selectedDateRange, setSelectedDateRange] = useState();
  const [dateRangePeriod, setDateRangePeriod] = useState(dateRangeOptionsList.thisMonth);

  const isLoading = metrics.inflight;


  useEffect(() => {
    const prevDateRange = getPreviousDateRangePeriod(dateRangePeriod, selectedDateRange);

    if (prevDateRange && selectedDateRange) {
      dispatch(fetchSiteMetrics(siteId, prevDateRange, selectedDateRange));
    }
  }, [selectedDateRange]);

  const onDateAndGranularityPickerChange = ({ dateRange, period }) => {
    setSelectedDateRange(
      !dateRange
        ? null
        : {
          startDate: moment(dateRange.startDate).format('YYYYMMDD'),
          endDate: moment(dateRange.endDate).format('YYYYMMDD'),
        }
    );
    setDateRangePeriod(period);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box mb={2}>
            <Typography variant="h6" color="textSecondary">
              At A Glance
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Paper className={classes.paper}>
            <FormControl component="fieldset">
              <DateAndGranularityPicker
                customDateRangeOptions={{
                  maxDate: new Date(),
                }}
                showCustomDateRange
                showGranularity={false}
                isDisabled={isLoading}
                onChange={onDateAndGranularityPickerChange}
              />
            </FormControl>
          </Paper>
        </Grid>
      </Grid>

      <DashboardGrowthCards
        isUnsupported={!isMetricSupported(siteId, dateRangePeriod)}
        hideCurrentValue={dateRangePeriod === 'all'}
        inflight={metrics.bySiteId[siteId] ? metrics.bySiteId[siteId].inflight : true}
        growth={metrics.bySiteId[siteId] ? metrics.bySiteId[siteId].growth : null}
      />

      <DashboardEngagementCards
        isUnsupported={!isMetricSupported(siteId, dateRangePeriod)}
        hideCurrentValue={dateRangePeriod === 'all'}
        inflight={metrics.bySiteId[siteId] ? metrics.bySiteId[siteId].inflight : true}
        engagement={metrics.bySiteId[siteId] ? metrics.bySiteId[siteId].engagement : null}
      />
    </>
  );
};

export default SCDashboardAtGlance;
