import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Breadcrumbs, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import { Home as HomeIcon } from '@material-ui/icons';
import useRescuerDashboardActions from '../../../../hooks/useRescuerDashboardActions';
import routes from '../../../../routes';
import { StyledRescuerBreadcrumb, StyledRescuerTextBreadcrumb } from '../Components/StyledRescuerBreadcrumb';
import MyEnviroImpact from '../../rescuerDashboardDesktop/myFrus/Components/MyEnviroImpact';
import DateAndGranularityPicker, {
  dateRangeOptionsList,
} from '../../../../components/DateAndGranularityPicker/DateAndGranularityPicker';
import FABContext from '../../../../context/FABContext/FABContext';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: palette.background.default,
  },
  datePicker: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: palette.background.paper,
    boxShadow: '0 -4px 6px -1px rgba(0,0,0,0.1)',
    marginLeft: '-8px',
    paddingLeft: '8px',
  },
}));
const defaultDateRangeOptions = {
  last7Days: dateRangeOptionsList.last7Days,
  last90Days: dateRangeOptionsList.last90Days,
  thisWeek: dateRangeOptionsList.thisWeek,
  thisMonth: dateRangeOptionsList.thisMonth,
  thisYear: dateRangeOptionsList.thisYear,
};

const EnviroImpactBreadcrumb = () => (
  <Box boxShadow={0}>
    <Breadcrumbs aria-label="breadcrumb">
      <StyledRescuerBreadcrumb
        component={RouterLink}
        to={routes.index}
        label="Home"
        icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
      />
      <StyledRescuerTextBreadcrumb component="a" href="#" label="My Impact" />
    </Breadcrumbs>
  </Box>
);

const EnvironmentImpactDashboardMobileContainer = () => {
  const classes = useStyles();
  const fabContext = useContext(FABContext);
  const environmentImpactEntities = useSelector((state) => state.entities.environmentImpact);
  const environmentImpactLoading = environmentImpactEntities.inflight;
  const { fetchEnviromentImpact } = useRescuerDashboardActions();

  useEffect(() => {
    // hide FAB
    fabContext.updateFABStyles({
      speedDial: {
        display: 'none',
      },
    });

    // reset FAB styles on component unmount
    return () => fabContext.updateFABStyles({});
  }, []);

  const handleDateChange = (date) => {
    if (date) {
      const start = date?.startDate ? moment(date.startDate).format('YYYY-MM-DD') : null;
      const end = date?.endDate ? moment(date.endDate).format('YYYY-MM-DD') : null;

      fetchEnviromentImpact(false, start, end);
    } else {
      fetchEnviromentImpact(false);
    }
  };

  return (
    <>
      <EnviroImpactBreadcrumb />
      <Box className={classes.container}>
        <Box display="flex" py={2} overflow="scroll">
          <MyEnviroImpact isLoading={environmentImpactLoading} environmentImpact={environmentImpactEntities.all} />
        </Box>
        <Box py={2} className={classes.datePicker}>
          <DateAndGranularityPicker
            showGranularity={false}
            customDateRangeOptions={{
              maxDate: moment().toDate(),
            }}
            defaultDateRange="all"
            dateRangeOptions={defaultDateRangeOptions}
            onChange={(state) => {
              handleDateChange(state.dateRange);
            }}
            showCustomDateRange
            showAllDateRange
            showOnlyDatesInLabel
          />
        </Box>
      </Box>
    </>
  );
};

export default EnvironmentImpactDashboardMobileContainer;
