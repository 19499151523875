import React from 'react';
import moment from 'moment';
import { makeStyles, createMuiTheme, MuiThemeProvider, Tooltip, Button } from '@material-ui/core';
import BaseMUIDataTable from './BaseMUIDataTable/BaseMUIDataTable';
import { Colors } from '../assets/theme/Colors';
import { formatDate, formatTime } from '../helpers/formatters';
import {
  showUnClaimRescueButtons,
  showReleaseRescueButton,
  getRescuePickupLocationFullName,
  donorLinkFilters,
} from '../helpers/RescuesHelper';
import { getMuiTableDataIndex } from '../helpers/getters';
import useHasActiveFeatureFlag from '../hooks/useFeatureFlags';
import { FF_DONOR_LINK } from './FeatureFlagWrapper';
import DonorLinkPickupIndicator from './DonorLinkPickupIndicator';
import { sortByPickupReady } from '../helpers/sorters';

const useStyles = makeStyles(() => ({
  dateCell: {
    width: 125,
  },
  dayCell: {
    width: 125,
  },
  pickupBeginCell: {
    width: 125,
  },
  pickupEndCell: {
    width: 125,
  },
  customActionsButtons: {
    marginRight: 6,
    fontSize: 11,
    padding: 6,
  },
  cancelledRescueRow: {
    '& > td': {
      opacity: 0.25,
    },
  },
  cancelledRescueDateCell: {
    opacity: '1 !important',
    color: '#c1c1c1',
  },
}));

const RescuerUpcomingRescuesTable = ({
  tableId,
  rescues,
  user,
  isLoading,
  onUnclaimRescueButtonClick,
  onReleaseRescueButtonClick,
  onCellClick,
}) => {
  const classes = useStyles();
  const hasActiveDonorLinkFF = useHasActiveFeatureFlag(FF_DONOR_LINK);

  const pickupReadyCustomBodyRender = (value, tableMeta, csv = false) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];

    if (!rescue) {
      return null;
    }

    if (!rescue?.location_donor_link_active) {
      return '-';
    }

    if (csv) {
      return value ? 'Pickup Ready' : 'No food yet';
    }

    return <DonorLinkPickupIndicator pickupReady={!!value} />;
  };

  return (
    <MuiThemeProvider theme={theme => createMuiTheme({
      ...theme,
      overrides: {
        ...theme.overrides,
        MUIDataTableHeadCell: {
          root: {
            ...theme.overrides.MUIDataTableHeadCell.root,
            '&:last-child': {
              width: 255,
            },
          },
        },
      },
    })}>
      <BaseMUIDataTable
        tableId={tableId}
        isLoading={isLoading}
        data={rescues}
        columns={[
          {
            name: 'id',
            label: 'ID',
            options: {
              filter: false,
              sort: false,
              display: false,
            },
          },
          {
            name: 'date',
            label: 'Date',
            options: {
              setCellProps: () => ({ className: classes.dateCell }),
              filterOptions: {
                renderValue: formatDate,
              },
              customBodyRenderCSV: formatDate,
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                return (
                  <>
                    <span>
                      {moment(value, 'YYYYMMDD').format('MM/DD/YYYY')}
                    </span>

                    {rescue && rescue.cancelled_by_id && (
                      <>
                        <br />

                        <span style={{ color: Colors.errorText, fontWeight: 'bold' }}>
                          Cancelled
                        </span>
                      </>
                    )}
                  </>
                );
              },
            },
          },
          {
            name: 'day',
            label: 'Day',
            options: {
              setCellProps: () => ({ className: classes.dayCell }),
              customBodyRenderCSV: (value, tableMeta) =>
                moment(rescues[getMuiTableDataIndex(tableMeta)].date, 'YYYYMMDD').format('dddd'),
              customBodyRender: (value, tableMeta) =>
                moment(rescues[getMuiTableDataIndex(tableMeta)].date, 'YYYYMMDD').format('dddd'),
            },
          },
          {
            name: 'pickup_begin',
            label: 'Pickup Begin',
            options: {
              filter: false,
              setCellProps: () => ({ className: classes.pickupBeginCell }),
              customBodyRenderCSV: formatTime,
              customBodyRender: value => formatTime(value),
            },
          },
          {
            name: 'pickup_end',
            label: 'Pickup End',
            options: {
              filter: false,
              setCellProps: () => ({ className: classes.pickupEndCell }),
              customBodyRenderCSV: formatTime,
              customBodyRender: value => formatTime(value),
            },
          },
          {
            name: 'location',
            label: 'Food Donor',
            options: {
              customFilterListOptions: {
                render: value => `Food Donor: ${value}`,
              },
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                if (!rescue) {
                  return null;
                }

                return getRescuePickupLocationFullName(rescue);
              },
            },
          },
          {
            name: 'receiver',
            label: 'Receiving Agency',
            options: {
              customBodyRender: value => value || '',
            },
          },
          ...(hasActiveDonorLinkFF
            ? [
                {
                  name: 'rescue_confirmed',
                  label: 'Donor Link',
                  options: {
                    display: true,
                    filter: true,
                    customBodyRender: (value, tableMeta) => pickupReadyCustomBodyRender(value, tableMeta),
                    customBodyRenderCSV: (value, tableMeta) => pickupReadyCustomBodyRender(value, tableMeta, true),
                    filterOptions: {
                      names: [
                        donorLinkFilters.dataReceived,
                        donorLinkFilters.dataNotReceived,
                        donorLinkFilters.donorLinkInactive,
                        donorLinkFilters.donorLinkActive,
                      ],
                      logic: (option, filters, row) => {
                        if (!filters || filters.length === 0) {
                          return false;
                        }

                        const rescueId = row[0];
                        const rescue = rescues.find((r) => r.id === rescueId);

                        switch (filters[0]) {
                          case donorLinkFilters.dataReceived:
                            return !rescue.rescue_confirmed;
                          case donorLinkFilters.dataNotReceived:
                            return !!option;
                          case donorLinkFilters.donorLinkInactive:
                            return rescue.location_donor_link_active;
                          case donorLinkFilters.donorLinkActive:
                            return !rescue.location_donor_link_active;
                          default:
                            return true;
                        }
                      },
                    },
                    customFilterListOptions: {
                      render: (value) => `Donor Link: ${value}`,
                    },
                    sortCompare: (order) => (row1, row2) => {
                      const rescue1 = rescues.find((fd) => fd.id === row1.rowData[0]);
                      const rescue2 = rescues.find((fd) => fd.id === row2.rowData[0]);

                      return sortByPickupReady(order, rescue1, rescue2);
                    },
                  },
                },
              ]
            : []),
          {
            name: 'slug',
            label: 'Description',
            options: {
              filter: false,
            },
          },
          {
            name: 'notes',
            label: 'Pickup Notes',
            options: {
              filter: false,
            },
          },
          {
            name: 'actions',
            label: 'Actions',
            options: {
              download: false,
              Empty: true,
              filter: false,
              searchable: false,
              viewColumns: false,
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                if (!rescue) {
                  return false;
                }

                return (
                  <>
                    {showUnClaimRescueButtons(rescue, user) && (
                      <Tooltip
                        placement="top"
                        key="Sorry, I can't make it"
                        title="Unclaim rescue"
                        className={classes.customActionsButtons}
                      >
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => onUnclaimRescueButtonClick(rescue)}
                        >
                          Sorry, I can't make it
                        </Button>
                      </Tooltip>
                    )}

                    {showReleaseRescueButton(rescue, user) && (
                      <Tooltip
                        placement="top"
                        key="Sorry, I can't make it"
                        title="Release rescue"
                        className={classes.customActionsButtons}
                      >
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => onReleaseRescueButtonClick(rescue)}
                        >
                          Sorry, I can't make it
                        </Button>
                      </Tooltip>
                    )}
                  </>
                );
              },
            },
          },
        ]}
        options={{
          elevation: 0,
          rowsPerPage: 15,
          selectableRows: 'none',
          responsive: 'simple',
          onCellClick: (colData, cellMeta) => {
            // ignore click on "actions" column cell
            if (cellMeta.colIndex >= 9) {
              return false;
            }

            const rescue = rescues[getMuiTableDataIndex(cellMeta)];

            if (!rescue || typeof onCellClick !== 'function') {
              return false;
            }

            return onCellClick(rescue);
          },
          setRowProps: (row, dataIndex) => {
            const rescue = rescues[dataIndex];

            if (!rescue || !rescue.cancelled_by_id) {
              return false;
            }

            return {
              className: classes.cancelledRescueRow,
            };
          },
        }}
      />
    </MuiThemeProvider>
  );
};

export default RescuerUpcomingRescuesTable;
