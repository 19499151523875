import React from 'react';
import moment from 'moment';
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  Tooltip,
  Button,
  FormLabel,
  FormGroup,
  Select,
  MenuItem,
} from '@material-ui/core';
import BaseMUIDataTable from '../../../../../components/BaseMUIDataTable/BaseMUIDataTable';
import { Colors } from '../../../../../assets/theme/Colors';
import { formatDate, formatTime } from '../../../../../helpers/formatters';
import {
  getRescueClaimer,
  getRescuePickupLocationFullName,
  getRescuePosition, isRescueClaimed,
  showAdoptRescueButton,
  showClaimRescueButton,
} from '../../../../../helpers/RescuesHelper';
import { getMuiTableDataIndex } from '../../../../../helpers/getters';
import RescuesTableStatusBox from '../../../../../components/Common/RescuesTableStatusBox';
import useHasActiveFeatureFlag from '../../../../../hooks/useFeatureFlags';
import { FF_DONOR_LINK } from '../../../../../components/FeatureFlagWrapper';
import DonorLinkPickupIndicator from '../../../../../components/DonorLinkPickupIndicator';
import useActiveUser from '../../../../../hooks/useActiveUser';
import { sortByPickupReady } from '../../../../../helpers/sorters';

const useStyles = makeStyles(() => ({
  dateCell: {
    width: 125,
  },
  dayCell: {
    width: 125,
  },
  pickupBeginCell: {
    width: 125,
  },
  pickupEndCell: {
    width: 125,
  },
  customActionsButtons: {
    marginRight: 6,
    fontSize: 11,
    padding: 6,
  },
  cancelledRescueRow: {
    '& > td': {
      opacity: 0.25,
    },
  },
  claimedRescueRow: {
    '& > td:not(:last-child)': {
      opacity: 0.3,
      '-webkit-touch-callout': 'none' /* iOS Safari */,
      '-webkit-user-select': 'none' /* Safari */,
      '-khtml-user-select': 'none' /* Konqueror HTML */,
      '-moz-user-select': 'none' /* Old versions of Firefox */,
      '-ms-user-select': 'none' /* Internet Explorer/Edge */,
      'user-select': 'none' /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */,
    },
  },
  cancelledRescueDateCell: {
    opacity: '1 !important',
    color: '#c1c1c1',
  },
}));

const RescuerDashboardScheduleRescuesTable = ({
  tableId,
  rescues,
  rescuingPaused,
  onClaimRescueButtonClick,
  onAdoptRescueButtonClick,
  onCellClick,
}) => {
  const classes = useStyles();
  const activeUser = useActiveUser();
  const hasActiveDonorLinkFF = useHasActiveFeatureFlag(FF_DONOR_LINK);

  const pickupReadyCustomBodyRender = (value, tableMeta) => {
    const rescue = rescues[getMuiTableDataIndex(tableMeta)];

    if (!rescue) {
      return null;
    }

    if (!rescue?.location_donor_link_active) {
      return '-';
    }

    return <DonorLinkPickupIndicator pickupReady={!!value} />;
  };

  return (
    <MuiThemeProvider theme={theme => createMuiTheme({
      ...theme,
      overrides: {
        ...theme.overrides,
        MUIDataTableHeadCell: {
          root: {
            ...theme.overrides.MUIDataTableHeadCell.root,
            '&:last-child': {
              width: 175,
            },
          },
        },
      },
    })}>
      <BaseMUIDataTable
        tableId={tableId}
        data={rescues}
        columns={[
          {
            name: 'id',
            label: 'ID',
            options: {
              filter: false,
              sort: false,
              display: false,
            },
          },
          {
            name: 'date',
            label: 'Date',
            options: {
              filterOptions: {
                renderValue: formatDate,
              },
              customFilterListOptions: {
                render: value => `Date: ${formatDate(value)}`,
              },
              setCellProps: () => ({ className: classes.dateCell }),
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                return (
                  <>
                    <span>
                      {moment(value, 'YYYYMMDD').format('MM/DD/YYYY')}
                    </span>

                    {rescue && rescue.cancelled_by_id && (
                      <>
                        <br />

                        <span style={{ color: Colors.errorText, fontWeight: 'bold' }}>
                          Cancelled
                        </span>
                      </>
                    )}
                  </>
                );
              },
            },
          },
          {
            name: 'day',
            label: 'Day',
            options: {
              filterType: 'custom',
              filterOptions: {
                logic: (dayOfWeek, filters) => {
                  if (!filters || filters.length === 0) {
                    return false;
                  }

                  return !filters.includes(dayOfWeek);
                },
                display: (filterList, onChange, index, column) => (
                  <>
                    <FormLabel>Day</FormLabel>
                    <FormGroup>
                      <Select
                        fullWidth
                        value={filterList[index][0] || 'All'}
                        onChange={event => {
                          filterList[index][0] = event.target.value;
                          onChange(filterList[index][0] !== 'All' ? filterList[index] : [], index, column);
                        }}
                      >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="Sunday">Sunday</MenuItem>
                        <MenuItem value="Monday">Monday</MenuItem>
                        <MenuItem value="Tuesday">Tuesday</MenuItem>
                        <MenuItem value="Wednesday">Wednesday</MenuItem>
                        <MenuItem value="Thursday">Thursday</MenuItem>
                        <MenuItem value="Friday">Friday</MenuItem>
                        <MenuItem value="Saturday">Saturday</MenuItem>
                      </Select>
                    </FormGroup>
                  </>
                ),
              },
              customFilterListOptions: {
                render: value => `Day: ${value}`,
              },
              setCellProps: () => ({ className: classes.dayCell }),
              customBodyRender: (value, tableMeta) =>
                moment(rescues[getMuiTableDataIndex(tableMeta)].date, 'YYYYMMDD').format('dddd'),
            },
          },
          {
            name: 'pickup_begin',
            label: 'Pickup Begin',
            options: {
              filter: false,
              setCellProps: () => ({ className: classes.pickupBeginCell }),
              customBodyRender: value => formatTime(value),
            },
          },
          {
            name: 'pickup_end',
            label: 'Pickup End',
            options: {
              filter: false,
              setCellProps: () => ({ className: classes.pickupEndCell }),
              customBodyRender: value => formatTime(value),
            },
          },
          {
            name: 'location',
            label: 'Food Donor',
            options: {
              customFilterListOptions: {
                render: value => `Food Donor: ${value}`,
              },
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                if (!rescue) {
                  return null;
                }

                const claimer = getRescueClaimer(rescue);
                const rescuerIsRescueClaimer = activeUser.id === claimer?.id;

                if (isRescueClaimed(rescue) && !rescuerIsRescueClaimer) {
                  return null;
                }

                return getRescuePickupLocationFullName(rescue);
              },
            },
          },
          {
            name: 'receiver',
            label: 'Receiver',
            options: {
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                if (!rescue) {
                  return null;
                }

                const claimer = getRescueClaimer(rescue);
                const rescuerIsRescueClaimer = activeUser.id === claimer?.id;

                if (isRescueClaimed(rescue) && !rescuerIsRescueClaimer) {
                  return null;
                }

                return value || '';
              },
            },
          },
          {
            name: 'pickup_city',
            label: 'Pickup City',
            options: {
              display: false,
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                if (!rescue) {
                  return null;
                }

                return `${rescue.pickup_city}, ${rescue.pickup_st}`;
              },
            },
          },
          {
            name: 'dropoff_city',
            label: 'Dropoff City',
            options: {
              display: false,
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                if (!rescue) {
                  return null;
                }

                return `${rescue.dropoff_city}, ${rescue.dropoff_st}`;
              },
            },
          },
          ...(hasActiveDonorLinkFF
            ? [
                {
                  name: 'rescue_confirmed',
                  label: 'Donor Link',
                  options: {
                    display: true,
                    filter: true,
                    sort: true,
                    sortCompare: (order) => (row1, row2) => {
                      const rescue1 = rescues.find((fd) => fd.id === row1.rowData[0]);
                      const rescue2 = rescues.find((fd) => fd.id === row2.rowData[0]);

                      return sortByPickupReady(order, rescue1, rescue2);
                    },
                    customBodyRender: (value, tableMeta) => pickupReadyCustomBodyRender(value, tableMeta),
                  },
                },
              ]
            : []),
          {
            name: 'slug',
            label: 'Description',
            options: {
              filter: false,
            },
          },
          {
            name: 'rescue_size',
            label: 'Car Size',
            options: {
              customBodyRender: value => value || '',
            },
          },
          {
            name: 'position',
            label: 'Position',
            options: {
              filter: false,
              display: false,
              customBodyRenderLite: dataIndex => getRescuePosition(rescues[dataIndex]),
            },
          },
          {
            name: 'actions',
            label: 'Actions',
            options: {
              download: false,
              Empty: true,
              filter: false,
              searchable: false,
              viewColumns: false,
              customBodyRender: (value, tableMeta) => {
                const rescue = rescues[getMuiTableDataIndex(tableMeta)];

                if (!rescue) {
                  return false;
                }

                if (isRescueClaimed(rescue)) {
                  return <RescuesTableStatusBox rescue={rescue} />
                }

                return (
                  <>
                    {showClaimRescueButton(rescue) && (
                      <Tooltip
                        placement="top"
                        key="I'll do it"
                        title={rescuingPaused ? "Resume rescuing to claim rescue" : "Claim rescue"}
                        className={classes.customActionsButtons}
                      >
                        <Button
                          // Set pointer event for when the butons are disabled
                          style={{pointerEvents: "auto"}}
                          component={rescuingPaused ? "div" : "button"}
                          data-testid="claim-rescue"
                          disabled={rescuingPaused}
                          type="button"
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={rescuingPaused ? null : () => onClaimRescueButtonClick(rescue)}
                        >
                          I'll do it
                        </Button>
                      </Tooltip>
                    )}

                    {showAdoptRescueButton(rescue) && (
                      <Tooltip
                        placement="top"
                        key="Adopt"
                        title={rescuingPaused ? "Resume rescuing to adpot rescue" : "Adopt rescue"}
                        className={classes.customActionsButtons}
                      >
                        <Button
                          // Set pointer event for when the butons are disabled
                          style={{pointerEvents: "auto"}}
                          component={rescuingPaused ? "div" : "button"}
                          disabled={rescuingPaused}
                          type="button"
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={rescuingPaused ? null : () => onAdoptRescueButtonClick(rescue)}
                          >
                          Adopt
                        </Button>
                      </Tooltip>
                    )}
                  </>
                );
              },
            },
          },
        ]}
        options={{
          sortOrder: {
            name: 'date',
            direction: 'asc',
          },
          filter: false,
          download: false,
          elevation: 0,
          rowsPerPage: 15,
          selectableRows: 'none',
          responsive: 'simple',
          onCellClick: (colData, cellMeta) => {
            // ignore click on "actions" column cell
            if (cellMeta.colIndex >= 9) {
              return false;
            }

            const rescue = rescues[cellMeta.dataIndex];

            if (!rescue || isRescueClaimed(rescue)) {
              return false;
            }

            return onCellClick(rescue);
          },
          setRowProps: (row, dataIndex) => {
            const rescue = rescues[dataIndex];

            if (rescue && isRescueClaimed(rescue)) {
              return {
                className: classes.claimedRescueRow
              }
            }

            if (!rescue || !rescue.cancelled_by_id) {
              return false;
            }

            return {
              className: classes.cancelledRescueRow,
            };
          },
        }}
      />
    </MuiThemeProvider>
  );
};

export default RescuerDashboardScheduleRescuesTable;
