import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Box, Typography, List, ListItem } from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import routes from '../../routes';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';
import FoodDonorPendingMobileCard from './FoodDonorPendingMobileCard';
import { fetchFoodDonorsWithPendingVerifications } from '../../actions/foodDonors';
import useActiveSite from '../../hooks/useActiveSite';

const PendingRegistrationsBreadcrumbs = () => (
  <Box boxShadow={0}>
    <Breadcrumbs aria-label="breadcrumb">
      <StyledRescuerBreadcrumb
        component={RouterLink}
        to={generatePath(routes.mobileDashboard)}
        label="Home"
        icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
      />
      <StyledRescuerTextBreadcrumb component={RouterLink} to={routes.mobileToDo} label="To-do" />
      <StyledRescuerTextBreadcrumb component="a" href="#" label="Pending Food Donors" />
    </Breadcrumbs>
  </Box>
);

const useStyles = makeStyles(({ palette, typography, spacing }) => ({
  container: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: palette.background.default,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  listSubheader: {
    ...typography['h5'],
    padding: spacing(1),
  },
  listSubItem: {
    display: 'block',
    padding: spacing(1),
  },
  ul: {
    marginBottom: spacing(2),
    backgroundColor: 'inherit',
    padding: 0,
  },
  noData: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    '-webkit-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
}));

const FoodDonorPendingRegistrationsList = () => {
  const classes = useStyles();
  const activeSite = useActiveSite();
  const dispatch = useDispatch();
  const foodDonors = useSelector(state => state.entities.foodDonors);
  const foodDonorsList = Object.values(foodDonors.byId);

  useEffect(() => {
    dispatch(fetchFoodDonorsWithPendingVerifications([activeSite.id]));
    window.scrollTo({ top: 0 });
  }, [dispatch]);

  if (!foodDonors.inflight && foodDonors.allIds.length === 0) {
    return (
      <>
        <PendingRegistrationsBreadcrumbs />

        <Box className={classes.container}>
          <Box className={classes.noData}>
            <Typography color="textSecondary" variant="h5">
              You have no pending food donor registrations
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <PendingRegistrationsBreadcrumbs />

      <List className={classes.container} subheader={<li />}>
        <li className={classes.listSection}>
          <ul className={classes.ul}>
            {foodDonorsList.map(foodDonorRequest => (
              <ListItem key={`fd-${foodDonorRequest.id}`} className={classes.listSubItem}>
                <FoodDonorPendingMobileCard foodDonor={foodDonorRequest} />
              </ListItem>
            ))}
          </ul>
        </li>
      </List>
    </>
  );
};

export default FoodDonorPendingRegistrationsList;
