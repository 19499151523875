import mockupVideo from '../assets/videos/mockupCourseVideo.mp4';

const foodSafety = {
  id: 1,
  title: 'Food Safety',
  modules: ['Video', 'Need To Know', 'Quiz'],
  video: mockupVideo,
  videoDuration: '4',
  transcript: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at aliquet metus, 
    vitae tincidunt velit. Ut gravida sed leo non fermentum. Etiam bibendum convallis faucibus. 
    Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
    Nullam fermentum, justo sit amet convallis volutpat, diam magna lobortis nibh, 
    eget facilisis nibh dui et mauris. Aenean porttitor ligula at mi ultricies commodo. 
    Pellentesque eu auctor dui. Mauris eu auctor mauris. Vivamus auctor, ipsum porttitor cursus tincidunt, 
    elit diam venenatis massa, cursus viverra libero velit luctus justo. In et volutpat elit. Suspendisse potenti. 
    Donec sollicitudin at velit eu sodales. Pellentesque vel orci auctor, convallis odio non, condimentum arcu.`,
  needToKnowDuration: '10',
  needToKnow: [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    'Sed varius ex eget auctor sollicitudin.',
    'Mauris porta viverra molestie. Donec vitae lobortis elit. Cras egestas egestas ipsum pulvinar ullamcorper.',
  ],
  quizDuration: '4',
  quiz: [
    {
      id: 1,
      question: 'Is this a question?',
      answers: [
        {
          label: 'An answer',
          value: 'answer-1',
          correct: 1,
        },
        {
          label: 'An answer',
          value: 'answer-2',
        },
        {
          label: 'An answer',
          value: 'answer-3',
        },
        {
          label: 'An answer',
          value: 'answer-4',
        },
      ],
    },
    {
      id: 2,
      question: 'Is this a second question?',
      answers: [
        {
          label: 'An answer',
          value: 'answer-1',
        },
        {
          label: 'An answer',
          value: 'answer-2',
        },
        {
          label: 'An answer',
          value: 'answer-3',
          correct: 1,
        },
        {
          label: 'An answer',
          value: 'answer-4',
        },
      ],
    },
    {
      id: 3,
      question: 'Is this a third question?',
      answers: [
        {
          label: 'An answer',
          value: 'answer-1',
        },
        {
          label: 'An answer',
          value: 'answer-2',
        },
        {
          label: 'An answer',
          value: 'answer-3',
        },
        {
          label: 'An answer',
          value: 'answer-4',
          correct: 1,
        },
      ],
    },
  ],
};

const courses = [foodSafety];

export default courses;
