import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { generatePath, Route, Switch, withRouter, matchPath } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { CssBaseline, MuiThemeProvider, Fade, createMuiTheme } from '@material-ui/core';
import * as authService from './services/auth';
import PrivateRoute from './components/PrivateRoute';
import routes from './routes';
import * as errorsHelper from './helpers/errors';
import AppBar from './components/AppBar';
import NotificationsInbox from './components/Notifications/NotificationsList/NotificationsInbox';
import NotificationDetails from './components/NotificationDetails';
import NotificationsPreferences from './components/NotificationsPreferences';
import Callback from './components/Callback';
import CompleteRescuerRegistration from './components/CompleteRescuerRegistration';
import FoodDonorAddView from './containers/FoodDonorAddView';
import FoodDonorPickupLocationAddView from './containers/FoodDonorPickupLocationAddView';
import FoodDonorPickupLocationEditView from './containers/FoodDonorPickupLocationEditView';
import UmbrellaFoodDonorView from './containers/UmbrellaFoodDonorView';
import UmbrellaFoodDonorAddView from './containers/UmbrellaFoodDonorAddView';
import UmbrellaFoodDonorStaffAddView from './pages/UmbrellFoodDonor/UmbrellaFoodDonorStaffAddView';
import UmbrellaFoodDonorAddToUmbrellaView from './pages/UmbrellFoodDonor/UmbrellaFoodDonorAddToUmbrellaView';
import ReceiverAddView from './containers/ReceiverAddView';
import RescuerAddView from './containers/RescuerAddView';
import ReceiverDashboard from './containers/ReceiverDashboard';
import DonorLocationStaffAddView from './containers/DonorLocationStaffAddView';
import RescueEditView from './pages/Rescue/RescueEdit/RescueEditView';
import WhatsNewView from './containers/WhatsNewView';
import SitesListView from './containers/SitesListView';
import SiteAddView from './containers/SiteAddView';
import SiteEditView from './containers/SiteEditView';
import UserAddView from './containers/UserAddView';
import UserEditView from './containers/UserEditView';
import ReceiverStaffAddView from './containers/ReceiverStaffAddView';
import DashboardView from './containers/DashboardView';
import ManageSettingsView from './containers/ManageSettingsView';
import UnverifiedEmailNotification from './components/UnverifiedEmailNotification';
import ProfileView from './pages/Profile/ProfileContainer';
import * as usersApi from './api/users';
import * as authActions from './actions/auth';
import WelcomePageView from './containers/WelcomePageView';
import SystemNotAvailableView from './containers/SystemNotAvailableView';
import PWAInstallButton from './components/Add2HS/PWAInstallButton';
import ReceiverDashboardDesktop from './pages/receiverDashboard/ReceiverDashboardDesktop';
import { RescuerDashboardContainer } from './pages/rescuerDashboard/RescuerDashboardContainer';
import SiteCoordinatorDashboardContainer from './pages/SiteCoordinatorDashboard/SiteCoordinatorDashboardContainer';
import DonorDashboardContainer from './pages/donorDashboard/DonorDashboardContainer';
import { mainTheme } from './assets/theme';
import TourStepper from './components/TourStepper';
import FABButton from './components/FAB';
import Notifications from './components/Notifications';
import NSDDashboardContainer from './pages/NSDDashboard/NSDDashboardContainer';
import LoginPage from './pages/Auth/LoginPage';
import RegisterPage from './pages/Auth/RegisterPage';
import ForgotPasswordPage from './pages/Auth/ForgotPasswordPage';
import ResetPasswordPage from './pages/Auth/ResetPasswordPage';
import ScreenOrientationInfo from './pages/ScreenOrientationInfo';
import SendEmailView from './containers/SendEmailView';
import SendSmsView from './containers/SendSmsView';
import TermsOfServiceView from './containers/TermsOfServiceView';
import SwitchUserContainer from './pages/SwitchUser/SwitchUserContainer';
import { fetchFeatureFlagsIfNeeded } from './actions/featureFlags';
import FABContext from './context/FABContext/FABContext';
import Bluebird from 'bluebird';
import { setFABActions } from './helpers/fab';
import ContactUs from './pages/ContactUs/ContactUs';
import ContactListView from './pages/ContactList/ContactListView';
import Map from './pages/Map/Map';
import RescueClose from './pages/Rescue/RescueClose/RescueClose';
import DonorLocationDashboardContainer from './pages/donorDashboard/DonorLocationDashboard/DonorLocationDashboardContainer';
import RescueAdd from './pages/Rescue/RescueAdd/RescueAdd';
import EventsListView from './pages/Events/EventsList/EventsListView';
import EventCreateView from './pages/Events/EventCreate/EventCreateView';
import EventEditView from './pages/Events/EventEdit/EventEditView';
import EventDetailsView from './pages/Events/EventDetails/EventDetailsView';
import EventRescuerDetailsView from './pages/Events/EventRescuerDetails/EventRescuerDetailsView';
import EmailsView from './containers/EmailsView';
import SmsView from './containers/SmsView';
import EmailDetailsView from './containers/EmailDetailsView';
import OAuthCallbackView from './containers/OAuthCallback';
import RescuePhotoGalleryView from './pages/Rescue/RescueGallery/RescuePhotoGalleryView';
import PWAAdd2HSTutorial from './components/Add2HS/PWAAdd2HSTutorial';
import RescuerRescuesDashboardMobileContainer from './pages/rescuerDashboard/rescuerDashboardMobile/Rescues/RescuerRescuesDashboardMobileContainer';
import RescuerEventsDashboardMobileContainer from './pages/rescuerDashboard/rescuerDashboardMobile/Events/RescuerEventsDashboardMobileContainer';
import ScheduleMobileNEW from './pages/rescuerDashboard/rescuerDashboardMobile/Schedule/ScheduleMobileNEW';
import DonationEdit from './pages/Donation/DonationManage/DonationEdit/DonationEdit';
import DonationCreate from './pages/Donation/DonationManage/DonationCreate/DonationCreate';
import DonationRequestsListView from './pages/Donation/DonationManage/DonationRequestsList/DonationRequestsListView';
import DonationRequestCreate from './pages/Donation/DonationManage/DonationRequestCreate/DonationRequestCreate';
import DonationRequestEdit from './pages/Donation/DonationManage/DonationRequestEdit/DonationRequestEdit';
import HeartbeatLoadingLogo from './components/HeartbeatLoadingLogo';
import RescueAdoptConfirmation from './pages/rescueAdoptConfirmation/RescueAdoptConfirmation';
import CompleteRegistrationView from './pages/CompleteRegistration/CompleteRegistrationView';
import DonationRequestVerify from './pages/Donation/DonationManage/DonationRequestVerify/DonationRequestVerify';
import FoodDonorRegistrationRequest from './pages/FoodDonorRegistrationRequest/FoodDonorRegistrationRequest';
import { hasRoleInCurrentlySelectedSite } from './services/auth';
import { Roles } from './models/roles';
import { transformRoles } from './models/users';
import { getVerificationStatus } from './api/verification';
import { setQuickResponse, setUIDisabled } from './actions/ui';
import DonationPendingRequestsListView
from './pages/Donation/DonationManage/DonationPendingRequestsList/DonationPendingRequestsListView';
import FoodDonorPendingRegistrationsList from './pages/FoodDonorRegistrationRequest/FoodDonorPendingRegistrationsList';
import UnassignedFoodDonorEdit from './pages/UnassignedFoodDonor/UnassignedFoodDonorEdit';
import ClaimRescueBasedOnSiteGoalDialog from './components/ClaimRescueBasedOnSiteGoalDialog';
import PausedRescuerNotification from './components/PausedRescuerNotification';
import BadgesDashboardMobileContainer from './pages/rescuerDashboard/rescuerDashboardMobile/Badges/BadgesDashboardMobileContainer';
import SiteGoalsDashboardMobileContainer from './containers/SiteGoalsDashboardMobileContainer';
import AddNewSiteGoalView from './containers/AddNewSiteGoalView';
import RescueClosedEnviroImpact from './pages/Rescue/RescueClose/RescueClosedEnviroImpact';
import EnvironmentImpactDashboardMobileContainer from './pages/rescuerDashboard/rescuerDashboardMobile/EnvironmentImpact/EnvironmentImpactDashboardMobileContainer';
import CloseRescueReminderNotification from './components/CloseRescueReminderNotification';
import PushInstallButton from './components/PushInstallButton';
import MarketingHubContainer from './pages/MarketingHub/MarketingHubContainer';
import { setSDKInitialized } from './actions/marketingHub';
import AnnualAppearBanner from './components/AnnualAppearBanner';
import { fetchSettingsIfNeeded } from './actions/settings';
import { fetchSitesIfNeeded } from './actions/sites';
import IdModeMobile from './pages/rescuerDashboard/rescuerDashboardMobile/IdMode/IdModeMobile';
import PrivacyPolicyReagreement from './components/PrivacyPolicyReagreement';
import PrivacyPolicy from './components/PrivacyPolicy';
import ReportsView from './containers/ReportsView';
import RescuesView from './containers/RescuesView';
import AllUsersView from './containers/AllUsersView';
import QuickResponseTeamPopup from './components/QuickResponseTeamPopup';
import { updateUser } from './actions/users';
import ToDoMobile from './pages/SiteCoordinatorDashboard/ToDoMobile/ToDoMobile';
import ToDoUnclaimedRescues from './pages/SiteCoordinatorDashboard/ToDoMobile/ToDoUnclaimedRescues';
import ToDoNoReceiverAssigned from './pages/SiteCoordinatorDashboard/ToDoMobile/ToDoNoReceiverAssigned';
import LearningCenterMobileContainer from './pages/LearningCenter/LearningCenterMobile/LearningCenterMobileContainer';
import LearningCenterDesktopContainer from './pages/LearningCenter/LearningCenterDesktop/LearningCenterDesktopContainer';

const NewBadgeAlert = lazy(() => import('./components/NewBadgeAlert'));

class App extends Component {
  componentDidMount() {
    const { history, loggedInUser, userRegistrationData, location, initialFetch, SDKInit, site } = this.props;

    const isNewOnboarding = matchPath(location.pathname, {
      path: routes.completeRegistrationNew,
    });
    const isOldOnboarding = matchPath(location.pathname, {
      path: routes.completeRescuerRegistration,
    });

    const isInOnboardingProcess = !!isNewOnboarding || !!isOldOnboarding;
    const isOnTheLoginPage = location.pathname.indexOf(routes.login) !== -1;

    const isTermsOfServicePage = matchPath(location.pathname, {
      path: routes.termsOfService,
    });

    const showOldOnboarding = userRegistrationData && userRegistrationData.type === null;
    const onboardingRoute = showOldOnboarding ? routes.completeRescuerRegistration : routes.completeRegistrationNew;

    if (
      !location.search &&
      authService.isCompleteRegistrationProcessPending() &&
      (!isNewOnboarding || !isOldOnboarding) &&
      !isTermsOfServicePage
    ) {
      // todo: handle redirect to correct onboarding
      history.push(generatePath(onboardingRoute));
    }

    if (!isInOnboardingProcess && !isOnTheLoginPage && loggedInUser) {
      this.fetchUser();
    }

    if (loggedInUser) {
      // user refreshed the page, already logged in
      initialFetch();
    }

    // Facebook Graph API SDK initialization
    window.fbAsyncInit = async () => {
      await window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_GRAPH_API_APP_ID,
        autoLogAppEvents: true,
        cookie: true, // Remove when API is ready
        xfbml: true,
        status: true,
        version: 'v16.0', // v17 does not fetch page information
      });
      SDKInit();
    };

    ((d, s, id) => {
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

    const script = document.createElement("script");

    script.src = "https://tools.luckyorange.com/core/lo.js?site-id=9c799ab0";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);
  }

  componentDidUpdate(prevProps, prevState) {
    const { initialFetch, loggedInUser, location } = this.props;

    const isNewOnboarding = matchPath(location.pathname, {
      path: routes.completeRegistrationNew,
    });
    const isOldOnboarding = matchPath(location.pathname, {
      path: routes.completeRescuerRegistration,
    });

    const isInOnboardingProcess = !!isNewOnboarding || !!isOldOnboarding;

    if (
      // user just logged in
      (!isInOnboardingProcess && prevProps.loggedInUser === null && loggedInUser) ||
      // user navigates from welcome-page to index
      (location.state && location.state.prevPath === routes.welcomePage && location.pathname === routes.index)
    ) {
      initialFetch();
    }
  }

  fetchUser = () => {
    const { history, loggedInUser, impersonating, disableUI } = this.props;
    const activeUser = impersonating || loggedInUser;
    
    return Bluebird.try(() => usersApi.getUser(activeUser.id))
      .then(res => res.json())
      .then(json => {
        authService.setCurrentlyLoggedInOrImpersonatingUser(json.user);
        return json.user;
      })
      .then(userData => {
        const userObject = {
          ...userData,
          role_assignments: transformRoles(userData.role_assignments || []),
        };
        const isFoodDonorAdmin = hasRoleInCurrentlySelectedSite(userObject, Roles.DonorLocationAdmin);
        if (isFoodDonorAdmin) {
          const donorLocation = userObject.role_assignments.find(role => role.role_name === Roles.DonorLocationAdmin);
          Bluebird.try(() =>
            getVerificationStatus({ entityId: donorLocation.donor_location_id, entityType: 'food_donor' })
          )
            .then(res => res.json())
            .then(json => disableUI(json.data.length >= 1 && json.data[0].status !== 'approved'));
        }
      })
      .then(() => setFABActions(authService.getCurrentlyLoggedInOrImpersonatingUser(), history, this.context));
  };

  callback = () => {
    return <Callback />;
  };

  onLogoutClick = () => authService.logout();

  handleQrtAccept = async (userId) => {
    const { closeQuickResponsePopup, addUserToQuickResponseTeam } = this.props;
    closeQuickResponsePopup();
    try {
      await addUserToQuickResponseTeam(userId);
    } catch (err) {
      errorsHelper.handleError(err);
    }
  };

  render() {
    const { classes, loggedInUser, impersonating, location, isInitialFetchLoading, sites, settings, site, showQuickResponse, closeQuickResponsePopup, addUserToQuickResponseTeam } = this.props;

    const loggedInOrImpersonatingUserIsRescuer = authService.currentlyLoggedInOrImpersonatingUserHasAnyRoleInCurrentlySelectedSite([Roles.Rescuer]);

    // Check for custom branding setting
    const currentSite = Object.values(sites).find((s) => s.id === site?.id);
    const settingsList = Object.values(settings.byId);
    const customBrandingSetting = settingsList.find((setting) => setting.name === 'custom_branding');
    const hasCustomBranding =
      currentSite?.settings.find((setting) => setting.id === customBrandingSetting?.id)?.value === '1';

    // secondary_color is meant to be mui primary,
    // while primary_color is for app bar color.
    const customTheme = createMuiTheme({
      ...mainTheme,
      palette: {
        ...mainTheme.palette,
        ...(currentSite?.secondary_color && {
          primary: {
            main: currentSite.secondary_color,
            ...(currentSite?.button_text_color && {
              contrastText: currentSite.button_text_color,
            }),
          },
        }),
      },
      ...(currentSite?.secondary_color && {
        props: {
          ...mainTheme.props,
          MuiLink: {
            color: 'textPrimary',
          },
        },
      }),
    });

    // ensure that we have feature flags loaded, when the app loads
    if (isInitialFetchLoading) {
      return <HeartbeatLoadingLogo />;
    }

    return (
      <Fade in timeout={750}>
        <div>
          <MuiThemeProvider theme={hasCustomBranding ? customTheme : mainTheme}>
            <CssBaseline />

            <ScreenOrientationInfo />

            <AppBar
              hasCustomBranding={hasCustomBranding}
              customBranding={{
                primary_color: currentSite?.primary_color,
                secondary_color: currentSite?.secondary_color,
                logo_url: currentSite?.logo_url,
                toolbar_text_color: currentSite?.toolbar_text_color,
              }}
              location={location}
              onLogoutClick={this.onLogoutClick}
            />

            {loggedInUser && location.pathname !== routes.idMode && (
              <>
                {(impersonating?.tos_reagreement_needed || loggedInUser.tos_reagreement_needed) && (
                  <PrivacyPolicyReagreement user={impersonating || loggedInUser} />
                )}
                <FABButton />
                <TourStepper />
                <PWAAdd2HSTutorial />
                <PushInstallButton />

                <UnverifiedEmailNotification />
                {loggedInOrImpersonatingUserIsRescuer && <ClaimRescueBasedOnSiteGoalDialog rescuer={impersonating || loggedInUser} />}
                <PausedRescuerNotification />
                <CloseRescueReminderNotification />
                <AnnualAppearBanner />

                {loggedInUser?.new_badges?.length > 0 && (
                  <Suspense fallback={null}>
                    <NewBadgeAlert badges={loggedInUser?.new_badges} userId={loggedInUser?.id} />
                  </Suspense>
                )}

                {showQuickResponse && !loggedInUser?.quick_response_team_member && (
                  <QuickResponseTeamPopup
                    show={showQuickResponse}
                    onClose={closeQuickResponsePopup}
                    onAccept={() => this.handleQrtAccept(loggedInUser.id)}
                  />
                )}
              </>
            )}

            {location.pathname !== routes.idMode && <PWAInstallButton />}

            <div className={classes.root}>
              <div className={classes.app}>
                <Notifications />

                <Switch>
                  <Route exact path={routes.login} component={LoginPage} />
                  <Route exact path={routes.signup} component={RegisterPage} />
                  <Route exact path={routes.forgotPassword} component={ForgotPasswordPage} />
                  <Route exact path={routes.resetPassword} component={ResetPasswordPage} />
                  <Route path={routes.loggedout} component={this.loggedOut} />
                  <Route path={routes.callback} component={this.callback} />
                  <Route path={routes.whatsnew} component={WhatsNewView} />
                  <Route path={routes.contactUs} component={ContactUs} />
                  <Route exact path={routes.systemNotAvailable} component={SystemNotAvailableView} />
                  <Route exact path={routes.oAuthCallback} component={OAuthCallbackView} />
                  <PrivateRoute path={routes.switchUser} component={SwitchUserContainer} forceRender />
                  <PrivateRoute
                    path={[
                      routes.donation_add_new,
                      routes.donations_add_2,
                      routes.donation_add_from_map,
                      routes.donations_add,
                      routes.donor_link_donation_add,
                    ]}
                    component={DonationCreate}
                    forceRender
                  />
                  <PrivateRoute path={[routes.donation_edit, routes.donation, routes.donor_link_donation_edit,]} component={DonationEdit} forceRender />
                  <PrivateRoute path={routes.donation_requests} component={DonationRequestsListView} forceRender />
                  <PrivateRoute
                    path={routes.pendingDonations}
                    component={DonationPendingRequestsListView}
                    forceRender
                  />
                  <PrivateRoute
                    path={routes.pendingFoodDonorsRegistrations}
                    component={FoodDonorPendingRegistrationsList}
                    forceRender
                  />
                  <PrivateRoute exact path={routes.mobileToDo} component={ToDoMobile} forceRender />
                  <PrivateRoute
                    exact
                    path={routes.mobileToDoUnclaimedRescues}
                    component={ToDoUnclaimedRescues}
                    forceRender
                  />
                  <PrivateRoute
                    exact
                    path={routes.mobileToDoNoReceiverAssigned}
                    component={ToDoNoReceiverAssigned}
                    forceRender
                  />
                  <PrivateRoute path={routes.donation_create_request} component={DonationRequestCreate} forceRender />
                  <PrivateRoute path={[routes.donation_edit_request]} component={DonationRequestEdit} forceRender />
                  <PrivateRoute path={routes.foodDonorRegistrationReview} component={FoodDonorRegistrationRequest} />
                  <PrivateRoute path={routes.umbrellaFoodDonorsAdd} component={UmbrellaFoodDonorAddView} />
                  <PrivateRoute path={routes.donation_request_verify} component={DonationRequestVerify} />
                  <PrivateRoute exact path={routes.foodDonorStaffAdd} component={DonorLocationStaffAddView} />
                  <PrivateRoute exact path={routes.contactList} component={ContactListView} forceRender />
                  <PrivateRoute exact path={routes.foodDonorAdd} component={FoodDonorAddView} forceRender />
                  <PrivateRoute exact path={routes.foodDonor} component={DonorLocationDashboardContainer} />
                  <PrivateRoute
                    exact
                    path={routes.foodDonorPickupLocationCreate}
                    component={FoodDonorPickupLocationAddView}
                  />
                  <PrivateRoute
                    exact
                    path={routes.foodDonorPickupLocationEdit}
                    component={FoodDonorPickupLocationEditView}
                  />
                  <PrivateRoute path={routes.umbrellaFoodDonorAddToUmbrella} component={UmbrellaFoodDonorAddToUmbrellaView} />
                  <PrivateRoute path={routes.umbrellaFoodDonorsStaffAdd} component={UmbrellaFoodDonorStaffAddView} />
                  <PrivateRoute path={routes.umbrellaFoodDonorsAdd} component={UmbrellaFoodDonorAddView} />
                  <PrivateRoute exact path={routes.umbrellaFoodDonor} component={UmbrellaFoodDonorView} />
                  <PrivateRoute path={routes.receivers_add} component={ReceiverAddView} forceRender />
                  <PrivateRoute exact path={routes.receiverStaffAdd} component={ReceiverStaffAddView} />
                  <PrivateRoute path={routes.receiver} component={ReceiverDashboard} />
                  <PrivateRoute exact path={routes.rescuesGallery} component={RescuePhotoGalleryView} forceRender />
                  <PrivateRoute
                    exact
                    path={[routes.rescue, routes.rescue_edit, routes.rescuesScheduleEdit]}
                    component={RescueEditView}
                    forceRender
                  />
                  <PrivateRoute exact path={routes.rescueAdd} component={RescueAdd} forceRender />
                  <PrivateRoute
                    exact
                    path={routes.rescueAdoptConfirmation}
                    component={RescueAdoptConfirmation}
                    forceRender
                  />
                  <PrivateRoute exact path={routes.rescuersAdd} component={RescuerAddView} />
                  <PrivateRoute exact path={routes.notifications} component={NotificationsInbox} forceRender />
                  <PrivateRoute
                    exact
                    path={routes.notificationsPreferences}
                    component={NotificationsPreferences}
                    forceRender
                  />
                  <PrivateRoute exact path={routes.notification} component={NotificationDetails} forceRender />
                  <PrivateRoute exact path={routes.sites} component={SitesListView} />
                  <PrivateRoute exact path={routes.sitesAdd} component={SiteAddView} />
                  <PrivateRoute exact path={routes.siteEdit} component={SiteEditView} />
                  <PrivateRoute exact path={routes.usersAdd} component={UserAddView} />
                  <PrivateRoute exact path={routes.unassignedFoodDonorEdit} component={UnassignedFoodDonorEdit} />
                  <PrivateRoute exact path={[routes.userEdit, routes.userEditNew]} component={UserEditView} />
                  <PrivateRoute exact path={routes.settings} component={ManageSettingsView} />
                  <PrivateRoute exact path={routes.rescueClose} component={RescueClose} forceRender />
                  <PrivateRoute exact path={routes.rescueCloseEnviro} component={RescueClosedEnviroImpact} forceRender />
                  <PrivateRoute exact path={routes.rescueCloseNoShow} component={RescueClose} forceRender />
                  <PrivateRoute exact path={routes.profile} component={ProfileView} forceRender />
                  <PrivateRoute exact path={routes.welcomePage} component={WelcomePageView} forceRender />
                  <PrivateRoute exact path={routes.reports} component={ReportsView} />
                  <PrivateRoute exact path={routes.dashboardReceiver} component={ReceiverDashboardDesktop} />
                  <PrivateRoute
                    exact
                    path={[routes.dashboardSC, routes.dashboardSCEvents]}
                    component={SiteCoordinatorDashboardContainer}
                    forceRender
                  />
                  <PrivateRoute exact path={routes.siteGoalAdd} component={AddNewSiteGoalView} />
                  <PrivateRoute exact path={routes.dashboardFoodDonor} component={DonorDashboardContainer} />
                  <PrivateRoute exact path={routes.dashboardNSD} component={NSDDashboardContainer} />
                  <PrivateRoute exact path={routes.marketingHub} component={MarketingHubContainer} />
                  <PrivateRoute exact path={routes.rescuesList} component={RescuesView} />
                  <PrivateRoute exact path={routes.allUsers} component={AllUsersView} />

                  <PrivateRoute exact path={routes.mobileDashboardSchedule} component={ScheduleMobileNEW} forceRender />
                  <PrivateRoute
                    exact
                    path={routes.mobileDashboardRescues}
                    component={RescuerRescuesDashboardMobileContainer}
                    forceRender
                  />
                  <PrivateRoute
                    exact
                    path={routes.mobileDashboardBadges}
                    component={BadgesDashboardMobileContainer}
                    forceRender
                  />
                  <PrivateRoute
                      exact
                      path={routes.mobileDashboardSiteGoals}
                      component={SiteGoalsDashboardMobileContainer}
                      forceRender
                  />
                  <PrivateRoute
                      exact
                      path={routes.mobileDashboardEnvironmentImpact}
                      component={EnvironmentImpactDashboardMobileContainer}
                      forceRender
                  />
                  <PrivateRoute
                    exact
                    path={[routes.mobileDashboardEvents, routes.mobileDashboardEventSignup]}
                    component={RescuerEventsDashboardMobileContainer}
                    forceRender
                  />
                  <PrivateRoute exact path={routes.idMode} component={IdModeMobile} forceRender />
                  <PrivateRoute
                    path={generatePath(routes.mobileDashboardLearn)}
                    component={LearningCenterMobileContainer}
                    forceRender
                  />
                  <PrivateRoute
                    path={generatePath(routes.learn)}
                    component={LearningCenterDesktopContainer}
                    forceRender
                  />

                  <PrivateRoute
                    exact
                    path={[routes.mobileDashboard, routes.dashboardRescuer, routes.dashboardRescuerEvents]}
                    component={RescuerDashboardContainer}
                    forceRender
                  />
                  <PrivateRoute exact path={routes.sendEmail} component={SendEmailView} />
                  <PrivateRoute exact path={routes.sendSms} component={SendSmsView} />
                  <PrivateRoute exact path={routes.map} component={Map} />
                  <PrivateRoute exact path={routes.event} component={EventDetailsView} forceRender />
                  <PrivateRoute exact path={routes.eventSignup} component={EventRescuerDetailsView} forceRender />
                  <PrivateRoute exact path={routes.eventsList} component={EventsListView} forceRender />
                  <PrivateRoute exact path={routes.eventAdd} component={EventCreateView} forceRender />
                  <PrivateRoute exact path={routes.eventEdit} component={EventEditView} forceRender />
                  <PrivateRoute exact path={routes.emails} component={EmailsView} />
                  <PrivateRoute exact path={routes.sms} component={SmsView} />
                  <PrivateRoute exact path={routes.emailDetails} component={EmailDetailsView} />
                  <Route exact path={routes.termsOfService} component={TermsOfServiceView} />
                  <Route exact path={routes.privacyPolicy} component={PrivacyPolicy} />
                  <Route exact path={routes.completeRescuerRegistration} component={CompleteRescuerRegistration} />
                  <Route exact path={routes.completeRegistrationNew} component={CompleteRegistrationView} />
                  {loggedInUser ? (
                    <PrivateRoute path={routes.index} component={DashboardView} forceRender />
                  ) : (
                    <Route path={routes.index} component={LoginPage} forceRender />
                  )}

                  <Route
                    render={({ location }) => {
                      return <div>{location.pathname} not implemented</div>;
                    }}
                  />
                </Switch>
              </div>
            </div>
          </MuiThemeProvider>
        </div>
      </Fade>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
  },
  app: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '8px !important',
      paddingRight: '8px !important',
    },
    paddingLeft: ({ isDrawerOpen, loggedInUser, impersonating }) => {
      if (loggedInUser && !authService.shouldHideMainDrawer(impersonating || loggedInUser)) {
        return isDrawerOpen ? 240 : 75;
      }

      return 24;
    },
    paddingRight: 24,
    marginTop: 10,
  },
});
const mapStateToProps = ({
  app: { loggedInUser, impersonating, userRegistration, site },
  entities: {
    sites,
    settings,
    featureFlags: { inflight: featureFlagsInflight },
  },
  ui: {
    persisted: { isDrawerOpen },
    showQuickResponse,
  },
}) => ({
  userRegistrationData: userRegistration.data,
  loggedInUser: loggedInUser,
  isDrawerOpen: isDrawerOpen,
  impersonating: impersonating,
  isInitialFetchLoading: !!featureFlagsInflight,
  site,
  sites: sites.byId,
  settings,
  showQuickResponse,
});

const mapDispatchToProps = dispatch => ({
  setUserRegistrationData: data => dispatch(authActions.setUserRegistrationData(data)),
  initialFetch: () => {
    dispatch(fetchFeatureFlagsIfNeeded());
    dispatch(fetchSettingsIfNeeded());
    dispatch(fetchSitesIfNeeded());
  },
  disableUI: status => dispatch(setUIDisabled({ status })),
  SDKInit: () => dispatch(setSDKInitialized()),
  closeQuickResponsePopup: () => dispatch(setQuickResponse(false)),
  addUserToQuickResponseTeam: (userId) => dispatch(updateUser(userId, { quick_response_team_member: true })),
});

App.contextType = FABContext;

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(App);
