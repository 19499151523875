import React, { useEffect } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { Box, Breadcrumbs, makeStyles, Paper, Typography } from '@material-ui/core';
import { Home as HomeIcon } from '@material-ui/icons';
import {
  StyledRescuerBreadcrumb,
  StyledRescuerTextBreadcrumb,
} from '../../rescuerDashboard/rescuerDashboardMobile/Components/StyledRescuerBreadcrumb';
import routes from '../../../routes';
import ModuleButtons from './ModuleButtons';

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: palette.background.default,
    flexDirection: 'column',
    padding: spacing(1),
  },
  needToKnow: {
    padding: spacing(1),
    marginTop: spacing(1),
  },
  list: {
    padding: spacing(2),
    margin: 0,
  },
  listItem: {
    marginBottom: spacing(1),
  },
}));

const LearningCenterMobileNeedToKnowBreadcrumbs = () => (
  <Box boxShadow={0}>
    <Breadcrumbs aria-label="breadcrumb">
      <StyledRescuerBreadcrumb
        component={Link}
        to={generatePath(routes.mobileDashboard)}
        label="Home"
        icon={<HomeIcon style={{ color: '#fff' }} fontSize="small" />}
      />
      <StyledRescuerTextBreadcrumb component={Link} to={generatePath(routes.mobileDashboardLearn)} label="Learn" />
      <StyledRescuerTextBreadcrumb component="a" href="#" label="Need To Know" />
    </Breadcrumbs>
  </Box>
);

const NeedToKnowModule = ({ course, nextTab, setCurrentCoursesState, isMobile = false }) => {
  const classes = useStyles();
  const history = useHistory();
  const { id, needToKnow } = course;

  if (!id) {
    if (isMobile) {
      return history.push(generatePath(routes.mobileDashboardLearn));
    }
    return history.push(generatePath(routes.learn));
  }

  useEffect(() => {
    setCurrentCoursesState({
      courseId: id,
      modules: { needToKnowModule: true },
    });
  }, [id]);

  return (
    <>
      {isMobile && <LearningCenterMobileNeedToKnowBreadcrumbs />}

      <Box className={classes.container}>
        <Typography variant="h6">Need To Know</Typography>

        <Paper className={classes.needToKnow}>
          <ul className={classes.list}>
            {(needToKnow || []).map((paragraph) => (
              <li key={paragraph} className={classes.listItem}>
                <Typography variant="body2">{paragraph}</Typography>
              </li>
            ))}
          </ul>
        </Paper>

        <ModuleButtons nextTab={nextTab} courseId={id} isMobile={isMobile} />
      </Box>
    </>
  );
};

export default NeedToKnowModule;
