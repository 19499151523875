import React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Box, Button, useMediaQuery, useTheme } from '@material-ui/core';
import routes from '../../../routes';

const ModuleButtons = ({ nextTab, courseId, isMobile = false }) => {
  const history = useHistory();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.only('xs'));

  const handleBack = () => {
    if (isMobile) {
      return history.push(generatePath(routes.mobileDashboardLearn));
    }

    return history.push(generatePath(routes.learn));
  };

  const handleNext = () => {
    if (nextTab) {
      if (isMobile) {
        return history.push(generatePath(routes.mobileDashboardLearn, { tab: nextTab, id: courseId }));
      }
      return history.push(generatePath(routes.learn, { tab: nextTab, id: courseId }));
    }

    if (isMobile) {
      return history.push(generatePath(routes.mobileDashboardLearn));
    }
    return history.push(generatePath(routes.learn));
  };

  if (!isMobileView) {
    return null;
  }

  return (
    <Box py={2} display="flex" justifyContent="end">
      <Button variant="contained" color="secondary" onClick={handleBack}>
        Back
      </Button>
      <Button style={{ marginLeft: '1rem' }} variant="contained" color="primary" onClick={handleNext}>
        {nextTab ? 'Next' : 'Done'}
      </Button>
    </Box>
  );
};

export default ModuleButtons;
