import React, { useEffect, useRef, useState } from 'react';
import GoogleMap from 'google-map-react';
import { Box } from '@material-ui/core';
import useNotificationService from '../../../hooks/useNotificationService';
import donorImage from '../../../assets/images/donor.png';

const ToDoAlertMap = ({ pickupAddress = false, dropoffAddress = false }) => {
  const mapApiRef = useRef();
  const [mapApi, setMapApi] = useState();
  const directionRef = useRef(null);
  const { addErrorNotification } = useNotificationService();

  const renderRoute = (values) => {
    const { map, maps } = mapApi;
    const directionsService = new maps.DirectionsService();

    // Check if the directions are rendered and clear them if so
    if (directionRef.current !== null) {
      directionRef.current.setMap(null);
      directionRef.current = null;
    }

    directionRef.current = new maps.DirectionsRenderer();
    directionRef.current.setMap(map);

    const route = {
      origin: values.origin,
      // If there is no destination use directions service to get lat long of origin location
      destination: values.destination || values.origin,
      travelMode: 'DRIVING',
    };

    directionsService.route(route, (response, status) => {
      if (status !== 'OK') {
        addErrorNotification(`Directions request failed due to ${status}`);
      } else {
        // Set directions to map when both origin and destination are provided
        if (values.origin && values.destination) {
          directionRef.current.setDirections(response); // Add route to the map
        }

        // Get lat long of origin location and set it to marker if there is no destination provided
        const directionsData = response.routes[0].legs[0]; // Get data about the mapped route
        if (!values.destination && directionsData) {
          const originLatLong = {
            lat: directionsData?.start_location?.lat(),
            lng: directionsData?.start_location?.lng(),
          };

          const marker = new maps.Marker({
            position: {
              lat: originLatLong.lat,
              lng: originLatLong.lng,
            },
            map,
            icon: donorImage,
          });

          map.panTo(marker.getPosition());
        }
      }
    });
  };

  useEffect(() => {
    if (!mapApi) {
      return;
    }

    if (pickupAddress) {
      renderRoute({ origin: pickupAddress, destination: dropoffAddress });
    }
  }, [pickupAddress, dropoffAddress, mapApi]);

  const defaultProps = {
    center: {
      lat: 39.0915821,
      lng: -94.8565884,
    },
    defaultZoom: 12,
  };

  return (
    <Box height="40vh" width="100%">
      <GoogleMap
        ref={mapApiRef}
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={(api) => setMapApi(api)}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.defaultZoom}
        options={{
          fullscreenControl: false,
          zoomControl: false,
          scrollwheel: false,
          cameraControl: false,
        }}
      />
    </Box>
  );
};

export default ToDoAlertMap;
