import { Chip, withStyles, emphasize } from '@material-ui/core';

export const StyledRescuerBreadcrumb = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    height: theme.spacing(3),
    color: theme.palette.secondary.contrastText,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

export const StyledRescuerTextBreadcrumb = withStyles((theme) => ({
  label: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  root: {
    backgroundColor: 'transparent',
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      boxShadow: theme.shadows[0],
      backgroundColor: 'transparent',
    },
  },
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591
