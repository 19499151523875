import React, { useEffect } from 'react';
import { generatePath, Route, Switch, Link, useRouteMatch } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Chip, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Bluebird from 'bluebird';
import { get } from 'lodash';
import routes from '../../../routes';
import SCDashboardAtGlance from './SCDashboardAtGlance';
import SCDashboardLeaderboards from './SCDashboardLeaderboards';
import SCDashboardToDo from './SCDashboardToDo';
import { updateSCToDoCounterBadge } from '../../../actions/misc';
import SCDashboardInsights from './SCDashboardInsights';
import SCDashboardSiteGoals from './SCDashboardSiteGoals';
import { fetchSiteUnclaimedRescuesFailed, fetchSiteUnclaimedRescues } from '../../../actions/sites';
import useActiveSite from '../../../hooks/useActiveSite';
import SCDashboardEnvironmentImpact from './SCDashboardEnvironmentImpact';
import { Colors } from '../../../assets/theme/Colors';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: Colors.gray,
    paddingLeft: 10,
    '&.active': {
      color: theme.palette.primary.main,
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        left: -3,
        top: 0,
        height: '100%',
        borderLeft: '4px solid',
        borderColor: theme.palette.primary.main,
      },
    },
  },
  container: {
    width: 'calc(100% - 170px)',
  },
}));

const scDashboardMenuList = [
  {
    name: 'To Do',
    component: SCDashboardToDo,
    path: generatePath(routes.dashboardSC),
  },
  {
    name: 'At A Glance',
    component: SCDashboardAtGlance,
    path: generatePath(routes.dashboardSC, { tab: 'at-glance' }),
  },
  {
    name: 'Leaderboards',
    component: SCDashboardLeaderboards,
    path: generatePath(routes.dashboardSC, { tab: 'leaderboards' }),
  },
  {
    name: 'Insights',
    component: SCDashboardInsights,
    path: generatePath(routes.dashboardSC, { tab: 'insights' }),
  },
  {
    name: 'Site Goals',
    component: SCDashboardSiteGoals,
    path: generatePath(routes.dashboardSC, { tab: 'site-goals' }),
  },
  {
    name: 'Environmental Impact',
    component: SCDashboardEnvironmentImpact,
    path: generatePath(routes.dashboardSC, { tab: 'environment-impact' }),
  },
];

export const scDashboardPaths = [
  generatePath(routes.dashboardSC),
  generatePath(routes.dashboardSC, { tab: 'at-glance' }),
  generatePath(routes.dashboardSC, { tab: 'leaderboards' }),
  generatePath(routes.dashboardSC, { tab: 'insights' }),
  generatePath(routes.dashboardSC, { tab: 'site-goals' }),
  generatePath(routes.dashboardSC, { tab: 'environment-impact' }),
  generatePath(routes.dashboardSC, { tab: 'analytics' }),
];

const Counter = withStyles({
  root: {
    backgroundColor: '#f7b924',
    fontWeight: 700,
    position: 'absolute',
    scale: 0.8,
    left: 50,
    top: 0,
  },
})(({ classes, label }) => <Chip size="small" classes={classes} label={label} />);

const SCDashboardDesktopContainer = () => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const params = useParams();
  const dispatch = useDispatch();
  const activeSite = useActiveSite();
  const unclaimedRescues = useSelector((state) => state.entities.sites.unclaimedRescues);
  const badges = useSelector((state) => state.misc.scDashboardMenuBadges);
  const totalUnclaimedRescues = get(unclaimedRescues, `bySiteId[${activeSite.id}].length`);

  useEffect(() => {
    // re-fetch rescues everytime we change the tab to update badge counter
    Bluebird.try(() =>
      dispatch(
        fetchSiteUnclaimedRescues(
          moment().format('YYYYMMDD'),
          moment().add(10, 'day').format('YYYYMMDD'),
          activeSite.id
        )
      )
    ).catch(() => dispatch(fetchSiteUnclaimedRescuesFailed()));
  }, [params.tab]);

  useEffect(() => {
    // update badge count on rescues length change
    dispatch(updateSCToDoCounterBadge('todo', totalUnclaimedRescues));
  }, [totalUnclaimedRescues]);

  const paths = scDashboardMenuList.map((item) => item.path);
  const getActiveTab = () => {
    const path = paths.indexOf(url);

    return Math.max(path, 0);
  };

  return (
    <>
      <Typography variant="h5">My Dashboard</Typography>
      <Box mt={5} display="flex" gridGap="16px">
        <Box className={classes.subMenu} minWidth="max-content">
          {scDashboardMenuList.map((item, index) => (
            <Typography style={{ position: 'relative' }} variant="subtitle2" key={item.name} gutterBottom>
              <Link
                className={index !== getActiveTab() ? classes.link : `${classes.link} active`}
                to={item.path}
                key={item.name}
              >
                {item.name}
              </Link>
              {item.name === 'To Do' && !!badges?.todo && <Counter label={badges?.todo} />}
            </Typography>
          ))}
        </Box>
        <Box className={classes.container}>
          <Switch>
            <Route path={[scDashboardPaths[0], generatePath(routes.index)]} exact component={SCDashboardToDo} />
            <Route path={scDashboardPaths[1]} exact render={() => <SCDashboardAtGlance siteId={activeSite.id} />} />
            <Route path={scDashboardPaths[2]} exact component={SCDashboardLeaderboards} />
            <Route path={scDashboardPaths[3]} exact render={() => <SCDashboardInsights siteId={activeSite.id} />} />
            <Route path={scDashboardPaths[4]} exact render={() => <SCDashboardSiteGoals siteId={activeSite.id} />} />
            <Route
              path={scDashboardPaths[5]}
              exact
              render={() => <SCDashboardEnvironmentImpact siteId={activeSite.id} />}
            />
          </Switch>
        </Box>
      </Box>
    </>
  );
};

export default SCDashboardDesktopContainer;
