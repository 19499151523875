import useHasActiveFeatureFlag from '../hooks/useFeatureFlags';

export const FF_EVENTS = 'events';
export const FF_SITE_GOALS = 'site-goals';
export const FF_RECEIVING_AGENCY_REPORT = 'receiving-agency-report';
export const FF_LIFETIME_RESCUERS_METRIC = 'lifetime-rescuers-metric';
export const FF_COMMUNITY_AND_ENGAGEMENT = 'community-and-engagement';
export const FF_BULK_EDIT = 'bulk-edit';
export const FF_PUSH_NOTIFICATIONS = 'push-notifications';
export const FF_RESCUE_SIZING = 'rescue-sizing';
export const FF_MARKETING_HUB = 'marketing-hub';
export const FF_ANNUAL_APPEAL = 'annual-appeal';
export const FF_SD_DASHBOARD_ANALYTICS = 'sd-dashboard-analytics';
export const FF_DONOR_LINK = 'donor-link';
export const FF_LEARNING_CENTER = 'learning-center';

const FeatureFlagWrapper = props => {
  const { renderOn, children, renderOff = () => null, flagName } = props;
  const hasActiveFeatureFlag = useHasActiveFeatureFlag(flagName)

  const resolveRender = () => (children && children ? children : renderOn());

  return hasActiveFeatureFlag ? resolveRender() : renderOff();
};

export default FeatureFlagWrapper;
